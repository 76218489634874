import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';

import { createOutgoingMessage, showAlert } from '../../../actions/radminUploads';

export const SendMessageIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.2602 3.9996C21.2497 3.90773 21.2296 3.81723 21.2 3.7296V3.6396C21.152 3.5283 21.0841 3.42665 20.9996 3.3396C20.9145 3.25986 20.8162 3.19556 20.7089 3.1496H20.6187C20.5264 3.07921 20.4207 3.02818 20.308 2.9996H20.2579C20.1582 2.98451 20.0569 2.98451 19.9572 2.9996L1.91636 8.9996C1.71584 9.06535 1.54124 9.1926 1.41747 9.36319C1.29369 9.53377 1.22705 9.739 1.22705 9.94959C1.22705 10.1602 1.29369 10.3654 1.41747 10.536C1.54124 10.7066 1.71584 10.8338 1.91636 10.8996L10.4657 13.7396L13.3122 22.2696C13.3781 22.4697 13.5056 22.6439 13.6766 22.7674C13.8476 22.8909 14.0533 22.9573 14.2643 22.9573C14.4754 22.9573 14.6811 22.8909 14.8521 22.7674C15.023 22.6439 15.1506 22.4697 15.2165 22.2696L21.2301 4.2696C21.2524 4.18138 21.2625 4.09054 21.2602 3.9996ZM16.5495 6.2896L10.9669 11.8596L5.38422 9.9996L16.5495 6.2896ZM14.2443 18.8396L12.3801 13.2696L17.9627 7.69959L14.2443 18.8396Z" fill="#00AEEF"/>
    </svg>    
  )
}

const Form = styled.form`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  border: 1px solid white;
  padding: 0px 18px;
  padding-top: 30px;
  outline: none;
  border-top: 1px solid #C2CCD9;
`
const Input = styled.input`
  width: 100%;
  border: 0;
  font-family: 'Roboto Condensed', sans-serif !important;
  &::placeholder {
    color: #8692a4;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;

const SendButton = styled.button`
  display: flex;
  background-color: white;
  border: 1px solid white;
  padding: 0px 18px;
  cursor: pointer;
  outline: none !important;
`

const MessageForm = ({ patientPhoneNumber, twilioPhoneNumber }) => {
  const [value, setValue] = useState('');
   
 const permission = useSelector((state) => state.auth.user.permission_level); 
 const rolePermissions = useSelector(
    (state) =>state.auth.user.permissions
  );
 const addPermission = rolePermissions?.find(itm=>itm.name ==="Incoming")?.permissions.find(itm=>itm.codename === "add_incoming")?.is_authorized;


  const dispatch = useDispatch();

  const handleChange = (e) => {
    setValue(e.target.value);
  }

  const onSendMessage = (e) => {
    e.preventDefault();
    if((permission && addPermission) || !permission){
    if (!value) return;
    window.analytics.track('Messages-Send-Message-Click');
    const currentTime = moment().toISOString()
    dispatch(createOutgoingMessage({patientPhoneNumber, twilioPhoneNumber, message: value, sendAt: currentTime}));
    setValue('');
   }else{
     dispatch(
       showAlert('danger', 'Error', `You don't have permission to add message`)
     );
   }
  }

  return (
    <Form onSubmit={onSendMessage}>
      <Input
        placeholder="Enter your message here..."
        value={value}
        onChange={handleChange}
      />
      <SendButton type="submit">
        <SendMessageIcon />
      </SendButton>
    </Form>
  );
};

export default MessageForm;
