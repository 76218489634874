import React from 'react';
import styled from 'styled-components';
import { CtIcon, PetIcon, MriIcon, UltrasoundIcon, MammogramIcon, XrayIcon, CloseIcon } from './Icons';

const Modal = styled.div`
  display: ${props => props.showModal ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(12, 41, 69, 0.05);
  backdrop-filter: blur(2px);
`

const ModalContentContainer = styled.div`	
  position: relative;	
  min-height: 400px;	
  width: 70%;	
  height: 80%;
  margin: 0 auto;
  background-color: white;
  cursor: default;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
`

const Header = styled.div`
  width: 100%;
  height: 60px;
  padding: 0 24px 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 70%;
  padding: 0 40px 0 30px;
`

const ModalTitle = styled.p`
  margin: 0;
  padding: 0;
  text-align: left;
  font-size: 18px;
  line-height: 120%;
  color: #0C2945;
`

const HorizontalLine = styled.hr`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 1px;
  background-color: #DFE0EB;
`

const IconContainer = styled.div`
  position: relative;
  z-index: 2;
  padding: 0;
  margin: 0;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00AEEF;
  border-radius: 50%;
  border: 2px solid #FFFFFF;
`

const HistoryCardContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:before {
    position: absolute;
    content: '';
    left: 14px;
    top: 31px;
    height: 110%;
    width: 2px;
    background: #C2CCD9;
  }
`

const InfoCardContainer = styled.div`
  margin-left: 25px;
  width: 100%;
  padding: 10px 0 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #FFFFFF;
  border: 1px solid #C2CCD9;
  box-sizing: border-box;
  border-radius: 8px;
`

const ModalityTitle = styled.p`
  margin: 0;
  padding: 0;
  line-height: 120%;
  color: #0C2945;
`

const Name = styled.p`
  margin: 0;
  padding: 0;
  font-family: "Open Sans",sans-serif !important;;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #252733;
`

const Date = styled.p`
  margin: 7px 0 0 0;
  padding: 0;
  font-family: "Open Sans",sans-serif !important;;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: #72808A;
`

const CardList = styled.ul`
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 200px;
  list-style: none;
  overflow: auto;
  user-select: text;
`

const ListItem = styled.li`
  width: 100%;
  margin-top: 10px;
`

const LoadMoreButton = styled.button`
  position: absolute;	
  left: 50%;	
  bottom: 10px;	
  margin-left: -75px;
  width: 150px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00AEEF;
  border-radius: 5px;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
  border: 0;
  opacity: ${props => props.disabled ? 0.4 : 1};
`

const CloseButton = styled.button`
  border: 0;
  background-color: #FFFFFF;
`

const setCurrectIcon = (name) => {
  name = name.toLowerCase();
  switch(name) {
    case 'ct':
      return CtIcon;
    case 'pet':
      return PetIcon;
    case 'mri':
      return MriIcon;
    case 'ultrasound':
      return UltrasoundIcon;
    case 'mammogram':
      return MammogramIcon;
    case 'x-ray':
      return XrayIcon;
    default:
      return CtIcon;
  }
}

const HistoryCard = ({icon, info}) => {
  const Icon = icon;
  return (
    <HistoryCardContainer>
      <IconContainer><Icon /></IconContainer>
      <InfoCardContainer>
        <ModalityTitle>Modality: {info.modality}</ModalityTitle>
        <Name>Referring Physician: {info.name}</Name>
        <Date>Exam Date: {info.date}</Date>
        <Date>Accession Number: {info.accession_number}</Date>
      </InfoCardContainer>
    </HistoryCardContainer>
  )
}

const PatientHistoryModal = ({showModal, closeModal, history, loadMore}) => {
  const onCloseModal = (e) => {
    e.stopPropagation();
    closeModal();
  }
  const onLoadMore = (e) => {
    e.stopPropagation();
    loadMore(true);
  }
  const prevDef = (e) => {	
    e.stopPropagation();	
  }
  return (
    <Modal
      showModal={showModal}
      onClick={prevDef}
    >
      <ModalContentContainer>
        <Header>
          <ModalTitle>Patient History</ModalTitle>
          <CloseButton type={'button'} onClick={onCloseModal}><CloseIcon /></CloseButton>
        </Header>
        <HorizontalLine />
        <Wrapper>
          <CardList>
            {
              history?.data && history.data.map(({id, modality, physicianName, date,accession_number}) => ( <ListItem key={id}>
                  <HistoryCard icon={setCurrectIcon(modality)} info={{modality, name: physicianName, date, accession_number}}/>
                </ListItem>
              ))
            }
          </CardList>
          <LoadMoreButton type={'button'} disabled={!history?.next} onClick={onLoadMore}>Load More</LoadMoreButton>
        </Wrapper>
      </ModalContentContainer>
    </Modal>
  )
}

export default PatientHistoryModal;
