import React, { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { Card } from "../../../styles";
import { ReactComponent as CheckIcon } from "../../../assets/checkIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getPermissions,
  updateRolePermission,
} from "../../../actions/radminSettings";
import { Spinner } from "react-bootstrap/esm";

const PageTitleText = styled.h2`
  font-family: Roboto Condensed;
  font-style: bold;
  font-weight: 700;
  font-size: 28px;
  line-height: 33.6px;
  letter-spacing: 0.2px;
  color: #0c2945;
  margin-bottom: 16px;
`;

const Input = styled.input`
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-radius: 50%;
  margin-right: 16px;
  border-color: ${(props) => props.border};
  background-color: ${(props) => props.background};
  background-clip: content-box;
  cursor: pointer;
  &[type="radio"] {
    padding: 3%;
  }
`;

const Chip = styled.div`
  background: ${(props) => props.background};
  border-radius: 20px;
  height: 24px;
  width: 106px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: ${(props) => props.hoveredBackground};
  }
`;

const ColumnHeaders = styled.p`
  width: 106px;
  font-weight: 400;
  font-size: 14px;
  font-family: Roboto Condensed;
  text-align: center;
  color: #000000;
`;

const AccessHeader = styled.h2`
  font-family: Roboto Condensed;
  font-style: bold;
  font-weight: 700;
  font-size: 22px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #252733;
  margin-bottom: 26px;
  text-align: left;
`;

const PermissionsHeader = styled.h4`
  font-style: bold;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #000000;
  margin-bottom: 25px;
`;

const Label = styled.label`
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 3px;
  margin-bottom: 0px;
  color: #222222;
`;

const RoleGroupContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margint-top: 4.5px;
  height: 16px;
  margin-bottom: 5px;
  flex-direction: column;
  margin-left: 7px;
  padding: 9px 11px;
`;

const RowLabel = styled.p`
  min-width: 126px;
  font-weight: 400;
  font-size: 14px;
  text-align: right;
  color: #000000;
  font-family: Open Sans;
`;

const Roles = () => {
  const [data, setData] = useState();
  const [selectedOption, setSelectedOption] = useState("admin");
  const dispatch = useDispatch();
  const rolePermissions = useSelector(
    (state) => state.radAdmin.rolePermissions
  );
  const rolePermissionsLoading = useSelector(
    (state) => state.radAdmin.rolePermissions.isLoading
  );

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    dispatch(getPermissions(selectedOption));
  }, [selectedOption]);

  useEffect(() => {
    setData(rolePermissions?.data);
  }, [rolePermissions]);

  return (
    <>
      <div style={{ display: "flex", gap: "180px" }}>
        <div>
          <PageTitleText>Role Permissions</PageTitleText>
          <PermissionsHeader>
            Choose the permissions for each role
          </PermissionsHeader>
          <RoleGroupContainer>
            <Label>
              <Input
                type="radio"
                value="admin"
                checked={selectedOption === "admin"}
                onChange={handleOptionChange}
                border={selectedOption === "admin" ? "#00AEEF" : "#D4D4D4"}
                background={selectedOption === "admin" ? "#00AEEF" : "#FFF"}
              />
              Admin
            </Label>

            <Label>
              <Input
                type="radio"
                value="editor"
                checked={selectedOption === "editor"}
                onChange={handleOptionChange}
                border={selectedOption === "editor" ? "#00AEEF" : "#D4D4D4"}
                background={selectedOption === "editor" ? "#00AEEF" : "#FFF"}
              />
              Editor
            </Label>
            <Label>
              <Input
                type="radio"
                value="viewer"
                checked={selectedOption === "viewer"}
                onChange={handleOptionChange}
                border={selectedOption === "viewer" ? "#00AEEF" : "#D4D4D4"}
                background={selectedOption === "viewer" ? "#00AEEF" : "#FFF"}
              />
              Viewer
            </Label>
          </RoleGroupContainer>
        </div>

        <Card style={{ padding: "30px", marginTop: "10px" }}>
          <AccessHeader>Admin Access Controls</AccessHeader>
          <div
            style={{ borderTop: "1px solid #D2D3D7", marginBottom: "33px" }}
          />
          <div>
            {rolePermissionsLoading ? (
              <div style={{ width: "710px" }}>
                <Spinner role="status" animation="border" size="sm" />
              </div>
            ) : (
              <>
                <div style={{ display: "flex" }}>
                  <ColumnHeaders
                    style={{ "margin-right": "30px", width: "126px" }}
                  ></ColumnHeaders>
                  <ColumnHeaders style={{ "margin-right": "30px" }}>
                    NO ACCESS
                  </ColumnHeaders>
                  <ColumnHeaders style={{ "margin-right": "4px" }}>
                    READ
                  </ColumnHeaders>
                  <ColumnHeaders style={{ "margin-right": "4px" }}>
                    CREATE
                  </ColumnHeaders>
                  <ColumnHeaders style={{ "margin-right": "4px" }}>
                    UPDATE
                  </ColumnHeaders>
                  <ColumnHeaders>DELETE</ColumnHeaders>
                </div>
                {data?.filter(itm=> itm.name !== "Campaigns")?.map((itm) => (
                  <div style={{ display: "flex", gap: "30px" }}>
                    <RowLabel>{itm.name}</RowLabel>
                    <Chip
                      hoveredBackground={"#F0B2B2"}
                      background={
                        itm.permissions.every((itm) => !itm.is_authorized)
                          ? "#CF0000"
                          : "#F0F0F0"
                      }
                      onClick={() => {
                        setData((prev) =>
                          prev.map((it) =>
                            it.id === itm.id
                              ? {
                                  ...it,
                                  permissions: it.permissions.map((child) => ({
                                    ...child,
                                    is_authorized: false,
                                  })),
                                }
                              : it
                          )
                        );

                        let newData = data
                          .filter((it) => it.id === itm.id)[0]
                          ?.permissions.forEach((element) => {
                            dispatch(
                              updateRolePermission({
                                permission: element.id,
                                role: selectedOption,
                                is_authorized: false,
                              })
                            );
                          });
                      }}
                    >
                      {itm.permissions.every((itm) => !itm.is_authorized) && (
                        <CheckIcon />
                      )}
                    </Chip>
                    <p style={{ display: "flex", gap: "4px" }}>
                      {itm.permissions.map((data, idx) => (
                        <Chip
                          hoveredBackground={"#B2E6FA"}
                          background={
                            data.is_authorized ? "#00AEEF" : "#F0F0F0"
                          }
                          onClick={() => {
                            let updatedPermissions = [...itm.permissions]; // Create a copy of permissions array
                            const clickedIndex = updatedPermissions.findIndex(
                              (permission) => permission.id === data.id
                            );

                            const isSelected =
                              updatedPermissions[clickedIndex].is_authorized;

                            if (isSelected) {
                              if (clickedIndex === 0) {
                                for (
                                  let i = clickedIndex;
                                  i < updatedPermissions.length;
                                  i++
                                ) {
                                  updatedPermissions[i].is_authorized = false;
                                  dispatch(
                                    updateRolePermission({
                                      permission: updatedPermissions[i].id,
                                      role: selectedOption,
                                      is_authorized: false,
                                    })
                                  );
                                }
                              } else {
                                updatedPermissions[
                                  clickedIndex
                                ].is_authorized = false;
                                dispatch(
                                  updateRolePermission({
                                    permission:
                                      updatedPermissions[clickedIndex].id,
                                    role: selectedOption,
                                    is_authorized: false,
                                  })
                                );
                              }
                            } else {
                              if (clickedIndex === 0) {
                                updatedPermissions[0].is_authorized = true;
                                dispatch(
                                  updateRolePermission({
                                    permission: updatedPermissions[0].id,
                                    role: selectedOption,
                                    is_authorized: true,
                                  })
                                );
                              } else {
                                updatedPermissions[0].is_authorized = true;
                                dispatch(
                                  updateRolePermission({
                                    permission: updatedPermissions[0].id,
                                    role: selectedOption,
                                    is_authorized: true,
                                  })
                                );
                                updatedPermissions[
                                  clickedIndex
                                ].is_authorized = true;
                                dispatch(
                                  updateRolePermission({
                                    permission:
                                      updatedPermissions[clickedIndex].id,
                                    role: selectedOption,
                                    is_authorized: true,
                                  })
                                );
                              }
                            }

                            setData((prev) =>
                              prev.map((it) =>
                                it.id === itm.id
                                  ? {
                                      ...it,
                                      permissions: updatedPermissions,
                                    }
                                  : it
                              )
                            );
                          }}
                        >
                          {data.is_authorized && <CheckIcon />}
                        </Chip>
                      ))}
                    </p>
                  </div>
                ))}
              </>
            )}
          </div>
        </Card>
      </div>
    </>
  );
};

export default Roles;
