import {
  throttle,
  isEmpty,
  isNull,
  capitalize,
  camelCase,
  omitBy,
  isUndefined,
  isNil,
} from 'lodash';
import moment from 'moment';
import queryString from 'query-string';

import API from '../../libs/api';
import {
  sortByAsc,
  setModalityCase,
  prepareFollowUpStatusReq,
} from '../../utils/utils';
import { SET_IS_HAVE_UPLOADS } from '../auth';
import {
  generateNewSequence,
  defaultSequences,
  demoSequences,
} from '../../pages/RadAdmin/Campaigns/Create/contactSequenceData';
import { buildCampaignStepPayload } from './helpers';

const scoped = (name) => `RADADMIN/${name}`;
const mockResponse = { data: { response: { id: Date.now() } } };

export const SHOW_ALERT = scoped('SHOW_ALERT');
export const HIDE_ALERT = scoped('HIDE_ALERT');
export const SET_PROGRESS_BAR_VALUE = scoped('SET_PROGRESS_BAR_VALUE');

export const CREATE_RADMIN_UPLOAD_FETCH = 'CREATE_RADMIN_UPLOAD_FETCH';
export const CREATE_RADMIN_UPLOAD_RESOLVE = 'CREATE_RADMIN_UPLOAD_RESOLVE';
export const CREATE_RADMIN_UPLOAD_REJECT = 'CREATE_RADMIN_UPLOAD_REJECT';

export const SET_IS_WAS_FIRST_IMPORT = 'SET_IS_WAS_FIRST_IMPORT';

export const GET_DOCTORS_LIST_REQUEST = 'GET_DOCTORS_LIST_REQUEST';
export const GET_DOCTORS_LIST_FAILURE = 'GET_DOCTORS_LIST_FAILURE';
export const GET_DOCTORS_LIST_SUCCESS = 'GET_DOCTORS_LIST_SUCCESS';


export const GET_DOCTORS_LIST_DROPDOWN_REQUEST = 'GET_DOCTORS_LIST_DROPDOWN_REQUEST';
export const GET_DOCTORS_LIST_DROPDOWN_FAILURE = 'GET_DOCTORS_LIST_DROPDOWN_FAILURE';
export const GET_DOCTORS_LIST_DROPDOWN_SUCCESS = 'GET_DOCTORS_LIST_DROPDOWN_SUCCESS';

export const GET_PATIENT_LIST_VIEW_REQUEST = 'GET_PATIENT_LIST_VIEW_REQUEST';
export const GET_PATIENT_LIST_VIEW_SUCCESS = 'GET_PATIENT_LIST_VIEW_SUCCESS';
export const GET_PATIENT_LIST_VIEW_FAILURE = 'GET_PATIENT_LIST_VIEW_FAILURE';

export const SET_PATIENTS_SEARCH_VALUE = 'SET_PATIENTS_SEARCH_VALUE';

export const SET_DOCTOR_FOR_PATIENTS = 'SET_DOCTOR_FOR_PATIENTS';

export const GET_DOCTOR_REQUEST = 'GET_DOCTOR_REQUEST';
export const GET_DOCTOR_FAILURE = 'GET_DOCTOR_FAILURE';
export const GET_DOCTOR_SUCCESS = 'GET_DOCTOR_SUCCESS';
export const SET_DOCTOR_SEARCH_VALUE = 'SET_DOCTOR_SEARCH_VALUE';

export const GET_PATIENTS_LIST_REQUEST = 'GET_PATIENTS_LIST_REQUEST';
export const GET_PATIENTS_LIST_FAILURE = 'GET_PATIENTS_LIST_FAILURE';
export const GET_PATIENTS_LIST_SUCCESS = 'GET_PATIENTS_LIST_SUCCESS';
export const RESET_PATIENTS_DATA = 'RESET_PATIENTS_DATA';

export const GET_PATIENT_REPORTS_REQUEST = 'GET_PATIENT_REPORTS_REQUEST';
export const GET_PATIENT_REPORTS_FAILURE = 'GET_PATIENT_REPORTS_FAILURE';
export const GET_PATIENT_REPORTS_SUCCESS = 'GET_PATIENT_REPORTS_SUCCESS';

export const GET_PREVIOUS_UPLOADS_LIST_REQUEST =
  'GET_PREVIOUS_UPLOADS_LIST_REQUEST';
export const GET_PREVIOUS_UPLOADS_LIST_FAILURE =
  'GET_PREVIOUS_UPLOADS_LIST_FAILURE';
export const GET_PREVIOUS_UPLOADS_LIST_SUCCESS =
  'GET_PREVIOUS_UPLOADS_LIST_SUCCESS';

export const GET_FOLLOW_UP_LIST_REQUEST = 'GET_FOLLOW_UP_LIST_REQUEST';
export const GET_FOLLOW_UP_LIST_FAILURE = 'GET_FOLLOW_UP_LIST_FAILURE';
export const GET_FOLLOW_UP_LIST_SUCCESS = 'GET_FOLLOW_UP_LIST_SUCCESS';

export const UPDATE_FOLLOW_UP_LIST_REQUEST = 'UPDATE_FOLLOW_UP_LIST_REQUEST';
export const UPDATE_FOLLOW_UP_LIST_FAILURE = 'UPDATE_FOLLOW_UP_LIST_FAILURE';
export const UPDATE_FOLLOW_UP_LIST_SUCCESS = 'UPDATE_FOLLOW_UP_LIST_SUCCESS';

export const GET_RECO_CONFIG_REQUEST = 'GET_RECO_CONFIG_REQUEST';
export const GET_RECO_CONFIG_FAILURE = 'GET_RECO_CONFIG_FAILURE';
export const GET_RECO_CONFIG_SUCCESS = 'GET_RECO_CONFIG_SUCCESS';

export const UPDATE_RECO_CONFIG_REQUEST = 'UPDATE_RECO_CONFIG_REQUEST';
export const UPDATE_RECO_CONFIG_FAILURE = 'UPDATE_RECO_CONFIG_FAILURE';
export const UPDATE_RECO_CONFIG_SUCCESS = 'UPDATE_RECO_CONFIG_SUCCESS';

export const DELETE_FOLLOW_UP_LIST_SUCCESS = 'DELETE_FOLLOW_UP_LIST_SUCCESS';

export const GET_TOTAL_STATUSES_REQUEST = 'GET_TOTAL_STATUSES_REQUEST';
export const GET_TOTAL_STATUSES_FAILURE = 'GET_TOTAL_STATUSES_FAILURE';
export const GET_TOTAL_STATUSES_SUCCESS = 'GET_TOTAL_STATUSES_SUCCESS';

export const GET_ADHERENCE_OF_DATE_REQUEST= 'GET_ADHERENCE_OF_DATE_REQUEST';
export const GET_ADHERENCE_OF_DATE_SUCCESS = 'GET_ADHERENCE_OF_DATE_SUCCESS';
export const GET_ADHERENCE_OF_DATE_FAILURE= 'GET_ADHERENCE_OF_DATE_FAILURE';

export const GET_ADHERENCE_OVER_RATE_REQUEST = 'GET_ADHERENCE_OVER_RATE_REQUEST';
export const GET_ADHERENCE_OVER_RATE_SUCCESS =
  'GET_ADHERENCE_OVER_RATE_SUCCESS';
export const GET_ADHERENCE_OVER_RATE_FAILURE =
  'GET_ADHERENCE_OVER_RATE_FAILURE';
  
export const GET_REVENUE_CHARGES_REQUEST =
'GET_REVENUE_CHARGES_REQUEST';
export const GET_REVENUE_CHARGES_SUCCESS =
'GET_REVENUE_CHARGES_SUCCESS';
export const GET_REVENUE_CHARGES_FAILURE =
'GET_REVENUE_CHARGES_FAILURE';


export const GET_TOTAL_REVENUE_CHARGES_REQUEST =
'GET_TOTAL_REVENUE_CHARGES_REQUEST';
export const GET_TOTAL_REVENUE_CHARGES_SUCCESS =
'GET_TOTAL_REVENUE_CHARGES_SUCCESS';
export const GET_TOTAL_REVENUE_CHARGES_FAILURE =
'GET_TOTAL_REVENUE_CHARGES_FAILURE';

export const GET_TOTAL_REVENUE_CHARGES_AVERAGE_SUCCESS =
'GET_TOTAL_REVENUE_CHARGES_AVERAGE_SUCCESS';

export const GET_ATTRIBUTE_REVENUE_CHARGES_REQUEST =
'GET_ATTRIBUTE_REVENUE_CHARGES_REQUEST';
export const GET_ATTRIBUTE_REVENUE_CHARGES_SUCCESS =
'GET_ATTRIBUTE_REVENUE_CHARGES_SUCCESS';
export const GET_ATTRIBUTE_REVENUE_CHARGES_FAILURE =
'GET_ATTRIBUTE_REVENUE_CHARGES_FAILURE';


export const GET_RECO_HISTORY_REQUEST = 'GET_RECO_HISTORY_REQUEST';
export const GET_RECO_HISTORY_FAILURE = 'GET_RECO_HISTORY_FAILURE';
export const GET_RECO_HISTORY_SUCCESS = 'GET_RECO_HISTORY_SUCCESS';

export const GET_RADIOLOGIST_LIST_REQUEST = 'GET_RADIOLOGIST_LIST_REQUEST';
export const GET_RADIOLOGIST_LIST_FAILURE = 'GET_RADIOLOGIST_LIST_FAILURE';
export const GET_RADIOLOGIST_LIST_SUCCESS = 'GET_RADIOLOGIST_LIST_SUCCESS';

export const GET_RADIOLOGIST_LEADERBOARD_SUCCESS =
  'GET_RADIOLOGIST_LEADERBOARD_SUCCESS';
export const GET_RADIOLOGIST_LEADERBOARD_REQUEST =
  'GET_RADIOLOGIST_LEADERBOARD_REQUEST';
export const GET_RADIOLOGIST_LEADERBOARD_REQUEST_FAILURE =
  'GET_RADIOLOGIST_LEADERBOARD_REQUEST_FAILURE';
export const SET_RADIOLOGIST_SEARCH_VALUE = 'SET_RADIOLOGIST_SEARCH_VALUE';

export const CREATE_NOTE_REQUEST = 'CREATE_NOTE_REQUEST';
export const CREATE_NOTE_SUCCESS = 'CREATE_NOTE_SUCCESS';
export const CREATE_NOTE_FAILURE = 'CREATE_NOTE_FAILURE';

export const UPDATE_NOTE_REQUEST = 'UPDATE_NOTE_REQUEST';
export const UPDATE_NOTE_SUCCESS = 'UPDATE_NOTE_SUCCESS';
export const UPDATE_NOTE_FAILURE = 'UPDATE_NOTE_FAILURE';

export const GET_NOTES_REQUEST = 'GET_NOTES_REQUEST';
export const GET_NOTES_SUCCESS = 'GET_NOTES_SUCCESS';
export const GET_NOTES_FAILURE = 'GET_NOTES_FAILURE';

export const GET_ALL_NOTES_REQUEST = 'GET_ALL_NOTES_REQUEST';
export const GET_ALL_NOTES_SUCCESS = 'GET_ALL_NOTES_SUCCESS';
export const GET_ALL_NOTES_FAILURE = 'GET_ALL_NOTES_FAILURE';

export const GET_NOTES_ANALYTICS_REQUEST = 'GET_NOTES_ANALYTICS_REQUEST';
export const GET_NOTES_ANALYTICS_SUCCESS = 'GET_NOTES_ANALYTICS_SUCCESS';
export const GET_NOTES_ANALYTICS_FAILURE = 'GET_NOTES_ANALYTICS_FAILURE';

export const GET_MODALITY_LIST_REQUEST = 'GET_MODALITY_LIST_REQUEST';
export const GET_MODALITY_LIST_FAILURE = 'GET_MODALITY_LIST_FAILURE';
export const GET_MODALITY_LIST_SUCCESS = 'GET_MODALITY_LIST_SUCCESS';

export const GET_ANATOMY_LIST_REQUEST = 'GET_ANATOMY_LIST_REQUEST';
export const GET_ANATOMY_LIST_FAILURE = 'GET_ANATOMY_LIST_FAILURE';
export const GET_ANATOMY_LIST_SUCCESS = 'GET_ANATOMY_LIST_SUCCESS';

export const GET_TIMEFRAME_STATUS_LIST_REQUEST =
  'GET_TIMEFRAME_STATUS_LIST_REQUEST';
export const GET_TIMEFRAME_STATUS_LIST_FAILURE =
  'GET_TIMEFRAME_STATUS_LIST_FAILURE';
export const GET_TIMEFRAME_STATUS_LIST_SUCCESS =
  'GET_TIMEFRAME_STATUS_LIST_SUCCESS';

export const CHANGE_ACTION_STATUS_REQUEST = 'CHANGE_ACTION_STATUS_REQUEST';
export const CHANGE_ACTION_STATUS_FAILURE = 'CHANGE_ACTION_STATUS_FAILURE';
export const CHANGE_ACTION_STATUS_SUCCESS = 'CHANGE_ACTION_STATUS_SUCCESS';

export const GET_IMAGING_ADHERENCE_REQUEST = 'GET_IMAGING_ADHERENCE_REQUEST';
export const GET_IMAGING_ADHERENCE_SUCCESS = 'GET_IMAGING_ADHERENCE_SUCCESS';
export const GET_IMAGING_ADHERENCE_FAILURE = 'GET_IMAGING_ADHERENCE_FAILURE';

export const GET_RECOMMENDATION_TIMEFRAME_REQUEST = 'GET_RECOMMENDATION_TIMEFRAME_REQUEST';
export const GET_RECOMMENDATION_TIMEFRAME_SUCCESS = 'GET_RECOMMENDATION_TIMEFRAME_SUCCESS';
export const GET_RECOMMENDATION_TIMEFRAME_FAILURE = 'GET_RECOMMENDATION_TIMEFRAME_FAILURE';

export const GET_MODALITIES_REQUEST = 'GET_MODALITIES_REQUEST';
export const GET_MODALITIES_SUCCESS = 'GET_MODALITIES_SUCCESS';
export const GET_MODALITIES_FAILURE = 'GET_MODALITIES_FAILURE';

export const GET_RECO_MODALITIES_REQUEST = 'GET_RECO_MODALITIES_REQUEST';
export const GET_RECO_MODALITIES_SUCCESS = 'GET_RECO_MODALITIES_SUCCESS';
export const GET_RECO_MODALITIES_FAILURE = 'GET_RECO_MODALITIES_FAILURE';

export const GET_RECO_ANATOMIES_REQUEST = 'GET_RECO_ANATOMIES_REQUEST';
export const GET_RECO_ANATOMIES_SUCCESS = 'GET_RECO_ANATOMIES_SUCCESS';
export const GET_RECO_ANATOMIES_FAILURE = 'GET_RECO_ANATOMIES_FAILURE';

export const GET_RECO_ANATOMIES_AND_MODALITIES_REQUEST = 'GET_RECO_ANATOMIES_AND_MODALITIES_REQUEST';
export const GET_RECO_ANATOMIES_AND_MODALITIES_SUCCESS = 'GET_RECO_ANATOMIES_AND_MODALITIES_SUCCESS';
export const GET_RECO_ANATOMIES_AND_MODALITIES_FAILURE = 'GET_RECO_ANATOMIES_AND_MODALITIES_FAILURE';

export const GET_IMAGING_ADHERENCE_MODALITY_OPTIONS_REQUEST =
  'GET_IMAGING_ADHERENCE_MODALITY_OPTIONS_REQUEST';
export const GET_IMAGING_ADHERENCE_MODALITY_OPTIONS_SUCCESS =
  'GET_IMAGING_ADHERENCE_MODALITY_OPTIONS_SUCCESS';
export const GET_IMAGING_ADHERENCE_MODALITY_OPTIONS_FAILURE =
  'GET_IMAGING_ADHERENCE_MODALITY_OPTIONS_FAILURE';

export const GET_EXPORT_REPORT_REQUEST = 'GET_EXPORT_REPORT_REQUEST';
export const GET_EXPORT_REPORT_FAILURE = 'GET_EXPORT_REPORT_FAILURE';
export const GET_EXPORT_REPORT_SUCCESS = 'GET_EXPORT_REPORT_SUCCESS';

export const GET_RECENT_INCOMING_MESSAGES_REQUEST =
  'GET_RECENT_INCOMING_MESSAGES_REQUEST';
export const GET_RECENT_INCOMING_MESSAGES_FAILURE =
  'GET_RECENT_INCOMING_MESSAGES_FAILURE';
export const GET_RECENT_INCOMING_MESSAGES_SUCCESS =
  'GET_RECENT_INCOMING_MESSAGES_SUCCESS';
export const SET_USERS_MESSAGES_INFO = 'SET_USERS_MESSAGES_INFO';

export const GET_MORE_CHAT_MESSAGES_REQUEST = 'GET_MORE_CHAT_MESSAGES_REQUEST';
export const GET_MORE_CHAT_MESSAGES_FAILURE = 'GET_MORE_CHAT_MESSAGES_FAILURE';
export const GET_MORE_CHAT_MESSAGES_SUCCESS = 'GET_MORE_CHAT_MESSAGES_SUCCESS';
export const SET_USERS_PHONE_NUMBERS = 'SET_USERS_PHONE_NUMBERS';

export const CREATE_OUTGOING_MESSAGE_REQUEST =
  'CREATE_OUTGOING_MESSAGE_REQUEST';
export const CREATE_OUTGOING_MESSAGE_FAILURE =
  'CREATE_OUTGOING_MESSAGE_FAILURE';
export const CREATE_OUTGOING_MESSAGE_SUCCESS =
  'CREATE_OUTGOING_MESSAGE_SUCCESS';

export const CREATE_OUTGOING_MESSAGE_REQUEST_FROM_DIALOG =
  'CREATE_OUTGOING_MESSAGE_REQUEST_FROM_DIALOG';
export const CREATE_OUTGOING_MESSAGE_FAILURE_FROM_DIALOG =
  'CREATE_OUTGOING_MESSAGE_FAILURE_FROM_DIALOG';
export const CREATE_OUTGOING_MESSAGE_SUCCESS_FROM_DIALOG =
  'CREATE_OUTGOING_MESSAGE_SUCCESS_FROM_DIALOG';

export const GET_TWILIO_CONFIG_REQUEST = 'GET__TWILIO_CONFIG_REQUEST';
export const GET_TWILIO_CONFIG_FAILURE = 'GET_TWILIO_CONFIG_FAILURE';
export const GET_TWILIO_CONFIG_SUCCESS = 'GET_TWILIO_CONFIG_SUCCESS';

export const GET_FINDINGS_BOI_REQUEST = 'GET_FINDINGS_BOI_REQUEST';
export const GET_FINDINGS_BOI_FAILURE = 'GET_FINDINGS_BOI_FAILURE';
export const GET_FINDINGS_BOI_SUCCESS = 'GET_FINDINGS_BOI_SUCCESS';

export const UPDATE_TWILIO_CONFIG_REQUEST = 'UPDATE_TWILIO_CONFIG_REQUEST';
export const UPDATE_TWILIO_CONFIG_FAILURE = 'UPDATE_TWILIO_CONFIG_FAILURE';
export const UPDATE_TWILIO_CONFIG_SUCCESS = 'UPDATE_TWILIO_CONFIG_SUCCESS';

export const DELETE_TWILIO_CONFIG_REQUEST = 'DELETE_TWILIO_CONFIG_REQUEST';
export const DELETE_TWILIO_CONFIG_SECCESS = 'DELETE_TWILIO_CONFIG_SECCESS';
export const DELETE_TWILIO_CONFIG_FAILURE = 'DELETE_TWILIO_CONFIG_FAILURE';

export const GET_SMS_RESPONSE_REQUEST = 'GET_SMS_RESPONSE_REQUEST';
export const GET_SMS_RESPONSE_FAILURE = 'GET_SMS_RESPONSE_FAILURE';
export const GET_SMS_RESPONSE_SUCCESS = 'GET_SMS_RESPONSE_SUCCESS';

export const UPDATE_SMS_RESPONSE_REQUEST = 'UPDATE_SMS_RESPONSE_REQUEST';
export const UPDATE_SMS_RESPONSE_FAILURE = 'UPDATE_SMS_RESPONSE_FAILURE';
export const UPDATE_SMS_RESPONSE_SUCCESS = 'UPDATE_SMS_RESPONSE_SUCCESS';

export const GET_PATIENT_HISTORY_REQUEST = 'GET_PATIENT_HISTORY_REQUEST';
export const GET_PATIENT_HISTORY_FAILURE = 'GET_PATIENT_HISTORY_FAILURE';
export const GET_PATIENT_HISTORY_SUCCESS = 'GET_PATIENT_HISTORY_SUCCESS';

export const GET_ADHERENCE_FUNNEL_REQUEST = 'GET_ADHERENCE_FUNNEL_REQUEST';
export const GET_ADHERENCE_FUNNEL_SUCCESS = 'GET_ADHERENCE_FUNNEL_SUCCESS';
export const GET_ADHERENCE_FUNNEL_FAILURE = 'GET_ADHERENCE_FUNNEL_FAILURE';

export const GET_ADHERENCE_OF_TODAY_REQUEST = 'GET_ADHERENCE_OF_TODAY_REQUEST';
export const GET_ADHERENCE_OF_TODAY_SUCCESS = 'GET_ADHERENCE_OF_TODAY_SUCCESS';
export const GET_ADHERENCE_OF_TODAY_FAILURE = 'GET_ADHERENCE_OF_TODAY_FAILURE';

export const GET_TEMPLATES_REQUEST = 'GET_TEMPLATES_REQUEST';
export const GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS';
export const GET_TEMPLATES_FAILURE = 'GET_TEMPLATES_FAILURE';
export const CREATE_TEMPLATE_SUCCESS = 'CREATE_TEMPLATE_SUCCESS';

export const GET_FAX_TEMPLATES_REQUEST = 'GET_FAX_TEMPLATES_REQUEST';
export const GET_FAX_TEMPLATES_SUCCESS = 'GET_FAX_TEMPLATES_SUCCESS';
export const GET_FAX_TEMPLATES_FAILURE = 'GET_FAX_TEMPLATES_FAILURE';

export const GET_LETTER_TEMPLATES_REQUEST = 'GET_LETTER_TEMPLATES_REQUEST';
export const GET_LETTER_TEMPLATES_SUCCESS = 'GET_LETTER_TEMPLATES_SUCCESS';
export const GET_LETTER_TEMPLATES_FAILURE = 'GET_LETTER_TEMPLATES_FAILURE';

export const GET_OUTGOING_MESSAGES_REQUEST = 'GET_OUTGOING_MESSAGES_REQUEST';
export const GET_OUTGOING_MESSAGES_SUCCESS = 'GET_OUTGOING_MESSAGES_SUCCESS';
export const GET_OUTGOING_MESSAGES_FAILURE = 'GET_OUTGOING_MESSAGES_FAILURE';

export const GET_OUTGOING_EMAIL_REQUEST = 'GET_OUTGOING_EMAIL_REQUEST';
export const GET_OUTGOING_EMAIL_SUCCESS = 'GET_OUTGOING_EMAIL_SUCCESS';
export const GET_OUTGOING_EMAIL_FAILURE = 'GET_OUTGOING_EMAIL_FAILURE';


export const GET_OUTGOING_FAXES_REQUEST = 'GET_OUTGOING_FAXES_REQUEST';
export const GET_OUTGOING_FAXES_SUCCESS = 'GET_OUTGOING_FAXES_SUCCESS';
export const GET_OUTGOING_FAXES_FAILURE = 'GET_OUTGOING_FAXES_FAILURE';

export const GET_SEARCH_REPORT_TEXT_REQUEST = 'GET_SEARCH_REPORT_TEXT_REQUEST';
export const GET_SEARCH_REPORT_TEXT_SUCCESS = 'GET_SEARCH_REPORT_TEXT_SUCCESS';
export const GET_SEARCH_REPORT_TEXT_FAILURE = 'GET_SEARCH_REPORT_TEXT_FAILURE';


export const SET_TEMPLATE_ID_TO_PREVIEW = 'SET_TEMPLATE_ID_TO_PREVIEW';
export const SET_TEMPLATE_TO_PREVIEW = 'SET_TEMPLATE_TO_PREVIEW';


export const SET_FAX_TEMPLATE_ID_TO_PREVIEW = 'SET_FAX_TEMPLATE_ID_TO_PREVIEW';
export const SET_FAX_TEMPLATE_TO_PREVIEW = 'SET_FAX_TEMPLATE_TO_PREVIEW';

export const GET_FOLLOWUP_LIST_FROM_MESSAGES_SUCCESS =
  'GET_FOLLOWUP_LIST_FROM_MESSAGES_SUCCESS';
export const GET_FOLLOWUP_LIST_FROM_MESSAGES_REQUEST =
  'GET_FOLLOWUP_LIST_FROM_MESSAGES_REQUEST';

export const GET_COHORTS_REQUEST = 'GET_COHORTS_REQUEST';
export const GET_COHORTS_SUCCESS = 'GET_COHORTS_SUCCESS';
export const GET_COHORTS_FAILURE = 'GET_COHORTS_FAILURE';

export const GET_COHORT_WITH_ID_REQUEST = 'GET_COHORT_WITH_ID_REQUEST';
export const GET_COHORT_WITH_ID_SUCCESS = 'GET_COHORT_WITH_ID_SUCCESS';
export const GET_COHORT_WITH_ID_FAILURE = 'GET_COHORT_WITH_ID_FAILURE';

export const CREATE_COHORTS_EXPORT_REQUEST = 'CREATE_COHORTS_EXPORT_REQUEST';

export const GET_DIMENTION_REQUEST = 'GET_DIMENTION_REQUEST';
export const GET_DIMENTION_SUCCESS = 'GET_DIMENTION_SUCCESS';
export const GET_DIMENTION_FAILURE = 'GET_DIMENTION_FAILURE';

export const GET_ALL_DIMENTIONS_REQUEST = 'GET_ALL_DIMENTIONS_REQUEST';
export const GET_ALL_DIMENTIONS_SUCCESS = 'GET_ALL_DIMENTIONS_SUCCESS';
export const GET_ALL_DIMENTIONS_FAILURE = 'GET_ALL_DIMENTIONS_FAILURE';

export const GET_PATIENT_IN_COHORT_REQUEST = 'GET_PATIENT_IN_COHORT_REQUEST';
export const GET_PATIENT_IN_COHORT_SUCCESS = 'GET_PATIENT_IN_COHORT_SUCCESS';
export const GET_PATIENT_IN_COHORT_FAILURE = 'GET_PATIENT_IN_COHORT_FAILURE';

export const GET_PATIENT_BY_ID_REQUEST = 'GET_PATIENT_BY_ID_REQUEST';
export const GET_PATIENT_BY_ID_SUCCESS = 'GET_PATIENT_BY_ID_SUCCESS';
export const GET_PATIENT_BY_ID_FAILURE = 'GET_PATIENT_BY_ID_FAILURE';

export const GET_APPOINTMENT_BY_PATIENT_ID_REQUEST =
  'GET_APPOINTMENT_BY_PATIENT_ID_REQUEST';
export const GET_APPOINTMENT_BY_PATIENT_ID_SUCCESS =
  'GET_APPOINTMENT_BY_PATIENT_ID_SUCCESS';
export const GET_APPOINTMENT_BY_PATIENT_ID_FAILURE =
  'GET_APPOINTMENT_BY_PATIENT_ID_FAILURE';

export const GET_REPORT_BY_PATIENT_ID_REQUEST =
  'GET_REPORT_BY_PATIENT_ID_REQUEST';
export const GET_REPORT_BY_PATIENT_ID_SUCCESS =
  'GET_REPORT_BY_PATIENT_ID_SUCCESS';
export const GET_REPORT_BY_PATIENT_ID_FAILURE =
  'GET_REPORT_BY_PATIENT_ID_FAILURE';

export const GET_PRESCRIPTIONS_BY_PATIENT_ID_SUCCESS = 'GET_PRESCRIPTIONS_BY_PATIENT_ID_SUCCESS';
export const GET_PRESCRIPTIONS_BY_PATIENT_ID_FAILURE = 'GET_PRESCRIPTIONS_BY_PATIENT_ID_FAILURE';
export const GET_PRESCRIPTIONS_BY_PATIENT_ID_REQUEST = 'GET_PRESCRIPTIONS_BY_PATIENT_ID_REQUEST';


 export const GET_EMAILS_BY_PATIENT_ID_SUCCESS = 'GET_EMAILS_BY_PATIENT_ID_SUCCESS';
 export const GET_EMAILS_BY_PATIENT_ID_FAILURE = 'GET_EMAILS_BY_PATIENT_ID_FAILURE';
 export const GET_EMAILS_BY_PATIENT_ID_REQUEST = 'GET_EMAILS_BY_PATIENT_ID_REQUEST';

 export const GET_COHORTS_BY_PATIENT_ID_SUCCESS = 'GET_COHORTS_BY_PATIENT_ID_SUCCESS';
 export const GET_COHORTS_BY_PATIENT_ID_FAILURE = 'GET_COHORTS_BY_PATIENT_ID_FAILURE';
 export const GET_COHORTS_BY_PATIENT_ID_REQUEST = 'GET_COHORTS_BY_PATIENT_ID_REQUEST';

export const GET_RECO_BY_PATIENT_ID_REQUEST = 'GET_RECO_BY_PATIENT_ID_REQUEST';
export const GET_RECO_BY_PATIENT_ID_SUCCESS = 'GET_RECO_BY_PATIENT_ID_SUCCESS';
export const GET_RECO_BY_PATIENT_ID_FAILURE = 'GET_RECO_BY_PATIENT_ID_FAILURE';


export const UPDATE_RECO_BY_PATIENT_ID_REQUEST = 'UPDATE_RECO_BY_PATIENT_ID_REQUEST';
export const UPDATE_RECO_BY_PATIENT_ID_SUCCESS = 'UPDATE_RECO_BY_PATIENT_ID_SUCCESS';
export const UPDATE_RECO_BY_PATIENT_ID_FAILURE = 'UPDATE_RECO_BY_PATIENT_ID_FAILURE';

export const UPDATE_PATIENT_BY_ID_REQUEST = 'UPDATE_PATIENT_BY_ID_REQUEST';
export const UPDATE_PATIENT_BY_ID_SUCCESS = 'UPDATE_PATIENT_BY_ID_SUCCESS';
export const UPDATE_PATIENT_BY_ID_FAILURE = 'UPDATE_PATIENT_BY_ID_FAILURE';

export const GET_MESSAGES_BY_PATIENT_PHONE_REQUEST =
  'GET_MESSAGES_BY_PATIENT_PHONE_REQUEST';
export const GET_MESSAGES_BY_PATIENT_PHONE_SUCCESS =
  'GET_MESSAGES_BY_PATIENT_PHONE_SUCCESS';
export const GET_MESSAGES_BY_PATIENT_PHONE_FAILURE =
  'GET_MESSAGES_BY_PATIENT_PHONE_FAILURE';

export const CREATE_COHORT_REQUEST = 'CREATE_COHORT_REQUEST';
export const CREATE_COHORT_SUCCESS = 'CREATE_COHORT_SUCCESS';
export const CREATE_COHORT_FAILURE = 'CREATE_COHORT_FAILURE';

export const GET_DIMENSIONS_TO_EDIT_REQUEST = 'GET_DIMENSIONS_TO_EDIT_REQUEST';
export const GET_DIMENSIONS_TO_EDIT_SUCCESS = 'GET_DIMENSIONS_TO_EDIT_SUCCESS';
export const GET_DIMENSIONS_TO_EDIT_FAILURE = 'GET_DIMENSIONS_TO_EDIT_FAILURE';
export const SET_DIMENSIONS_TO_INITIAL = 'SET_DIMENSIONS_TO_INITIAL';

export const GET_COHORT_FIXED_VALUES_REQUEST =
  'GET_COHORT_FIXED_VALUES_REQUEST';
export const GET_COHORT_FIXED_VALUES_SUCCESS =
  'GET_COHORT_FIXED_VALUES_SUCCESS';
export const GET_COHORT_FIXED_VALUES_FAILURE =
  'GET_COHORT_FIXED_VALUES_FAILURE';

export const GET_CAMPAIGNS_REQUEST = 'GET_CAMPAIGNS_REQUEST';
export const GET_CAMPAIGNS_REQUEST_SUCCESS = 'GET_CAMPAIGNS_REQUEST_SUCCESS';
export const GET_CAMPAIGNS_REQUEST_FAILURE = 'GET_CAMPAIGNS_REQUEST_FAILURE';

export const CREATE_CAMPAIGN_STEP = 'CREATE_CAMPAIGN_STEP';
export const FETCH_CAMPAIGN_STEPS = 'FETCH_CAMPAIGN_STEPS';
export const DELETE_CAMPAIGN_STEP = 'DELETE_CAMPAIGN_STEP';
export const EDIT_CAMPAIGN_STEP = 'EDIT_CAMPAIGN_STEP';

export const RESET_CAMPAIGN = 'RESET_CAMPAIGN';
export const CREATE_CAMPAIGNS_REQUEST = 'CREATE_CAMPAIGNS_REQUEST';
export const CREATE_CAMPAIGNS_REQUEST_SUCCESS =
  'CREATE_CAMPAIGNS_REQUEST_SUCCESS';
export const CREATE_CAMPAIGNS_REQUEST_FAILURE =
  'CREATE_CAMPAIGNS_REQUEST_FAILURE';

export const DELETE_CAMPAIGNS_REQUEST = 'DELETE_CAMPAIGNS_REQUEST';
export const DELETE_CAMPAIGNS_REQUEST_SUCCESS =
  'DELETE_CAMPAIGNS_REQUEST_SUCCESS';
export const DELETE_CAMPAIGNS_REQUEST_FAILURE =
  'DELETE_CAMPAIGNS_REQUEST_FAILURE';

export const GET_CAMPAIGNS_ATTRIBUTE_CHOICES_REQUEST =
  'GET_CAMPAIGNS_ATTRIBUTE_CHOICES_REQUEST';
export const GET_CAMPAIGNS_ATTRIBUTE_CHOICES_SUCCESS =
  'GET_CAMPAIGNS_ATTRIBUTE_CHOICES_SUCCESS';
export const GET_CAMPAIGNS_ATTRIBUTE_CHOICES_FAILURE =
  'GET_CAMPAIGNS_ATTRIBUTE_CHOICES_FAILURE';

export const EXPORT_MESSAGES_REQUEST = 'EXPORT_MESSAGES_REQUEST';
export const EXPORT_MESSAGES_SUCCESS = 'EXPORT_MESSAGES_SUCCESS';
export const EXPORT_MESSAGES_FAILURE = 'EXPORT_MESSAGES_FAILURE';

export const GET_DROPDOWN_RADIOLOGISTS_REQUEST =
  'GET_DROPDOWN_RADIOLOGISTS_REQUEST';
export const GET_DROPDOWN_RADIOLOGISTS_SUCCESS =
  'GET_DROPDOWN_RADIOLOGISTS_SUCCESS';
export const GET_DROPDOWN_RADIOLOGISTS_FAILURE =
  'GET_DROPDOWN_RADIOLOGISTS_FAILURE';


export const GET_COHORTS_DROPDOWN_REQUEST =
  'GET_COHORTS_DROPDOWN_REQUEST';
export const GET_COHORTS_DROPDOWN_SUCCESS =
  'GET_COHORTS_DROPDOWN_SUCCESS';
export const GET_COHORTS_DROPDOWN_FAILURE =
  'GET_COHORTS_DROPDOWN_FAILURE';

export const GET_DROPDOWN_REFERRERS_REQUEST =
  'GET_DROPDOWN_REFERRERS_REQUEST';
export const GET_DROPDOWN_REFERRERS_SUCCESS =
  'GET_DROPDOWN_REFERRERS_SUCCESS';
export const GET_DROPDOWN_REFERRERS_FAILURE =
  'GET_DROPDOWN_REFERRERS_FAILURE';

export const GET_RECENT_INCOMING_MESSAGES_UPDATE_SUCCESS =
  'GET_RECENT_INCOMING_MESSAGES_UPDATE_SUCCESS';

export const EDIT_SAVED_FILTER_RECOMMENDATIONS_SUCCESS = 'EDIT_SAVED_FILTER_RECOMMENDATIONS_SUCCESS'
export const DELETE_SAVED_FILTER_RECOMMENDATIONS_SUCCESS = 'DELETE_SAVED_FILTER_RECOMMENDATIONS_SUCCESS'
export const GET_SAVED_FILTER_RECOMMENDATIONS_SUCCESS = 'GET_SAVED_FILTER_RECOMMENDATIONS_SUCCESS'
export const CREATE_SAVED_FILTER_RECOMMNEDATIONS_SUCCESS = 'CREATE_SAVED_FILTER_RECOMMNEDATIONS_SUCCESS'

export const SET_ADDITIONAL_SEGMENT_CONDITIONS = 'SET_ADDITIONAL_SEGMENT_CONDITIONS';
export const SET_SEGMENT_CONDITION = 'SET_SEGMENT_CONDITION';
export const SET_TIME_TRIGGER = 'SET_TIME_TRIGGER';
export const RESET_TIME_TRIGGER = 'RESET_TIME_TRIGGER';
export const RESET_SAVED_FILTER_RECOMMENDATIONS_SUCCESS = 'RESET_SAVED_FILTER_RECOMMENDATIONS_SUCCESS';

export const RESET_STATE = scoped('RESET_STATE');


export const GET_KEY_METRICS_REQUEST = 'GET_KEY_METRICS_REQUEST';
export const GET_KEY_METRICS_FAILURE = 'GET_KEY_METRICS_FAILURE';
export const GET_KEY_METRICS_SUCCESS = 'GET_KEY_METRICS_SUCCESS';

export const resetState = () => ({ type: RESET_STATE });

export const showAlert = (type, headline, content, timeout = 3000) => ({
  type: SHOW_ALERT,
  payload: { type, headline, timeout, children: content, active: true },
});

export const hideAlert = () => ({ type: HIDE_ALERT });

export const createRadminUpload =
  (userId, isUserHaveUploads, formsData, onSuccess, onFirstImport) =>
  async (dispatch, getState) => {
    const setProgressBarValue = (percentCompleted) => {
      dispatch({ type: SET_PROGRESS_BAR_VALUE, payload: percentCompleted });
    };
    try {
      dispatch({ type: CREATE_RADMIN_UPLOAD_FETCH });

      const setProgressBarThrottled = throttle(setProgressBarValue, 1000);
      const config = {
        onUploadProgress: function (progressEvent) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgressBarThrottled(percentCompleted);
        },
      };
      const arrOfResp = await Promise.all(
        formsData.map((formData) =>
          API.postWithImageRequest('/api/v1/upload/', formData, config)
        )
      );

      dispatch({ type: CREATE_RADMIN_UPLOAD_RESOLVE, payload: arrOfResp });
      dispatch(showAlert('success', 'Success', 'File successfully uploaded!'));
      if (!isUserHaveUploads) {
        dispatch({ type: SET_IS_HAVE_UPLOADS, payload: true });
        onFirstImport();
      } else if (onSuccess) onSuccess();
    } catch (error) {
      console.error(error);
      const errorData = (error && error.response && error.response.data) || {};
      let errorText =
        errorData.detail ||
        (errorData.upload_file && errorData.upload_file[0]) ||
        'There was an error processing your request';
      dispatch({ type: CREATE_RADMIN_UPLOAD_REJECT });
      dispatch(showAlert('danger', 'Error', errorText));
    } finally {
      setProgressBarValue(null);
    }
  };

// const delay = ms => new Promise(res => setTimeout(res, ms))

export const getRadiologistList =
  (pageNumber, sort,  option , config, date, rangeDate, rangeExam) => async (dispatch, getState) => {
    // const currentPageNumber = getState().radAdmin.radiologist.pageNumber;
    const searchVal = getState().radAdmin.radiologist.searchValue;

    


   
    let formattedDate =  date && moment(date).format('YYYY-MM-DD')
  
    let range_start_gte = option === 'range start' && config === 'gte' && date? `&range_start_gte=${formattedDate}` : '';
    let range_start_lte = option === 'range start' && config === 'lte' && date? `&range_start_lte=${formattedDate}` : '';
    let range_end_gte = option === 'range end' && config === 'gte' && date? `&range_end_gte=${formattedDate}` :  '';
    let range_end_lte = option === 'range end' && config === 'lte' && date? `&range_end_lte=${formattedDate}` : '';
   
    let range = '';

    if (rangeDate?.startDate && rangeDate?.endDate) {
        const formattedStartDate = moment(rangeDate.startDate).format('YYYY-MM-DD');
        const formattedEndDate = moment(rangeDate.endDate).format('YYYY-MM-DD');

        range = `&${option === 'range end' ? 'range_end' : 'range_start'}_gte=${formattedStartDate}&${option === 'range end' ? 'range_end' : 'range_start'}_lte=${formattedEndDate}`;

    }

    let examRange = '';

    if (rangeExam?.startDate && rangeExam?.endDate) {
        const formattedStartDate = moment(rangeExam.startDate).format('YYYY-MM-DD');
        const formattedEndDate = moment(rangeExam.endDate).format('YYYY-MM-DD');

        examRange = `&initial_exam_date_gte=${formattedStartDate}&initial_exam_date_lte=${formattedEndDate}`;

    }
    try {
      dispatch({
        type: GET_RADIOLOGIST_LEADERBOARD_REQUEST,
        payload: pageNumber,
      });
      const {
        data: { results: stats, next },
      } = await API.getRequest(
        `/api/v1/radiologist/stats/?page=${pageNumber}${
          searchVal ? `&search=${searchVal}` : ''
        }&ordering=-num_of_adherent_recos${range_start_gte}${range_start_lte}${range_end_gte}${range_end_lte}${range}${examRange}`
      );
      let items = stats.map((item) => {
        return {
          ...item,
          name: `${capitalize(item.first_name)} ${capitalize(item.last_name)}`,
          initials:
            capitalize(item.first_name.charAt(0)) +
            capitalize(item.last_name.charAt(0)),
          numberOfRecos: item?.num_recos || 0,
          closedRecos: item?.num_of_adherent_recos || 0,
          numberOfDetailedRecos: item?.num_of_quality_recos || 0,
          highQuality: item?.percent_quality_recos || 0,
          num_reports: item?.num_reports || 0,
        };
      });
      const hasMoreItems = next;
      dispatch({
        type: GET_RADIOLOGIST_LEADERBOARD_SUCCESS,
        payload: { items, hasMoreItems, pageNumber },
      });
    } catch (error) {
      console.error(error);
      dispatch(showAlert('danger', 'Error', error.message));
      dispatch({
        type: GET_RADIOLOGIST_LEADERBOARD_REQUEST_FAILURE,
        payload: error.message,
      });
    }
  };

export const getDoctorsListAction =
  (pageNumber, sort, searchQuery = '', option , config, date, rangeDate, rangeExam ) =>
  async (dispatch, getState) => {
    const currentPageNumber = getState().radAdmin.patients.pageNumber;
    const searchVal = getState().radAdmin.doctors.searchValue || searchQuery;
    if (currentPageNumber && currentPageNumber !== pageNumber) {
      const direction = currentPageNumber < pageNumber ? 'Next' : 'Back';
      window.analytics.track(`Ref-Doctors-Pagination-${direction}-Click`);
    }
    let sortVal = '';
    switch (sort) {
      case 'Reco Overdue Asc':
        sortVal = 'num_of_overdue_recos';
        break;
      case 'Reco Coming Due Desc':
        sortVal = '-num_of_coming_due_recos';
        break;
      case 'Reco Overdue Desc':
        sortVal = '-num_of_overdue_recos';
        break;
      case 'Reco Coming Due Asc':
        sortVal = 'num_of_coming_due_recos';
        break;
      default:
        sortVal = '';
        break;
    }
    try {
      dispatch({ type: GET_DOCTORS_LIST_REQUEST, payload: pageNumber });


      let formattedDate =  date && moment(date).format('YYYY-MM-DD')
  
      let range_start_gte = option === 'range start' && config === 'gte' && date? `&range_start_gte=${formattedDate}` : '';
      let range_start_lte = option === 'range start' && config === 'lte' && date? `&range_start_lte=${formattedDate}` : '';
      let range_end_gte = option === 'range end' && config === 'gte' && date? `&range_end_gte=${formattedDate}` :  '';
      let range_end_lte = option === 'range end' && config === 'lte' && date? `&range_end_lte=${formattedDate}` : '';
     
      let range = '';

      if (rangeDate?.startDate && rangeDate?.endDate) {
          const formattedStartDate = moment(rangeDate.startDate).format('YYYY-MM-DD');
          const formattedEndDate = moment(rangeDate.endDate).format('YYYY-MM-DD');

          range = `&${option === 'range end' ? 'range_end' : 'range_start'}_gte=${formattedStartDate}&${option === 'range end' ? 'range_end' : 'range_start'}_lte=${formattedEndDate}`;

      }

      let examRange = '';

      if (rangeExam?.startDate && rangeExam?.endDate) {
          const formattedStartDate = moment(rangeExam.startDate).format('YYYY-MM-DD');
          const formattedEndDate = moment(rangeExam.endDate).format('YYYY-MM-DD');

          examRange = `&initial_exam_date_gte=${formattedStartDate}&initial_exam_date_lte=${formattedEndDate}`;

      }



      let {
        data: { results: physicians, next }
      } = await API.getRequest(
        `/api/v1/physician/stats/?page=${pageNumber}${
          searchVal ? `&search=${searchVal}` : ''
        }&ordering=-num_of_adherent_recos${range_start_gte}${range_start_lte}${range_end_gte}${range_end_lte}${range}${examRange}`
      );

      let items = physicians.map((item) => {
        return {
          ...item,
          referredPatients: item.num_patients || 0,
          imagingRecos: item.num_reports || 0,
          overduePatients: item.num_of_overdue_recos || 0,
          comingDuePatients: item.num_of_coming_due_recos || 0,
          adherentPatients: item.num_of_adherent_recos || 0,
          name: `${capitalize(item.first_name)} ${capitalize(item.last_name)}`,
          initials:
            capitalize(item.first_name.charAt(0)) +
            capitalize(item.last_name.charAt(0)),
        };
      });

      dispatch({
        type: GET_DOCTORS_LIST_SUCCESS,
        payload: { items, hasMoreItems:next, pageNumber },
      });
    } catch (error) {
      console.error(error);
      dispatch(showAlert('danger', 'Error', error.message));
      dispatch({ type: GET_DOCTORS_LIST_FAILURE, payload: error.message });
    }
  };

  export const getDoctorsListDropdownAction =
  (pageNumber, sort, searchQuery = '') =>
  async (dispatch, getState) => {
    const currentPageNumber = getState().radAdmin.patients.pageNumber;
    const searchVal = getState().radAdmin.doctors.searchValue || searchQuery;
    if (currentPageNumber && currentPageNumber !== pageNumber) {
      const direction = currentPageNumber < pageNumber ? 'Next' : 'Back';
      window.analytics.track(`Ref-Doctors-Pagination-${direction}-Click`);
    }
    let sortVal = '';
    switch (sort) {
      case 'Reco Overdue Asc':
        sortVal = 'num_of_overdue_recos';
        break;
      case 'Reco Coming Due Desc':
        sortVal = '-num_of_coming_due_recos';
        break;
      case 'Reco Overdue Desc':
        sortVal = '-num_of_overdue_recos';
        break;
      case 'Reco Coming Due Asc':
        sortVal = 'num_of_coming_due_recos';
        break;
      default:
        sortVal = '';
        break;
    }
    try {
      dispatch({ type: GET_DOCTORS_LIST_DROPDOWN_REQUEST, payload: pageNumber });

      let {
        data: { results: physicians, next }
      } = await API.getRequest(
        `/api/v1/physician/stats/?page=${pageNumber}${
          searchVal ? `&search=${searchVal}` : ''
        }&ordering=-num_of_adherent_recos`
      );

      let items = physicians.map((item) => {
        return {
          ...item,
          referredPatients: item.num_patients || 0,
          imagingRecos: item.num_reports || 0,
          overduePatients: item.num_of_overdue_recos || 0,
          comingDuePatients: item.num_of_coming_due_recos || 0,
          adherentPatients: item.num_of_adherent_recos || 0,
          name: `${capitalize(item.first_name)} ${capitalize(item.last_name)}`,
          initials:
            capitalize(item.first_name.charAt(0)) +
            capitalize(item.last_name.charAt(0)),
        };
      });

      dispatch({
        type: GET_DOCTORS_LIST_DROPDOWN_SUCCESS,
        payload: { items, hasMoreItems:next, pageNumber },
      });
    } catch (error) {
      console.error(error);
      dispatch(showAlert('danger', 'Error', error.message));
      dispatch({ type: GET_DOCTORS_LIST_DROPDOWN_FAILURE, payload: error.message });
    }
  };

export const setDoctorForPatientsAction = (doctor) => async (dispatch) => {
  try {
    dispatch({ type: SET_DOCTOR_FOR_PATIENTS, payload: doctor });
  } catch (error) {
    console.error(error);
  }
};

export const getDoctorAction = (doctorId) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_DOCTOR_REQUEST });

    const { data } = await API.getRequest(`/api/v1/physician/${doctorId}/`);

    dispatch({ type: GET_DOCTOR_SUCCESS, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: GET_DOCTOR_FAILURE, payload: error.message });
  }
};

export const getPatientsListAction =
  (pageNumber, doctorId, provider, rec) => async (dispatch, getState) => {
    const currentPageNumber = getState().radAdmin.patients.pageNumber;
    if (currentPageNumber && currentPageNumber !== pageNumber) {
      const direction = currentPageNumber < pageNumber ? 'Next' : 'Back';
      window.analytics.track(`Patient-List-Pagination-${direction}-Click`);
    }
    try {
      dispatch({
        type: GET_PATIENTS_LIST_REQUEST,
        payload: { pageNumber, provider },
      });
      let followUpRec = '';
      if (rec) {
        followUpRec = `follow_up_recommended=${rec === 'imgReco'}&`;
      }

      const { data } = await API.getRequest(
        `/api/v1/report2/details/?${followUpRec}page=${pageNumber}&referring_physician=${doctorId}`
      );
      const res = await Promise.all(
        data.results.map(({ patient }) =>
          API.getRequest(`/api/v1/report2/?patient=${patient.id}`)
        )
      );
      const physiciansIds = res
        .reduce((total, item) => total.concat(item.data.results), [])
        .map(({ referring_physician }) => referring_physician)
        .filter((itm, idx, arr) => arr.indexOf(itm) === idx);
      const physiciansRes = await Promise.all(
        physiciansIds.map((id) => API.getRequest(`/api/v1/physician/${id}/`))
      );
      const physiciansNames = physiciansRes
        .reduce((total, item) => total.concat(item.data), [])
        .map(({ id, first_name, last_name }) => ({
          id,
          physicianName: `${first_name} ${last_name}`,
        }));

      let detailsRes;
      if (data.results.length > 0) {
        detailsRes = await API.getRequest(
          `/api/v1/recommendation/?user_rejected=${false}`
        );
      }
      const totalItems = data.count;
      let items = data.results.map(
        ({
          patient,
          accession_number,
          modality,
          initial_exam_date,
          follow_up_recommended,
          status,
          status_override,
          id,
          report_text,
        }) => ({
          patient,
          name: '',
          accessionNumber: accession_number,
          lastScanType: modality,
          lastScanDate: initial_exam_date,
          follow_up_recommended,
          reportText: report_text,
          status: status,
          status_override: status_override,
          reportDetails: detailsRes.data.results.find(
            (item) => item.report === id
          ),
          patientName: `${patient.first_name} ${patient.last_name}`,
          reports: res.find(
            ({ data }) => data.results[0].patient === patient.id
          )?.data,
        })
      );
      const itemsWithReports = items.map((info) => {
        return {
          ...info,
          reports: {
            ...info.reports,
            results: info.reports.results.map((report) => ({
              ...report,
              initial_exam_date: moment(report.initial_exam_date).format(
                'DD.MM.YYYY'
              ),
              physicianName: physiciansNames.find(
                (item) => item.id === report.referring_physician
              ).physicianName,
            })),
          },
        };
      });
      dispatch({
        type: GET_PATIENTS_LIST_SUCCESS,
        payload: {
          items: itemsWithReports,
          totalItems,
          pageNumber: currentPageNumber + 1 || 1,
        },
      });
    } catch (error) {
      console.error(error);
      dispatch(showAlert('danger', 'Error', error.message));
      dispatch({ type: GET_PATIENTS_LIST_FAILURE, payload: error.message });
    }
  };

export const getPatientsListViewAction =
  (pageNumber, searchValue) => async (dispatch) => {
    try {
      dispatch({ type: GET_PATIENT_LIST_VIEW_REQUEST });
      const { data } = await API.getRequest(
        `/api/v1/patient/stats/?page=${pageNumber}${
          searchValue ? `&search=${searchValue}` : ''
        }`
      );
      // const reportsCounts = await Promise.all(
      //   data.results.map(({ id }) =>
      //     API.getRequest(`/api/v1/report2/?patient=${id}`)
      //   )
      // );
      // const recommendationsCounts = await Promise.all(
      //   data.results.map(({ id }) =>
      //     API.getRequest(
      //       `/api/v1/recommendation/aggregate/?agg_func=count&report__patient=${id}`
      //     )
      //   )
      // );
      const patients = data.results;
      let patientsData = [];
      for (let i = 0; i < patients.length; i++) {
        // const reportsCount = reportsCounts[i].data?.count;
        // const recommendationsCount = recommendationsCounts[i].data.id__count;
        const patientName = `${patients[i].first_name} ${patients[i].last_name}`;
        patientsData[i] = {
          ...patients[i],
          // reportsCount,
          // recommendationsCount,
          patientName,
        };
      }
      const hasMoreItems = data.next;
      dispatch({
        type: GET_PATIENT_LIST_VIEW_SUCCESS,
        payload: { items: patientsData, hasMoreItems, pageNumber },
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_PATIENT_LIST_VIEW_FAILURE, payload: error.message });
    }
  };

export const getMorePatientReports =
  (patientId) => async (dispatch, getState) => {
    const nextUrl = getState().radAdmin.patients.data.find(
      ({ patient }) => patient.id === patientId
    ).reports.next;
    if (!nextUrl) {
      return;
    }
    const nextUrlPart = nextUrl.slice(nextUrl.indexOf('/api/v1'));
    try {
      dispatch({ type: GET_PATIENT_REPORTS_REQUEST });

      const { data } = await API.getRequest(`${nextUrlPart}`);
      const { results } = data;
      const physiciansIds = data.results
        .map(({ referring_physician }) => referring_physician)
        .filter((itm, idx, arr) => arr.indexOf(itm) === idx);
      const physiciansRes = await Promise.all(
        physiciansIds.map((id) => API.getRequest(`/api/v1/physician/${id}/`))
      );
      const physiciansNames = physiciansRes
        .reduce((total, item) => total.concat(item.data), [])
        .map(({ id, first_name, last_name }) => ({
          id,
          physicianName: `${first_name} ${last_name}`,
        }));
      const reports = {
        ...data,
        results: results.map((item) => ({
          ...item,
          initial_exam_date: moment(item.initial_exam_date).format(
            'DD.MM.YYYY'
          ),
          physicianName: physiciansNames.find(
            (itm) => itm.id === item.referring_physician
          ).physicianName,
        })),
      };

      dispatch({ type: GET_PATIENT_REPORTS_SUCCESS, payload: reports });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_PATIENT_REPORTS_FAILURE, payload: error.message });
    }
  };

export const resetPatientsDataAction = () => async (dispatch) => {
  try {
    dispatch({ type: RESET_PATIENTS_DATA });
  } catch (error) {
    console.error(error);
  }
};

export const getPreviousUploadsListAction =
  (pageNumber) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_PREVIOUS_UPLOADS_LIST_REQUEST,
        payload: pageNumber,
      });

      const sortById = (a, b) => (a.id < b.id ? 1 : a.id > b.id ? -1 : 0);

      const itemsOnPage = 10;
      let itemsCount = getState().radAdmin.previousUploads.totalItems;
      let itemsDifference = itemsCount && itemsCount % itemsOnPage;
      let nextData, items, totalItems;
      if (!itemsCount) {
        const itemsCountRes = await API.getRequest(`/api/v1/upload/`);
        itemsCount = itemsCountRes.data?.count;
        itemsDifference = itemsCount % itemsOnPage;
      }
      if (!itemsCount) {
        dispatch({
          type: GET_PREVIOUS_UPLOADS_LIST_SUCCESS,
          payload: { items: [], totalItems: null },
        });
      }
      const pageNum = Math.ceil(itemsCount / itemsOnPage) - pageNumber;
      const { data } = await API.getRequest(
        `/api/v1/upload/?page=${pageNum + 1}`
      );
      if (itemsDifference && pageNum) {
        nextData = await API.getRequest(`/api/v1/upload/?page=${pageNum}`);
        items = data.results
          .slice(0, itemsDifference)
          .concat(nextData.data.results.slice(itemsDifference, itemsOnPage));
      } else if (itemsDifference) {
        items = data.results.slice(0, itemsDifference);
      } else {
        items = data.results;
      }
      items.sort(sortById);
      totalItems = data.count;

      dispatch({
        type: GET_PREVIOUS_UPLOADS_LIST_SUCCESS,
        payload: { items, totalItems },
      });
    } catch (error) {
      console.error(error);
      dispatch(showAlert('danger', 'Error', error.message));
      dispatch({
        type: GET_PREVIOUS_UPLOADS_LIST_FAILURE,
        payload: error.message,
      });
    }
  };

export const getFollowUpListAction =
  (pageNumber, status = 'Coming Due', searchVal, filters, dates, date, config, option, rangeDate) =>
  async (dispatch, getState) => {
    let sortVal = '';
    switch (filters?.sort) {
      case 'Reco Max Asc':
        sortVal = 'range_end';
        break;
      case 'Reco Max Desc':
        sortVal = '-range_end';
        break;
      case 'Reco Min Asc':
        sortVal = 'range_start';
        break;
      case 'Reco Min Desc':
        sortVal = '-range_start';
        break;
        case 'Reco Exam Asc':
          sortVal = 'report__initial_exam_date';
          break;
        case 'Reco Exam Desc':
          sortVal = '-report__initial_exam_date';
          break;


    }

    if(pageNumber ===1){
      dispatch({
        type: GET_FOLLOW_UP_LIST_SUCCESS,
        payload: { items: [], totalItems: 0, next: null, pageNumber: 1 },
      });
    }
    
    
    const prepareStatusForReq = (status) =>
      `status=${status.trim().replace(' ', '_').toLowerCase()}&`;
    try {
      dispatch({ type: GET_FOLLOW_UP_LIST_REQUEST });

      if (status === 'empty') {
        dispatch({
          type: GET_FOLLOW_UP_LIST_SUCCESS,
          payload: { items: [], totalItems: 0, next: null, pageNumber: 1 },
        });
        return;
      }
      let followUpStatus = '';
      if (status) {
        followUpStatus =
          typeof status === 'string'
            ? prepareStatusForReq(status)
            : status
                .filter((item) => item)
                .map(({ status }) => prepareStatusForReq(status));
        // status.filter((item) => pageNumber > 100 ? item.id__count > (pageNumber - 1) * 10 : item).map(({ status }) => prepareStatusForReq(status));
      }
      // await dispatch(getRecoConfig());
      const ageFilter = filters['age'].length>0
      ? filters.age.map(itm=>`&patient_age_group=${itm.value}`).join('')
      : '';
      const followUpDoc = filters['radiologist'].length>0
        ? filters.radiologist.map(itm=> `&report__radiologist__id=${itm.value}`).join('')
        : '';
      const followUpRefDoc = filters['referrers'].length>0
        ? filters.referrers.map(itm=>`&report__referring_physician=${itm.value}`).join('') 
        : '';
      const followUpModality = filters.modality.length>0? filters.modality.map(itm=>modalityFilter(
        itm,
        filters?.modalityOperator
      )).join('') :''
      const followUpAnatomy = filters.anatomy.length>0? filters.anatomy.map(itm => anatomyFilter(
        itm,
        filters?.anatomyOperator
      )).join(''):''
      const followUpTimeframeStatus = filters['timeframe'].length>0
        ? filters.timeframe.map(itm=>`&timeframe_status_extracted=${itm.value}`).join('')
        : '';         
      // const followUpAgeOptions = filters['age'].length>0
      //   ? `&report__patient__date_of_birth__gte=${startDate}&report__patient__date_of_birth__lte=${endDate}`
      //   : '';
      const conditional = getState().radAdmin.recoConfig.data?.conditional;
      const negated = getState().radAdmin.recoConfig.data?.negated;


      let examCodeQuery = ''
      if(filters?.examCodeOperator?.value === 'isNotEqual'){
         examCodeQuery = `&exam_code_ne`
      }else{
       examCodeQuery = `&exam_code`
      }

      const examCode = filters['exam_code'].length>0
      ? filters['exam_code'].map(itm=>`${examCodeQuery}=${itm.value}`).join('') 
      : '';
      
      const initialExamDateFilter =
        dates?.startDate && dates?.endDate
          ? `&initial_exam_date_gte=${moment(dates.startDate).format('YYYY-MM-DD')}&initial_exam_date_lte=${moment(dates.endDate).format('YYYY-MM-DD')}`
          : '';
      const notesFilter = filters['has_note'].length>0
        ? `&has_note=${filters['has_note'][0].value}`
        : '';
        const examTypeFilter = filters['exam_type'].length>0
        ? `&exam_type=${filters['exam_type'][0].value}`
        : '';

        const recommendationTypeFilter = filters['recommendation_type'].length>0
        ? `&recommendation_type=${filters['recommendation_type'][0].value}`
        : '';

        const contrastFilter = filters['contrast'].length>0
        ? `&contrast=${filters['contrast'][0].value}`
        : '';

      const messageSentFilter = filters['message_sent'].length>0
        ? `&message_sent=${filters['message_sent'][0].value}`
        : '';
        const locationFilter = filters['location'].length>0
        ? filters.location.map(itm=>`&location_address=${itm.value}`).join('')
        : '';
        const cohortFilter = filters['cohort'].length>0
        ? filters.cohort.map(itm=>`&cohort=${itm.value}`).join('')
        : '';

        const guidelineFilter = filters['guideline_type'].length>0
        ? filters['guideline_type'].map(itm=>`&guideline_type=${itm.value}`).join('')
        : '';

        const taskFilter = filters['task_type'].length>0
        ? filters['task_type'].map(itm=>`&note_task_type=${itm.value}`).join('')
        : '';

        let reportModalityQuery = ''
         if(filters?.reportModalityOperator?.value === 'isNotEqual'){
            reportModalityQuery = `&report_modality_ne`
         }else{
          reportModalityQuery = `&report__modality`
         }

        const reportModality = filters['report_modality'].length>0 ? filters['report_modality'].map(itm=>`${reportModalityQuery}=${itm.value}`).join('') 
        : '';

        const languageFilter = filters['patient_language'].length>0
        ? `&patient_language=${filters['patient_language'][0].value}`
        : '';


      let formattedDate =  date && moment(date).format('YYYY-MM-DD')
  
      let range_start_gte = option === 'range start' && config === 'gte' && date? `&range_start_gte=${formattedDate}` : '';
      let range_start_lte = option === 'range start' && config === 'lte' && date? `&range_start_lte=${formattedDate}` : '';
      let range_end_gte = option === 'range end' && config === 'gte' && date? `&range_end_gte=${formattedDate}` :  '';
      let range_end_lte = option === 'range end' && config === 'lte' && date? `&range_end_lte=${formattedDate}` : '';
     

      // let range =  rangeDate?.startDate && rangeDate?.endDate
      // ? `&range_end_gte=${moment(rangeDate.startDate).format('YYYY-MM-DD')}&range_end_lte=${moment(rangeDate.endDate).format('YYYY-MM-DD')}`
      // : '';
      let range = '';

      if (rangeDate?.startDate && rangeDate?.endDate) {
          const formattedStartDate = moment(rangeDate.startDate).format('YYYY-MM-DD');
          const formattedEndDate = moment(rangeDate.endDate).format('YYYY-MM-DD');

          range = `&${option === 'range end' ? 'range_end' : 'range_start'}_gte=${formattedStartDate}&${option === 'range end' ? 'range_end' : 'range_start'}_lte=${formattedEndDate}`;

      }

      let respData;
      let totalItems;
      if (typeof followUpStatus === 'string') {
        const { data } = await API.getRequest(
          `/api/v1/recommendation/details/?${
            !conditional ? `&conditional=${conditional}` : ''
          }${
            !negated ? `&negated=${negated}` : ''
          }&${followUpStatus}page=${pageNumber}${
            searchVal ? `&search=${searchVal}` : ''
          }&user_rejected=${false}&ordering=${sortVal}${followUpDoc}${followUpRefDoc}${followUpModality}${followUpAnatomy}${followUpTimeframeStatus}${initialExamDateFilter}${notesFilter}${messageSentFilter}${locationFilter}${range_start_gte}${range_start_lte}${range_end_gte}${range_end_lte}${range}${reportModality}${cohortFilter}${examCode}${guidelineFilter}${taskFilter}${languageFilter}${examTypeFilter}${recommendationTypeFilter}${contrastFilter}${ageFilter}`
        );
        respData = data.results;
        totalItems = data.count;
      } else {
        const res = await Promise.all(
          followUpStatus.map((status) =>
            API.getRequest(
              `/api/v1/recommendation/details/?${
                !conditional ? `&conditional=${conditional}` : ''
              }${
                !negated ? `&negated=${negated}` : ''
              }&${status}page=${pageNumber}${
                searchVal ? `&search=${searchVal}` : ''
              }&user_rejected=${false}&ordering=${sortVal}${followUpRefDoc}${followUpDoc}${followUpModality}${followUpAnatomy}${followUpTimeframeStatus}${initialExamDateFilter}${notesFilter}${messageSentFilter}${locationFilter}${range_start_gte}${range_start_lte}${range_end_gte}${range_end_lte}${range}${reportModality}${cohortFilter}${examCode}${guidelineFilter}${taskFilter}${languageFilter}${examTypeFilter}${recommendationTypeFilter}${contrastFilter}${ageFilter}`
            )
          )
        );
        respData = res.reduce(
          (total, item) => total.concat(item.data.results),
          []
        );
        totalItems = res.reduce((sum, item) => sum + item.data.count, 0);
      }
      let notesResponse = await Promise.all(
        respData.map(({ report }) =>
          API.getRequest(`/api/v1/note?patient=${report.patient.id}&ordering=-created`)
        )
      );

      let boiResponse = await Promise.all(
        respData.map(({ report }) =>
          API.getRequest(`/api/v1/findings-boi/?report=${report.id}`)
        )
      );

      const items = respData.map(
        ({
          id,
          status,
          range_start,
          range_end,
          report,
          modality,
          anatomy,
          user_rejected,
          relevant_blob,
          report__modality,
          report_initial_exam_date,
          conditional,
          timeframe_status_extracted,
          nlp_modality,
          implied_modality,
          guideline_type,
          exam_type,
          recommendation_type,
          contrast
        },idx) => ({
          id,
          status,
          rangeStart: range_start,
          rangeEnd: range_end,
          user_rejected,
          havingNotes: notesResponse.find(
            (note) =>
              !isEmpty(note.data.results) &&
              note.data.results[0].patient === report.patient.id
          )
            ? true
            : false,
          accessionNumber: report.accession_number,
          modality : modality || nlp_modality || implied_modality,
          initialExamDate: moment(report.initial_exam_date).format(
            'YYYY-MM-DD'
          ),
          anatomy,
          patientId: report.patient.id,
          patient: report.patient,
          radiologist: report.radiologist,
          referring_physician: report.referring_physician,
          patientMRN: report.patient_mrn || report.patient.mrn,
          reportText: report.report_text,
          report__modality: report.modality,
          relevantBlob: relevant_blob,
          conditional: conditional,
          timeframe_status_extracted: timeframe_status_extracted,
          notesData: notesResponse[idx].data.results,
          boi: !isEmpty( boiResponse[idx].data.results)
            ? true
            : false,
          examCode:  report.exam_code ,
          guideline_type: guideline_type,
          examType: exam_type,
          recommendationType: recommendation_type,
          contrast:contrast
        })
      );
      dispatch({
        type: GET_FOLLOW_UP_LIST_SUCCESS,
        payload: {
          items,
          totalItems,
          pageNumber,
          doc: filters['radiologist'].value,
        },
      });
    } catch (error) {
      console.error(error);
      dispatch(showAlert('danger', 'Error', error.message));
      dispatch({ type: GET_FOLLOW_UP_LIST_FAILURE, payload: error.message });
    }
  };

export const updateFollowupList = (id, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_FOLLOW_UP_LIST_REQUEST });
    let response = await API.putRequest(`/api/v1/recommendation/${id}/`, data);
    let followupstListData = getState().radAdmin.followUpList.data;
    let requiredIndex = followupstListData.findIndex(
      (x) => x.id === response.data.id
    );
    let { user_modality, user_anatomy, user_range_start, user_range_end,conditional } =
      response.data;
    let requiredObject = followupstListData[requiredIndex];
    requiredObject = {
      ...requiredObject,
      modality: user_modality,
      anatomy: user_anatomy,
      rangeStart: user_range_start,
      rangeEnd: user_range_end,
      conditional: conditional
    };
    followupstListData[requiredIndex] = requiredObject;
    dispatch({
      type: UPDATE_FOLLOW_UP_LIST_SUCCESS,
      payload: followupstListData,
    });
    dispatch(showAlert('success', 'Success', 'Details updated successfully!'));
  } catch (error) {
    console.error(error);
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: UPDATE_FOLLOW_UP_LIST_FAILURE, payload: error.message });
  }
};

export const updateFollowupListOnSaveReco = (id, data, recId) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_FOLLOW_UP_LIST_REQUEST });
    let response = await API.patchRequest(`/api/v1/patient/${id}/`, data);
    let followupstListData = getState().radAdmin.followUpList.data;
    let requiredIndex = followupstListData.findIndex(
      (x) => x.id === recId
    );
   
    let requiredObject = followupstListData[requiredIndex];
    requiredObject = {
      ...requiredObject,
      patient:{
        ...requiredObject.patient,
        email: data.email,
        phone_number: data.phone_number,
      }
    };
    followupstListData[requiredIndex] = requiredObject;
    dispatch({
      type: UPDATE_FOLLOW_UP_LIST_SUCCESS,
      payload: followupstListData,
    });
    dispatch(showAlert('success', 'Success', 'Details updated successfully!'));
    return requiredObject

  } catch (error) {
    console.error(error);
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: UPDATE_FOLLOW_UP_LIST_FAILURE, payload: error.message });
  }
};

export const updateFollowupListPhysicianOnSaveReco = (id, data, recId) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_FOLLOW_UP_LIST_REQUEST });
    let response = await API.patchRequest(`/api/v1/physician/${id}/`, data);
    let followupstListData = getState().radAdmin.followUpList.data;
    let requiredIndex = followupstListData.findIndex(
      (x) => x.id === recId
    );
   
    let requiredObject = followupstListData[requiredIndex];
    requiredObject = {
      ...requiredObject,
      referring_physician:{
        ...requiredObject.referring_physician,
        email: data.email,
        phone_number: data.phone_number,
        fax: data.fax
      }
    };
    followupstListData[requiredIndex] = requiredObject;
    dispatch({
      type: UPDATE_FOLLOW_UP_LIST_SUCCESS,
      payload: followupstListData,
    });
    dispatch(showAlert('success', 'Success', 'Details updated successfully!'));
    return requiredObject
  } catch (error) {
    console.error(error);
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: UPDATE_FOLLOW_UP_LIST_FAILURE, payload: error.message });
  }
};


export const updatePatientRecoList = (id, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_RECO_BY_PATIENT_ID_REQUEST });
    let response = await API.putRequest(`/api/v1/recommendation/${id}/`, data);
    const patientRecosRow = getState().radAdmin.patientDetails.recommendations?.data
    let requiredIndex = patientRecosRow.findIndex(
      (x) => x.id === id
    );
    let { user_modality, user_anatomy, user_range_start, user_range_end,conditional } =
      response.data;
    let requiredObject = patientRecosRow[requiredIndex];
    requiredObject = {
      ...requiredObject,
      modality: user_modality,
      anatomy: user_anatomy,
      rangeStart: user_range_start,
      rangeEnd: user_range_end,
      conditional: conditional
    };
    patientRecosRow[requiredIndex] = requiredObject;
    dispatch({
      type: UPDATE_RECO_BY_PATIENT_ID_SUCCESS,
      payload: patientRecosRow,
    });
    dispatch(showAlert('success', 'Success', 'Details updated successfully!'));
  } catch (error) {
    console.error(error);
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: UPDATE_RECO_BY_PATIENT_ID_FAILURE, payload: error.message });
  }
};


export const updatePatientOnSaveReco = (id, data, recId) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_RECO_BY_PATIENT_ID_REQUEST });
    dispatch({ type: UPDATE_PATIENT_BY_ID_REQUEST });

    let response = await API.patchRequest(`/api/v1/patient/${id}/`, data);
    const patientRecosRow = getState().radAdmin.patientDetails.recommendations?.data
    const patientData =  getState().radAdmin.patientDetails?.data
    let requiredIndex = patientRecosRow.findIndex(
      (x) => x.id === recId
    );
   
    let requiredObject = patientRecosRow[requiredIndex];
    requiredObject = {
      ...requiredObject,
        patient: {
          ...requiredObject.patient,
          email: data.email,
          phone_number: data.phone_number,
        }
    };
    patientRecosRow[requiredIndex] = requiredObject;
    dispatch({
      type: UPDATE_RECO_BY_PATIENT_ID_SUCCESS,
      payload: patientRecosRow,
    });
    dispatch({ type: UPDATE_PATIENT_BY_ID_SUCCESS, payload: {...patientData,  email: data.email,
      phone_number: data.phone_number} });

    dispatch(showAlert('success', 'Success', 'Details updated successfully!'));
    return requiredObject

  } catch (error) {
    console.error(error);
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: UPDATE_RECO_BY_PATIENT_ID_FAILURE, payload: error.message });
    dispatch({ type: UPDATE_PATIENT_BY_ID_FAILURE, payload: error.message });

  }
};
export const updatePatientPhysicianOnSaveReco = (id, data, recId) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_RECO_BY_PATIENT_ID_REQUEST });
    let response = await API.patchRequest(`/api/v1/physician/${id}/`, data);
    const patientRecosRow = getState().radAdmin.patientDetails.recommendations?.data
    let requiredIndex = patientRecosRow.findIndex(
      (x) => x.id === recId
    );
   
    let requiredObject = patientRecosRow[requiredIndex];
    requiredObject = {
      ...requiredObject,
        referring_physician:{
          ...requiredObject.referring_physician,
          email: data.email,
          phone_number: data.phone_number,
          fax: data.fax
        }
    };
    patientRecosRow[requiredIndex] = requiredObject;
    dispatch({
      type: UPDATE_RECO_BY_PATIENT_ID_SUCCESS,
      payload: patientRecosRow,
    });
    dispatch(showAlert('success', 'Success', 'Details updated successfully!'));
    return requiredObject
  } catch (error) {
    console.error(error);
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: UPDATE_RECO_BY_PATIENT_ID_FAILURE, payload: error.message });
  }
};


export const getRecoConfig = () => async (dispatch) => {
  try {
    dispatch({ type: GET_RECO_CONFIG_REQUEST });
    const {
      data: { results },
    } = await API.getRequest(`/api/v1/recommendation_config/`);
    const { data: providerData } = await API.getRequest(
      `/api/v1/provider/${results[0].provider}`
    );
    const responseData = {
      conditional: providerData.see_conditional_recommendation,
      negated: providerData.see_negated_recommendation,
      provider: results[0].provider,
      rangeStart: results[0].default_days_to_maturity,
      rangeEnd: results[0].default_days_to_expiry,
      unequalModRangeStart: results[0].unequal_modality_default_days_to_maturity,
      unequalModRangeEnd: results[0].unequal_modality_default_days_to_expiry,
      nlp_post_impression: providerData.nlp_post_impression,
      id: results[0].id,
      name: providerData.name,
      impliedModality: results[0].implied_modality_imputation,
      excludedExamCodes: results[0].excluded_exam_codes,
      mammogramInEmails:  results[0].mammogram_in_emails,
      attributionValue: results[0].attribution_window_adherence,
      defaultRangeStart: results[0]?.default_initial_exam_date_gte_days,
      defaultRangeEnd: results[0]?.default_initial_exam_date_lte_days,
      showDefaultDate: results[0]?.default_initial_exam_date_enabled,
      showStatusOverride: results[0]?.calculate_reco_status,
      patientAgeInterval:results[0]?.patient_age_interval
    };
    dispatch({ type: GET_RECO_CONFIG_SUCCESS, payload: responseData });
  } catch (error) {
    console.error(error);
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_RECO_CONFIG_FAILURE, payload: error.message });
  }
};

export const updateRecoConfig =
  (id, { rangeStart, rangeEnd, unequalModRangeStart, unequalModRangeEnd, conditional, negated, provider, pointer,impliedModality, examCodes, mammogramInEmail, attributionValue, defaultRangeStart,defaultRangeEnd,showDefaultDate,showStatusOverride,patientAgeInterval }) =>

  async (dispatch) => {
    try {
      dispatch({ type: UPDATE_RECO_CONFIG_REQUEST });
      const recoConfigPayload = {
        default_days_to_maturity: rangeStart,
        default_days_to_expiry: rangeEnd,
        unequal_modality_default_days_to_maturity: unequalModRangeStart,
        unequal_modality_default_days_to_expiry: unequalModRangeEnd,
        implied_modality_imputation: impliedModality,
        excluded_exam_codes: examCodes.split(',').map(itm=>itm.trim()).filter(Boolean),
        mammogram_in_emails: mammogramInEmail,
        attribution_window_adherence: attributionValue,
        default_initial_exam_date_gte_days: defaultRangeEnd,
        default_initial_exam_date_lte_days: defaultRangeStart,
        default_initial_exam_date_enabled: showDefaultDate,
        calculate_reco_status: showStatusOverride,
        patient_age_interval:patientAgeInterval
      };
      const providerPayload = {
        see_conditional_recommendation: conditional,
        see_negated_recommendation: negated,
        nlp_post_impression: pointer,
      };
      const { data } = await API.patchRequest(
        `/api/v1/recommendation_config/${id}/`,
        recoConfigPayload
      );
      const { data: providerData } = await API.patchRequest(
        `/api/v1/provider/${provider}/`,
        providerPayload
      );
      const payload = {
        conditional: providerData.see_conditional_recommendation,
        negated: providerData.see_negated_recommendation,
        rangeStart: data.default_days_to_maturity,
        rangeEnd: data.default_days_to_expiry,
        nlp_post_impression: providerData.nlp_post_impression,
        unequalModRangeStart: data.unequal_modality_default_days_to_maturity,
        unequalModRangeEnd: data.unequal_modality_default_days_to_expiry,
        provider,
        id,
        impliedModality: data.implied_modality_imputation,
        excludedExamCodes: data.excluded_exam_codes,
        mammogramInEmails: data.mammogram_in_emails,
        attributionValue: data.attribution_window_adherence,
        defaultRangeStart: data?.default_initial_exam_date_lte_days,
        defaultRangeEnd: data?.default_initial_exam_date_gte_days,
        showDefaultDate: data?.default_initial_exam_date_enabled,
        showStatusOverride: data?.calculate_reco_status,
        patientAgeInterval: data?.patient_age_interval
        };
        
      dispatch({ type: UPDATE_RECO_CONFIG_SUCCESS, payload });
      dispatch(
        showAlert(
          'success',
          'Success',
          'Recommendation configuration updated successfully!'
        )
      );
      return { success: true };
    } catch (error) {
      console.error(error);
      dispatch(showAlert('danger', 'Error', error.message));
      dispatch({ type: UPDATE_RECO_CONFIG_FAILURE, payload: error.message });
    }
  };

export const deleteFollowupList =
  (idx, filters) => async (dispatch, getState) => {
    try {
      await API.putRequest(`/api/v1/recommendation/${idx}/`, {
        user_rejected: true,
      });
      let followUpList = getState().radAdmin.followUpList.data;
      let requiredIndex = followUpList.findIndex((x) => x.id === idx);
      followUpList.splice(requiredIndex, 1);
      dispatch(getTotalStatusesAction(filters));
      dispatch({ type: DELETE_FOLLOW_UP_LIST_SUCCESS, payload: followUpList });
      dispatch(showAlert('success', 'Success', 'Successfully deleted!'));
      return {
        deleted: true,
      };
    } catch (error) {
      console.error(error, 'error from dete reco');
      dispatch(showAlert('danger', 'Error', error.message));
      dispatch({ type: UPDATE_FOLLOW_UP_LIST_FAILURE, payload: error.message });
      return {
        deleted: false,
      };
    }
  };

export const getTotalStatusesAction =
  (filters, searchValue, dateFilters, date, config, option, rangeDate) => async (dispatch, getState) => {
    try {
      dispatch({ type: GET_TOTAL_STATUSES_REQUEST });

      // await dispatch(getRecoConfig());
      const followUpDoc = filters['radiologist'].length>0
      ? filters.radiologist.map(itm=> `&report__radiologist__id=${itm.value}`).join('')
      : '';
      const followUpRefDoc = filters['referrers'].length>0
        ? filters.referrers.map(itm=>`&report__referring_physician=${itm.value}`).join('') 
        : '';
        const followUpModality = filters.modality.length>0? filters.modality.map(itm=>modalityFilter(
          itm,
          filters?.modalityOperator
        )).join('') :''
        const followUpAnatomy = filters.anatomy.length>0? filters.anatomy.map(itm => anatomyFilter(
          itm,
          filters?.anatomyOperator
        )):''
      const followUpTimeframeStatus = filters['timeframe'].length>0
      ? filters.timeframe.map(itm=>`&timeframe_status_extracted=${itm.value}`).join('')
      : '';  
      const initialExamDateFilter =
        dateFilters?.startDate && dateFilters?.endDate
          ? `&initial_exam_date_gte=${moment(dateFilters.startDate).format('YYYY-MM-DD')}&initial_exam_date_lte=${moment(dateFilters.endDate).format('YYYY-MM-DD')}`
          : '';
      const noteFilter = filters['has_note'].length>0
        ? `&has_note=${filters['has_note'][0].value}`
        : '';
        const examTypeFilter = filters['exam_type'].length>0
        ? `&exam_type=${filters['exam_type'][0].value}`
        : '';

        const recommendationTypeFilter = filters['recommendation_type'].length>0
        ? `&recommendation_type=${filters['recommendation_type'][0].value}`
        : '';

        const contrastFilter = filters['contrast'].length>0
        ? `&contrast=${filters['contrast'][0].value}`
        : '';

        
    const messageSentFilter = filters['message_sent'].length>0
      ? `&message_sent=${filters['message_sent'][0].value}`
      : '';

      const locationFilter = filters['location'].length>0
      ? filters.location.map(itm=>`&location_address=${itm.value}`).join('')
      : '';

      const cohortFilter = filters['cohort'].length>0
      ? filters.cohort.map(itm=>`&cohort=${itm.value}`).join('')
      : '';

      const guidelineFilter = filters['guideline_type'].length>0
      ? filters['guideline_type'].map(itm=>`&guideline_type=${itm.value}`).join('')
      : '';

      const taskFilter = filters['task_type'].length>0
      ? filters['task_type'].map(itm=>`&note_task_type=${itm.value}`).join('')
      : '';

      let reportModalityQuery = ''
      if(filters?.reportModalityOperator?.value === 'isNotEqual'){
         reportModalityQuery = `&report_modality_ne`
      }else{
       reportModalityQuery = `&report__modality`
      }

      let examCodeQuery = ''
      if(filters?.examCodeOperator?.value === 'isNotEqual'){
         examCodeQuery = `&exam_code_ne`
      }else{
       examCodeQuery = `&exam_code`
      }

      const examCode = filters['exam_code'].length>0
      ? filters['exam_code'].map(itm=>`${examCodeQuery}=${itm.value}`).join('') 
      : '';

      const reportModality = filters['report_modality'].length>0 ? filters['report_modality'].map(itm=>`${reportModalityQuery}=${itm.value}`).join('') 
      : '';

      const languageFilter = filters['patient_language'].length>0
      ? `&patient_language=${filters['patient_language'][0].value}`
      : '';

      const ageFilter = filters['age'].length>0
      ? filters.age.map(itm=>`&patient_age_group=${itm.value}`).join('')
      : '';
      // const followUpAgeOptions = filters['age'].length>0
      //   ? `&report__patient__date_of_birth__gte=${startDate}&report__patient__date_of_birth__lte=${endDate}`
      //   : '';
      const conditional = getState().radAdmin.recoConfig.data?.conditional;
      const negated = getState().radAdmin.recoConfig.data?.negated;
      let formattedDate =  date && moment(date).format('YYYY-MM-DD')

      let range_start_gte = option === 'range start' && config === 'gte' && date? `&range_start_gte=${formattedDate}` : '';
      let range_start_lte = option === 'range start' && config === 'lte' && date? `&range_start_lte=${formattedDate}` : '';
      let range_end_gte =option === 'range end' && config === 'gte' && date? `&range_end_gte=${formattedDate}` :  '';
      let range_end_lte = option === 'range end' && config === 'lte' && date? `&range_end_lte=${formattedDate}` : '';
     

      // let range =  rangeDate?.startDate && rangeDate?.endDate
      // ? `&range_end_gte=${moment(rangeDate.startDate).format('YYYY-MM-DD')}&range_end_lte=${moment(rangeDate.endDate).format('YYYY-MM-DD')}`
      // : '';
      let range = '';

      if (rangeDate?.startDate && rangeDate?.endDate) {
          const formattedStartDate = moment(rangeDate.startDate).format('YYYY-MM-DD');
          const formattedEndDate = moment(rangeDate.endDate).format('YYYY-MM-DD');

          range = `&${option === 'range end' ? 'range_end' : 'range_start'}_gte=${formattedStartDate}&${option === 'range end' ? 'range_end' : 'range_start'}_lte=${formattedEndDate}`;
      }


      const { data } = await API.getRequest(
        `/api/v1/recommendation/aggregate/?agg_func=count&user_rejected=${false}${
          searchValue ? `&search=${searchValue}` : ''
        }${!conditional ? `&conditional=${conditional}` : ''}${
          !negated ? `&negated=${negated}` : ''
        }&group_by=status&distinct=true${followUpDoc}${followUpRefDoc}${followUpModality}${followUpAnatomy}${followUpTimeframeStatus}${initialExamDateFilter}${noteFilter}${messageSentFilter}${locationFilter}${range_start_gte}${range_start_lte}${range_end_gte}${range_end_lte}${range}${reportModality}${cohortFilter}${examCode}${guidelineFilter}${taskFilter}${languageFilter}${examTypeFilter}${recommendationTypeFilter}${contrastFilter}${ageFilter}`

      );

      dispatch({ type: GET_TOTAL_STATUSES_SUCCESS, payload: data });
    } catch (error) {
      console.error(error);
      dispatch(showAlert('danger', 'Error', error.message));
      dispatch({ type: GET_TOTAL_STATUSES_FAILURE, payload: error.message });
    }
  };

export const getAdherenceOfDate = (date, filters, frequency, selectedLabel,startDate,endDate, filteredData) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ADHERENCE_OF_DATE_REQUEST
    });
    const mapFilterValues = (category, filter) => {
      return filter.map((item) => `&${category}=${item.value}`).join('');
    };
    const followUpDoc =
      filters['radiologist'] && filters?.radiologist.length
        ? mapFilterValues('radiologist', filters.radiologist)
        : '';
    const followUpModality =
      filters['modality'] && filters?.modality.length
        ? mapFilterValues('modality', filters.modality)
        : '';
    const followUpAnatomy =
      filters['anatomy'] && filters.anatomy?.length
        ? mapFilterValues('anatomy', filters.anatomy)
        : '';
    const followUpTimeframeStatus =
      filters['timeframe'] && filters.timeframe?.length
        ? mapFilterValues('timeframe_status_extracted', filters.timeframe)
        : '';
    let timestampFilter='';

    if (date) {
      date = moment(date).format('YYYY-MM-DD');
      startDate= moment(startDate).format('YYYY-MM-DD');
      endDate= moment(endDate).format('YYYY-MM-DD');

      if(frequency?.value === 'days'){
       timestampFilter = `&timestamp=${date}`
      }
      if(frequency?.value === 'weeks'){
        timestampFilter = `&timestamp__gte=${moment(selectedLabel.split('-')[0]).format('YYYY-MM-DD')}&timestamp__lte=${moment(selectedLabel.split('-')[1]).format('YYYY-MM-DD')}`
      }
      if(frequency?.value === 'months'){
  
        function getMonthDateRange(lowerBound, upperBound, month) {
          const monthYear = moment(month, 'MMM YYYY').format('YYYY-MM');
          const isFullMonth = moment(monthYear).endOf('month').isSame(moment(monthYear, 'YYYY-MM-DD'), 'day');
        
          if (isFullMonth) {
             
              return {
                timestamp__gte: monthYear + '-01',
                timestamp__lte: moment(monthYear).endOf('month').format('YYYY-MM-DD'),
              };
            
          } else {
            const firstDay = moment(monthYear).startOf('month').format('YYYY-MM-DD');
            const lastDay = moment(monthYear).endOf('month').format('YYYY-MM-DD');
        
            if (firstDay >= lowerBound && lastDay <= upperBound) {
              return {
                timestamp__gte: firstDay,
                timestamp__lte: lastDay,
              };
            } else if (firstDay >= lowerBound || lastDay <= upperBound) {
              const adjustedLastDay = lastDay > upperBound ? upperBound : lastDay;
        
              return {
                timestamp__gte: firstDay >= lowerBound ? firstDay : lowerBound,
                timestamp__lte: adjustedLastDay,
              };
            } 
          }
        }
        
        const lowerBound = startDate;
        const upperBound = endDate;
        
        const month = selectedLabel; 
  
        const query = getMonthDateRange(lowerBound, upperBound, month);
      
        timestampFilter =  query?.timestamp__gte && query?.timestamp__lte && month?  `&timestamp__gte=${query?.timestamp__gte || ''}&timestamp__lte=${query?.timestamp__lte || ''}` :'';
      }
      if(frequency?.value === 'years'){
      function getYearDateRange(dateRange, year) {      
        const startDate = moment(dateRange.split(" - ")[0]);
        const endDate = moment(dateRange.split(" - ")[1]);
      
        if (startDate.year() === endDate.year() && startDate.year() === year) {
          return {
            timestampGte: startDate.toDate(),
            timestampLte: endDate.toDate(),
          };
        }
      
        if (year === startDate.year()) {
          return {
            timestampGte: startDate.toDate(),
            timestampLte: moment([startDate.year(), 11, 31]).toDate(),
          };
        } else if (year === endDate.year()) {
          return {
            timestampGte: moment([year, 0, 1]).toDate(),
            timestampLte: endDate.toDate(),
          };
        } return {
          timestampGte: `${year}-01-01` ,
          timestampLte: `${year}-12-31`,
        }
      }
      
      const dateRange1 = `${startDate} - ${endDate}`;
      const year1 = selectedLabel;
      const {timestampGte, timestampLte} = getYearDateRange(dateRange1, year1);   
      timestampFilter = `&timestamp__gte=${moment(timestampGte).format('YYYY-MM-DD')}&timestamp__lte=${moment(timestampLte).format('YYYY-MM-DD')}`
      }
  
    }

    const { data } = await API.getRequest(
      `/api/v1/historical_adherence_v3/aggregate/?agg_func=sum${timestampFilter}&group_by=status&distinct=true&field=count${followUpDoc}${followUpModality}${followUpAnatomy}${followUpTimeframeStatus}`
    );
    const statusCounts = {
      adherent_count: 0,
      coming_due_count: 0,
      expired_count: 0,
      non_mature_count: 0,
      overdue_count: 0,
    };
    data.reduce(function (previousValue, currentValue) {
      statusCounts[currentValue.status + '_count'] = currentValue.count__sum;
      return statusCounts;
    }, statusCounts);
    statusCounts.timestamp = date;
    dispatch({
      type: GET_ADHERENCE_OF_DATE_SUCCESS,
      payload: { data: [statusCounts], totalItems: data.count },
    });
  } catch (error) {
    console.error(error);
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({
      type: GET_ADHERENCE_OF_DATE_FAILURE
    });
  }
};


function findFirstDaysWith1(startDate, endDate) {
  const result = [];

  let currentDate = startDate.clone();
  while (currentDate.isSameOrBefore(endDate)) {
      if (currentDate.date() === 1) {
          result.push(currentDate.clone());
      }
      currentDate.add(1, 'days');
  }

  return result;
}


export const getAdherenceOverRate = (filters, startDateRange,endDateRange, frequency) => async (dispatch) => {
  try {  
    dispatch({
      type: GET_ADHERENCE_OVER_RATE_REQUEST
    });
    const mapFilterValues = (category, filter) => {
      return filter.map((item) => `&${category}=${item.value}`).join('');
    };
    const followUpDoc =
      filters['radiologist'] && filters?.radiologist.length
        ? mapFilterValues('radiologist', filters.radiologist)
        : '';
    const followUpModality =
      filters['modality'] && filters?.modality.length
        ? mapFilterValues('modality', filters.modality)
        : '';
    const followUpAnatomy =
      filters['anatomy'] && filters.anatomy?.length
        ? mapFilterValues('anatomy', filters.anatomy)
        : '';
    const followUpTimeframeStatus =
      filters['timeframe'] && filters.timeframe?.length
        ? mapFilterValues('timeframe_status_extracted', filters.timeframe)
        : '';

        const dates = [];
        const startDate = moment(startDateRange, "YYYY-MM-DD");
        const endDate = moment(endDateRange, "YYYY-MM-DD");

        const firstDays = findFirstDaysWith1(startDate, endDate);
        firstDays.forEach((day) => {
          dates.push(day.format("YYYY-MM-DD"));
        });

        let group_by= ""
        let order_by=''
        if(frequency?.value === 'days'){
          group_by= `status,timestamp`;
          order_by= 'timestamp'

        }
        if(frequency?.value === 'weeks'){
          group_by= `status,timestamp__year,timestamp__month,timestamp__week`;
          order_by= 'timestamp__year,timestamp__month,timestamp__week'
        }
    
        if(frequency?.value === 'months'){
         group_by= `status,timestamp__month,timestamp__year`;
         order_by = 'timestamp__year,timestamp__month'
        }
    
        if(frequency?.value === 'years'){
          group_by= `status,timestamp__year`;
          order_by= 'timestamp__year';

        }
    
    
      const { data } = await API.getRequest(
        `/api/v1/historical_adherence_v3/aggregate/?timestamp__gte=${startDateRange}&timestamp__lte=${endDateRange}&order_by=${order_by}&agg_func=sum&distinct=true&group_by=${group_by}&field=count${followUpDoc}${followUpModality}${followUpAnatomy}${followUpTimeframeStatus}`
      );

      const groupedData = data.reduce((result, item) => {
        function getDateFromWeek(weekNumber, year) {
          const startDate = moment().year(year).isoWeek(weekNumber).isoWeekday(1).format('DD MMM YYYY');
          const endDate = moment().year(year).isoWeek(weekNumber).isoWeekday(7).endOf('day').format('DD MMM YYYY');          

          return `${startDate} - ${endDate}`;
        }

        function getMonthName(month) {
          const months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];

          if (month >= 1 && month <= 12) {
            return months[month - 1];
          } else {
            return "Invalid Month";
          }
        }
        let timestamp;
        if(frequency?.value === 'days'){
         timestamp = item.timestamp;
        }
        if(frequency?.value === 'months'){
          timestamp = getMonthName(item.timestamp__month) + ',' + item.timestamp__year;
         }
         if(frequency?.value === 'weeks'){
          timestamp = getDateFromWeek( item.timestamp__week, item.timestamp__year ) ;
         }
         if(frequency?.value === 'years'){
          timestamp =  item.timestamp__year;
         }
       
    
        if (!result[timestamp]) {
            result[timestamp] = {
                name: timestamp,
                non_mature: 0,
                expired: 0,
                overdue: 0,
                adherent: 0,
                coming_due:0
            };
        }
    
        switch (item.status) {
            case 'non_mature':
                result[timestamp].non_mature += item.count__sum;
                break;
            case 'expired':
                result[timestamp].expired += item.count__sum;
                break;
            case 'overdue':
                result[timestamp].overdue += item.count__sum;
                break;
            case 'coming_due':
                  result[timestamp].coming_due += item.count__sum;
                  break;
            case 'adherent':
                result[timestamp].adherent += item.count__sum;
                break;
        }
    
        return result;
    }, {});
    
    const groupedArray = Object.values(groupedData);

    const graphData = [];
    for (let i = 0; i < groupedArray.length; i++) {
      const currentItem = groupedArray[i];
      const dividend =(currentItem.expired || 0) +(currentItem.adherent || 0) 
      const data = {
        name: currentItem.name,
        adherence:
        dividend > 0 ? ((currentItem.adherent || 0) / dividend) * 100 : 0,
        nonMature: currentItem.non_mature || 0,
        adherent: currentItem.adherent || 0,
        overdue: currentItem.overdue || 0,
        comingDue: currentItem.coming_due || 0,
        expired: currentItem.expired || 0,
      };
      graphData.push(data);
    }

    dispatch({
      type: GET_ADHERENCE_OVER_RATE_SUCCESS,
      payload: { data: graphData, startDate:startDateRange, endDate:endDateRange  },
    });
  } catch (error) {
    console.error(error);
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({
      type: GET_ADHERENCE_OVER_RATE_FAILURE
    });
  }
};

export const getRevenueCharges =
  (filters, startDateRange, endDateRange, type, frequency) => async (dispatch) => {
    try {
      dispatch({
        type: GET_REVENUE_CHARGES_REQUEST
      });
      const mapFilterValues = (category, filter) => {
        return filter.map((item) => `&${category}=${item.value}`).join("");
      };
      const followUpModality =
        filters["modality"] && filters?.modality.length
          ? mapFilterValues("modality", filters.modality)
          : "";
      const followUpAnatomy =
        filters["anatomy"] && filters.anatomy?.length
          ? mapFilterValues("anatomy", filters.anatomy)
          : "";
      const followUpTimeframeStatus =
        filters["timeframe"] && filters.timeframe?.length
          ? mapFilterValues("timeframe_status_extracted", filters.timeframe)
          : "";

      const startDate = moment(startDateRange, "YYYY-MM-DD");
      const endDate = moment(endDateRange, "YYYY-MM-DD");
      let grouping = ''

      const { data } = await API.getRequest(
        `/api/v1/provider/revenue/?avg=true${followUpModality}${followUpAnatomy}${followUpTimeframeStatus}`
      );
      dispatch({
        type: GET_TOTAL_REVENUE_CHARGES_AVERAGE_SUCCESS,
        payload: { data: data },
      });

      function getDateFromWeek(weekNumber, year) {
        const startDate = moment().year(year).isoWeek(weekNumber).isoWeekday(1).format('DD MMM YYYY');
        const endDate = moment().year(year).isoWeek(weekNumber).isoWeekday(7).endOf('day').format('DD MMM YYYY');          

        return `${startDate} - ${endDate}`;
      }

      function getMonthName(month) {
        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];

        if (month >= 1 && month <= 12) {
          return months[month - 1];
        } else {
          return "Invalid Month";
        }
      }
      if(frequency.value === 'days'){
        grouping = `&group_by=day`
      }
      else if(frequency.value === 'weeks'){
        grouping = `&group_by=week`
      }
      else if(frequency.value === 'months'){
        grouping = `&group_by=month`
      }
      else if(frequency.value === 'years'){
        grouping = `&group_by=year`
      }

   let graphData =   await API.getRequest(
        `/api/v1/provider/revenue/?initial_exam_date_gte=${startDate.format(
                  "YYYY-MM-DD"
                )}&initial_exam_date_lte=${endDate.format(
                  "YYYY-MM-DD"
                )}${grouping}${followUpModality}${followUpAnatomy}${followUpTimeframeStatus}`
      );
           dispatch({
          type: GET_REVENUE_CHARGES_SUCCESS,
          payload: {
            data: graphData.data.map(itm=>({...itm, name: frequency.value==='days'? itm.initial_exam_date__date: frequency.value ==='weeks'? getDateFromWeek( itm.initial_exam_date__week, itm.initial_exam_date__year ): frequency.value ==='months'? getMonthName(itm.initial_exam_date__month) + ',' + itm.initial_exam_date__year: itm.initial_exam_date__year })),
            startDate: startDateRange,
            endDate: endDateRange,
          },
        });
    } catch (error) {
      console.error(error);
      dispatch(showAlert("danger", "Error", error.message));
      dispatch({
        type: GET_REVENUE_CHARGES_FAILURE
      });
    }
  };



export const getAdherenceBenchmark = (filters) => async (dispatch) => {
  try {
    const mapFilterValues = (category, filter) => {
      return filter.map((item) => `&${category}=${item.value}`).join('');
    };
    const followUpDoc =
      filters['radiologist'] && filters?.radiologist.length
        ? mapFilterValues('radiologist', filters.radiologist)
        : '';
    const followUpModality =
      filters['modality'] && filters?.modality.length
        ? mapFilterValues('modality', filters.modality)
        : '';
    const followUpAnatomy =
      filters['anatomy'] && filters.anatomy?.length
        ? mapFilterValues('anatomy', filters.anatomy)
        : '';
    const followUpTimeframeStatus =
      filters['timeframe'] && filters.timeframe?.length
        ? mapFilterValues('status', filters.timeframe)
        : '';
   
      const { data } = await API.getRequest(
        `/api/v1/historical_adherence_v3/benchmark/?distinct=false${followUpDoc}${followUpModality}${followUpAnatomy}${followUpTimeframeStatus}`
      );

      return data["avg_benchmark"]
  
  
  } catch (error) {
    console.error(error);
    dispatch(showAlert('danger', 'Error', error.message));
  }
};

export const getRecoHistoryAction =
  (recommendationId, row) => async (dispatch, getState) => {
    try {
      dispatch({ type: GET_RECO_HISTORY_REQUEST });

      const { data } = await API.getRequest(
        `/api/v1/reco_history/?recommendation=${recommendationId}`
      );
      const {data:dataObj} = await API.getRequest(`/api/v1/sms_message/?patient=${row.patient.id}&ordering=-created`)
      const recentMessages = dataObj.results;

      let requiredMessagesArray = [];

      if (!isEmpty(recentMessages) && row.patient) {
        requiredMessagesArray = recentMessages.map((item) => {
          return {
            recommendation: row.id,
            status_override_from: null,
            status_override_to:
              item.direction === 'outbound' ? 'sms_delivered' : 'sms_received',
            changed_at: item.created,
            message: item.message || 'Empty',
          };
        });
      }
      
      dispatch({
        type: GET_RECO_HISTORY_SUCCESS,
        payload: [...data.results, ...requiredMessagesArray],
      });
    } catch (error) {
      console.error(error);
      dispatch(showAlert('danger', 'Error', error.message));
      dispatch({ type: GET_RECO_HISTORY_FAILURE, payload: error.message });
    }
  };

export const getRadiologistsListAction = (status) => async (dispatch) => {
  const followUpStatus = prepareFollowUpStatusReq(status);

  try {
    dispatch({ type: GET_RADIOLOGIST_LIST_REQUEST });

    let respData = [];

    if (typeof followUpStatus === 'string') {
      const { data } = await API.getRequest(
        `/api/v1/recommendation/simple_aggregate/?user_rejected=${false}&agg_func=none&group_by=report__radiologist__first_name,report__radiologist__last_name`
      );
      respData = data;
    } else {
      const res = await Promise.all(
        followUpStatus.map((status) =>
          API.getRequest(
            `/api/v1/recommendation/simple_aggregate/?user_rejected=${false}&agg_func=none&group_by=report__radiologist__first_name,report__radiologist__last_name`
          )
        )
      );
      respData = res.reduce((total, item) => total.concat(item.data), []);
    }

    const doctors = respData
      .map(
        ({
          report__radiologist__first_name,
          report__radiologist__last_name,
        }) => {
          if (
            report__radiologist__first_name &&
            report__radiologist__last_name
          ) {
            return `${report__radiologist__first_name} ${report__radiologist__last_name}`;
          }
        }
      )
      .filter((itm, idx, arr) => arr.indexOf(itm) === idx);

    dispatch({ type: GET_RADIOLOGIST_LIST_SUCCESS, payload: doctors });
  } catch (error) {
    console.error(error);
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_RADIOLOGIST_LIST_FAILURE, payload: error.message });
  }
};

export const getModalitiesListAction = (status) => async (dispatch) => {
  const followUpStatus = prepareFollowUpStatusReq(status);

  try {
    dispatch({ type: GET_MODALITY_LIST_REQUEST });

    let respData = [];
    if (typeof followUpStatus === 'string') {
      const { data } = await API.getRequest(
        `/api/v1/recommendation/simple_aggregate/?user_rejected=${false}&agg_func=none&group_by=modality&sorted=true`
      );
      respData = data;
    } else {
      const res = await Promise.all(
        followUpStatus.map((status) =>
          API.getRequest(
            `/api/v1/recommendation/simple_aggregate/?user_rejected=${false}&agg_func=none&group_by=modality&sorted=true`
          )
        )
      );
      respData = res.reduce((total, item) => total.concat(item.data), []);
    }

    dispatch({
      type: GET_MODALITY_LIST_SUCCESS,
      payload: respData
        .map((item) => item.modality)
        .filter((itm, idx, arr) => arr.indexOf(itm) === idx),
    });
  } catch (error) {
    console.error(error);
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_MODALITY_LIST_FAILURE, payload: error.message });
  }
};

export const getAnatomiesListAction = (status) => async (dispatch) => {
  console.log(status, 'status for anatomies');

  const followUpStatus = prepareFollowUpStatusReq(status);
  try {
    dispatch({ type: GET_ANATOMY_LIST_REQUEST });

    let respData = [];

    if (typeof followUpStatus === 'string') {
      const { data } = await API.getRequest(
        `/api/v1/recommendation/simple_aggregate/?user_rejected=${false}&agg_func=none&group_by=anatomy`
      );
      respData = data;
    } else {
      const res = await Promise.all(
        followUpStatus.map((status) =>
          API.getRequest(
            `/api/v1/recommendation/simple_aggregate/?user_rejected=${false}&agg_func=none&group_by=anatomy`
          )
        )
      );
      respData = res.reduce((total, item) => total.concat(item.data), []);
    }

    dispatch({
      type: GET_ANATOMY_LIST_SUCCESS,
      payload: respData
        .map((item) => item.anatomy)
        .filter((itm, idx, arr) => arr.indexOf(itm) === idx),
    });
  } catch (error) {
    console.error(error);
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_ANATOMY_LIST_FAILURE, payload: error.message });
  }
};

export const getTimeframeStatusesListAction = (status) => async (dispatch) => {
  const followUpStatus = prepareFollowUpStatusReq(status);

  try {
    dispatch({ type: GET_TIMEFRAME_STATUS_LIST_REQUEST });

    let respData = [];

    if (typeof followUpStatus === 'string') {
      const { data } = await API.getRequest(
        `/api/v1/recommendation/simple_aggregate/?user_rejected=${false}&agg_func=none&group_by=timeframe_status_extracted`
      );
      respData = data;
    } else {
      const res = await Promise.all(
        followUpStatus.map((status) =>
          API.getRequest(
            `/api/v1/recommendation/simple_aggregate/?user_rejected=${false}&agg_func=none&group_by=timeframe_status_extracted`
          )
        )
      );
      respData = res.reduce((total, item) => total.concat(item.data), []);
    }

    dispatch({
      type: GET_TIMEFRAME_STATUS_LIST_SUCCESS,
      payload: respData
        .map((item) => item.timeframe_status_extracted)
        .filter((itm, idx, arr) => arr.indexOf(itm) === idx),
    });
  } catch (error) {
    console.error(error);
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({
      type: GET_TIMEFRAME_STATUS_LIST_FAILURE,
      payload: error.message,
    });
  }
};

export const changeActionStatusAction =
  (id, status, patientId) => async (dispatch) => {
    try {
      dispatch({ type: CHANGE_ACTION_STATUS_REQUEST });
      const { data } = await API.putRequest(`/api/v1/recommendation/${id}/`, {
        status_override: status ==='Unresponsive / Comms Exhausted'? 'unresponsive' : status.toLowerCase().replace(/ /gi, '_'),
      });
      if (patientId) {
        dispatch(getFollowUpListFromMessages(patientId));
      } else {
        dispatch({ type: CHANGE_ACTION_STATUS_SUCCESS, payload: data });
      }
      dispatch(showAlert('success', 'Success', 'Status successfully changed!'));
    } catch (error) {
      dispatch(showAlert('danger', 'Error', error.message));
      dispatch({ type: CHANGE_ACTION_STATUS_FAILURE, payload: error.message });
    }
  };

  export const changeActionStatusActionPatientDetails =
  (id, status) => async (dispatch) => {
    try {
      dispatch({ type: CHANGE_ACTION_STATUS_REQUEST });
      await API.putRequest(`/api/v1/recommendation/${id}/`, {
        status_override: status ==='Unresponsive / Comms Exhausted'? 'unresponsive' : status.toLowerCase().replace(/ /gi, '_'),
      });
      dispatch(showAlert('success', 'Success', 'Status successfully changed!'));
    } catch (error) {
      dispatch(showAlert('danger', 'Error', error.message));
      dispatch({ type: CHANGE_ACTION_STATUS_FAILURE, payload: error.message });
    }
  };


export const getImagingAdherenceAction = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_IMAGING_ADHERENCE_REQUEST });
    let {modality, anatomy, radiologist, timeframe} = values


    const { data } = await API.getRequest(
      `/api/v1/report2/aggregate/?${
        modality ? `modality=${modality.value}&` : ''
      }${
        anatomy ? `anatomy=${anatomy.value}&` : ''
      }${
        radiologist ? `radiologist=${radiologist.value}&` : ''
      }${
        timeframe ? `timeframe_status_extracted=${timeframe.value}&` : ''
      }agg_func=count&distinct=false&group_by=follow_up_recommended%2Cinitial_exam_date__month%2Cinitial_exam_date__year`
    );
    if (data?.length) {
      const transformedData = data.map(
        ({
          initial_exam_date__year,
          initial_exam_date__month,
          id__count,
          follow_up_recommended,
        }) => ({
          imagingExamsCount: id__count,
          followUpsMadeCount: follow_up_recommended ? id__count : 0,
          date: moment(
            `${initial_exam_date__month}/01/${initial_exam_date__year}`,
            'M/D/YYYY'
          ).unix(),
        })
      );

      const sortedResponse = sortByAsc(transformedData, 'date');
      let responseObjGroupedByMonth = {};
      sortedResponse.forEach((data) => {
        const momentObject = moment.unix(data.date);
        const key = `${momentObject.month() + 1}.01.${momentObject.year()}`;
        if (responseObjGroupedByMonth[key]) {
          responseObjGroupedByMonth[key].push(data);
        } else {
          responseObjGroupedByMonth[key] = [data];
        }
      });
      let responseArrGroupedByMonth = Object.keys(
        responseObjGroupedByMonth
      ).reduce((acc, month) => {
        const dataArr = responseObjGroupedByMonth[month];
        const totalImagingExamsCount = dataArr.reduce(
          (acc, data) => acc + data.imagingExamsCount,
          0
        );
        const totalFollowUpsMadeCount = dataArr.reduce(
          (acc, data) => acc + data.followUpsMadeCount,
          0
        );
        return [
          ...acc,
          {
            date: month,
            timestamp: Date.parse(moment(month, 'MM/DD/YYYY').toDate()),
            imagingExamsCount: totalImagingExamsCount,
            followUpsMadeCount: totalFollowUpsMadeCount,
          },
        ];
      }, []);
      responseArrGroupedByMonth = sortByAsc(
        responseArrGroupedByMonth,
        'timestamp'
      );

      dispatch({
        type: GET_IMAGING_ADHERENCE_SUCCESS,
        payload: responseArrGroupedByMonth,
      });
    } else {
      dispatch({ type: GET_IMAGING_ADHERENCE_SUCCESS, payload: [] });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: GET_IMAGING_ADHERENCE_FAILURE, payload: error });
  }
};

export const getRecommendationTimeframeAction = (values, startDate, endDate) => async (dispatch) => {
  try {
    dispatch({ type: GET_RECOMMENDATION_TIMEFRAME_REQUEST });
    let {modality, anatomy, radiologist, timeframe} = values
    let dateFilters = startDate && endDate ? `initial_exam_date_gte=${startDate}&initial_exam_date_lte=${endDate}`:""

    const { data } = await API.getRequest(
      `/api/v1/recommendation/distributions/?${
        modality ? `modality=${modality.value}&` : ''
      }${
        anatomy ? `anatomy=${anatomy.value}&` : ''
      }${
        radiologist ? `radiologist_id=${radiologist.value}&` : ''
      }${
        timeframe ? `timeframe_status_extracted=${timeframe.value}&` : ''
      }${dateFilters}`
    );

    dispatch({ type: GET_RECOMMENDATION_TIMEFRAME_SUCCESS, payload: data, startDate, endDate });

  } catch (error) {
    console.error(error);
    dispatch({ type: GET_RECOMMENDATION_TIMEFRAME_FAILURE, payload: error });
  }
};



export const getAdherentCharges = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_TOTAL_REVENUE_CHARGES_REQUEST,
    });   
    const { data } = await API.getRequest(
      `/api/v1/provider/revenue/`
    );
    dispatch({
      type: GET_TOTAL_REVENUE_CHARGES_SUCCESS,
      payload: { data: data  },
    });   

  } catch (error) {
    console.error(error);
    dispatch({
      type: GET_TOTAL_REVENUE_CHARGES_FAILURE,
    });   
  }
};

export const getAttributeWindowCharges = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ATTRIBUTE_REVENUE_CHARGES_REQUEST,
    });   
    const { data } = await API.getRequest(
      `/api/v1/provider/revenue-from-msg-sent/`
    );
    dispatch({
      type: GET_ATTRIBUTE_REVENUE_CHARGES_SUCCESS,
      payload: { data: data  },
    });   

  } catch (error) {
    console.error(error);
    dispatch({
      type: GET_ATTRIBUTE_REVENUE_CHARGES_FAILURE,
    });   
  }
};


export const getImagingAdherenceModalityOptionsAction =
  () => async (dispatch) => {
    try {
      dispatch({ type: GET_IMAGING_ADHERENCE_MODALITY_OPTIONS_REQUEST });

      const { data } = await API.getRequest(
        '/api/v1/report2/aggregate/?agg_func=count&distinct=false&group_by=modality'
      );
      if (data?.length) {
        const transformedData = data.map(({ modality }) => ({
          value: modality,
          label: modality,
        }));

        dispatch({
          type: GET_IMAGING_ADHERENCE_MODALITY_OPTIONS_SUCCESS,
          payload: transformedData,
        });
      } else {
        dispatch({
          type: GET_IMAGING_ADHERENCE_MODALITY_OPTIONS_SUCCESS,
          payload: [],
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_IMAGING_ADHERENCE_MODALITY_OPTIONS_FAILURE,
        payload: error,
      });
    }
  };

export const getModalitiesAction = (rec) => async (dispatch) => {
  try {
    dispatch({ type: GET_MODALITIES_REQUEST });

    let followUpRec = '';
    if (rec) {
      followUpRec = `follow_up_recommended=${rec === 'imgReco'}&`;
    }

    const { data } = await API.getRequest(
      `/api/v1/report2/aggregate/?${followUpRec}agg_func=count&distinct=false&group_by=modality%2Cinitial_exam_date__year%2Cinitial_exam_date__month`
    );
    if (data?.length) {
      const transformedData = data.map(
        ({
          initial_exam_date__year,
          initial_exam_date__month,
          id__count,
          modality,
        }) => ({
          modality,
          modalityCount: id__count,
          date: `${initial_exam_date__month}.01.${initial_exam_date__year}`,
          timestamp: Date.parse(
            moment(
              `${initial_exam_date__month}.01.${initial_exam_date__year}`,
              'MM/DD/YYYY'
            ).toDate()
          ),
        })
      );
      const sortedResponce = sortByAsc(transformedData, 'timestamp');

      dispatch({ type: GET_MODALITIES_SUCCESS, payload: sortedResponce });
    } else {
      dispatch({ type: GET_MODALITIES_SUCCESS, payload: [] });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: GET_MODALITIES_FAILURE, payload: error });
  }
};

export const getFunnelAdherenceAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ADHERENCE_FUNNEL_REQUEST });

    const { data } = await API.getRequest(
      `/api/v1/report2/aggregate/?agg_func=count&distinct=false&group_by=follow_up_recommended%2Cinitial_exam_date__year%2Cinitial_exam_date__month`
    );
    if (data?.length) {
      const transformedData = data.map(
        ({
          initial_exam_date__year,
          initial_exam_date__month,
          id__count,
          follow_up_recommended,
        }) => ({
          imagingExamsCount: id__count,
          followUpsMadeCount: follow_up_recommended ? id__count : 0,
          date: moment(
            `${initial_exam_date__month}/01/${initial_exam_date__year}`,
            'M/D/YYYY'
          ).unix(),
        })
      );

      const sortedResponse = sortByAsc(transformedData, 'date');
      let responseObjGroupedByMonth = {};
      sortedResponse.forEach((data) => {
        const momentObject = moment.unix(data.date);
        const key = `${momentObject.month() + 1}.01.${momentObject.year()}`;
        if (responseObjGroupedByMonth[key]) {
          responseObjGroupedByMonth[key].push(data);
        } else {
          responseObjGroupedByMonth[key] = [data];
        }
      });
      let responseArrGroupedByMonth = Object.keys(
        responseObjGroupedByMonth
      ).reduce((acc, month) => {
        const dataArr = responseObjGroupedByMonth[month];
        const totalImagingExamsCount = dataArr.reduce(
          (acc, data) => acc + data.imagingExamsCount,
          0
        );
        const totalFollowUpsMadeCount = dataArr.reduce(
          (acc, data) => acc + data.followUpsMadeCount,
          0
        );
        return [
          ...acc,
          {
            date: month,
            timestamp: Date.parse(moment(month, 'MM/DD/YYYY').toDate()),
            imagingExamsCount: totalImagingExamsCount,
            followUpsMadeCount: totalFollowUpsMadeCount,
          },
        ];
      }, []);
      responseArrGroupedByMonth = sortByAsc(
        responseArrGroupedByMonth,
        'timestamp'
      );

      dispatch({
        type: GET_ADHERENCE_FUNNEL_SUCCESS,
        payload: responseArrGroupedByMonth,
      });
    } else {
      dispatch({ type: GET_ADHERENCE_FUNNEL_SUCCESS, payload: [] });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: GET_ADHERENCE_FUNNEL_FAILURE, payload: error });
  }
};

export const getExportReportAction =
  (dateStart, dateEnd, additionalFilters = {}, history) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: GET_EXPORT_REPORT_REQUEST });
      const payloadObject = {
        ordering_field: 'initial_exam_date',
        ordering_direction: 'ascending',
        follow_up_recommended_filter: true,
        negated_filter:false
      };

      const {
        selectedOption,
      } = additionalFilters;
    

      if (dateStart && dateEnd) {
       
        let startLabel;
        let endLabel;
        if (selectedOption === "range start") {
          startLabel = "nlp_range_start_greater_than_filter";
          endLabel = "nlp_range_start_less_than_filter";
        } else if (selectedOption === "range end") {
          startLabel = "nlp_range_end_greater_than_filter";
          endLabel = "nlp_range_end_less_than_filter";
        } else{
         startLabel = 'initial_exam_date_greater_than_filter';
         endLabel = 'initial_exam_date_less_than_filter';
        }
        payloadObject[startLabel] = dateStart;
        payloadObject[endLabel] = dateEnd;
      }
        
      if (additionalFilters.department){
        payloadObject.department_filter = additionalFilters.department;
      }
      if (additionalFilters.careType){
        payloadObject.care_type_filter = additionalFilters.careType;
      }
      if (additionalFilters.conditional !== ''){
        payloadObject.conditional_filter = additionalFilters.conditional;
      }
      if (additionalFilters.recommendation){
        payloadObject.recommendation_status_filter =
          Array.isArray(additionalFilters.recommendation)? additionalFilters.recommendation: [additionalFilters.recommendation];
      }
      if (additionalFilters.modality){
        payloadObject.modality_filter = Array.isArray(additionalFilters.modality)? additionalFilters.modality  : [additionalFilters.modality];
      }
      if (additionalFilters.anatomy){
        payloadObject.anatomy_filter = Array.isArray(additionalFilters.anatomy)? additionalFilters.anatomy: [additionalFilters.anatomy];
      }
      if (additionalFilters.radiologist){
        payloadObject.radiologists = Array.isArray(additionalFilters.radiologist)? additionalFilters.radiologist  : [additionalFilters.radiologist];
      }
      if (additionalFilters.primaryCarePhysician){
        payloadObject.primary_care_physician =
          additionalFilters.primaryCarePhysician;
      }
      if (additionalFilters.referringPhysician){
        payloadObject.referring_physicians =  Array.isArray(additionalFilters.referringPhysician)? additionalFilters.referringPhysician : [
          additionalFilters.referringPhysician,
        ];
      }
      if (additionalFilters.selectedPhysicians){
        payloadObject.referring_physicians =
          additionalFilters.selectedPhysicians;
      }
      if (additionalFilters.timeframe){
        payloadObject.timeframe_status_extracted_filter =  Array.isArray(additionalFilters.timeframe)? additionalFilters.timeframe : [
          additionalFilters.timeframe,
        ];
      }
      if (additionalFilters.guidelineType){
        payloadObject.guideline_type_filter = Array.isArray(additionalFilters.guidelineType)? additionalFilters.guidelineType : [
          additionalFilters.guidelineType,
        ]; 
      }

      if(additionalFilters?.reportText){
        payloadObject.report_text_filter = additionalFilters.reportText
      }
      if(additionalFilters?.exclude){
        payloadObject.neg_report_text_filter = additionalFilters.exclude
      }
      if(additionalFilters?.location){
        payloadObject.location_address_filter = Array.isArray(additionalFilters.location)? additionalFilters.location : [
          additionalFilters.location,
        ];
      }
      if(additionalFilters?.examCode){
        payloadObject.exam_code_filter =Array.isArray(additionalFilters.examCode)? additionalFilters.examCode: [additionalFilters.examCode];
      }
      if(additionalFilters?.examCodeNegated){
        payloadObject.exam_code_ne_filter = Array.isArray(additionalFilters.examCodeNegated)? additionalFilters.examCodeNegated: [additionalFilters.examCodeNegated];
      }
      if(additionalFilters?.modalityNegated){
        payloadObject.modality_ne_filter =  Array.isArray(additionalFilters.modalityNegated)? additionalFilters.modalityNegated: [additionalFilters.modalityNegated];
      }
      if(additionalFilters?.messageSent){
        payloadObject.message_sent_filter = additionalFilters.messageSent
      }
      if(additionalFilters?.recoIdList){
        payloadObject.reco_ids_filter =  additionalFilters.recoIdList
      }
      
      if(additionalFilters?.anatomyNegated){
        payloadObject.anatomy_ne_filter =  Array.isArray(additionalFilters.anatomyNegated)? additionalFilters.anatomyNegated: [additionalFilters.anatomyNegated];
      }
      if (additionalFilters.recoModality){
        payloadObject.reco_modality_filter = Array.isArray(additionalFilters.recoModality)? additionalFilters.recoModality  : [additionalFilters.recoModality];
      }
      if(additionalFilters?.recoModalityNegated){
        payloadObject.reco_modality_ne_filter = Array.isArray(additionalFilters.recoModalityNegated)? additionalFilters.recoModalityNegated  : [additionalFilters.recoModalityNegated]; 
      }

      if(additionalFilters?.cohort){
        payloadObject.cohort_filter = Array.isArray(additionalFilters.cohort)? additionalFilters.cohort : [
          additionalFilters.cohort,
        ];
      }

      if(additionalFilters?.contrast){
        payloadObject.contrast_filter= additionalFilters.contrast
      }
      if(additionalFilters?.examType){
        payloadObject.exam_type_filter= additionalFilters.examType
      }
      if(additionalFilters?.taskType){
        payloadObject.note_task_type_filter=   Array.isArray(additionalFilters.taskType)? additionalFilters.taskType : [
          additionalFilters.taskType,
        ];
      }
      if(additionalFilters?.hasNote){
        payloadObject.has_note_filter= additionalFilters.hasNote
      }
   
      if(additionalFilters?.recommendationType){
        payloadObject.recommendation_type_filter= additionalFilters.recommendationType
      }
      if(additionalFilters?.language){
        payloadObject.patient_language_filter= additionalFilters.language
      }
      if(additionalFilters?.age){
        payloadObject.patient_age_group_filter=Array.isArray(additionalFilters.age)? additionalFilters.age: [additionalFilters.age];
      }

      if(additionalFilters?.examDate && !dateStart && !dateEnd){
        let dateArr = additionalFilters.examDate.split(' - ');
         payloadObject.initial_exam_date_greater_than_filter = dateArr[0];
         payloadObject.initial_exam_date_less_than_filter = dateArr[1];
      }

      if( additionalFilters?.rangeLabel && additionalFilters?.rangeDate){
        let formattedDate =  additionalFilters.rangeDate && moment(additionalFilters.rangeDate).format('YYYY-MM-DD')
        payloadObject[additionalFilters?.rangeLabel] = formattedDate
      }
       
      if( additionalFilters?.rangeDateGte && additionalFilters?.rangeDateLte){
        const formattedStartDate = moment(additionalFilters.rangeDateGte).format('YYYY-MM-DD');
        const formattedEndDate = moment(additionalFilters.rangeDateLte).format('YYYY-MM-DD');

        payloadObject[additionalFilters?.rangeLabelGte]= formattedStartDate;
        payloadObject[additionalFilters?.rangeLabelLte] = formattedEndDate;
      }

      const { data } = await API.postRequest(`/api/v1/export/`, payloadObject);
      dispatch({ type: GET_EXPORT_REPORT_SUCCESS, payload: { data } });
      dispatch(
        showAlert(
          'success',
          'Success',
          'Your export was successful! View it in the Settings -> Export page to view the status.'
        )
      );
      return true;
    } catch (error) {
      console.error(error);
      dispatch(showAlert('danger', 'Error', error.message));
      dispatch({ type: GET_EXPORT_REPORT_FAILURE, payload: error.message });
    }
  };

export const createCohortExport = (cohortId) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_COHORTS_EXPORT_REQUEST });

    const { data } = await API.postRequest(`/api/v1/export/`, {
      ordering_direction: 'ascending',
      cohort: cohortId,
      follow_up_recommended_filter: true,
    });

    dispatch(showAlert('success', 'Success', 'Your export was successful!'));
    return { success: true };
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
  }
};


export const saveFilterRecommendations = ({title, list}) => async (dispatch) => {
  try {

    const { data } = await API.postRequest(`/api/v1/recommendation-filters/`, {
      "title": title,
      "filters": list
    });
    dispatch({ type: CREATE_SAVED_FILTER_RECOMMNEDATIONS_SUCCESS, payload: data });
    dispatch(showAlert('success', 'Success', 'Filter saved successfully!'));
    dispatch(getSavedFilterRecommendations());
    return data
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
  }
};

export const saveCohorts = ({title, list, modalityOperator,anatomyOperator,reportModalityOperator, examCodeOperator}) => async (dispatch) => {
  try {
const payload = {
  cohort_title: title,
  raw_filters: {
    condition: "and",
      filters: list.map(itm => ({
      model:    itm.category ==='age' || itm.category ==='has_note'? 'Patient'  
               : itm.category ==='report_modality' || itm.category ==='location' || itm.category === 'radiologist' || itm.category === 'referrers'  || itm.category=== 'message_sent' ? 'Report2'
               : 'Recommendation',
      attribute: itm.category === 'message_sent'?'message_sent'
               : itm.category ==='location'?'location_address'
               : itm.category ==='has_note'?'contains_note'
               : itm.category ==='timeframe'?'timeframe_status_extracted' 
               : itm.category === 'report_modality'? 'modality'
               : itm.category === 'radiologist'? 'radiologist'
               : itm.category === 'referrers'?'referring_physician'
               : itm.category === 'age'? 'age_group'
               : itm.category === 'task_type'? 'note_task_type'
               : itm.category,
      value:  itm.value,
      operator: "",
      exclude: (itm.category ==='modality' && modalityOperator.value === "isNotEqual"
      ) || (itm.category === 'anatomy' && anatomyOperator.value === "isNotEqual"
      ) || (itm.category === 'report_modality' && reportModalityOperator.value === "isNotEqual")
        || (itm.category ==='exam_code' && examCodeOperator.value === "isNotEqual")? 1 : 0
    }))
        }
}

    await API.postRequest(`/api/v1/cohort/`, payload);
    dispatch(showAlert('success', 'Success', 'Cohort saved successfully!'));
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
  }
};


export const getSavedFilterRecommendations = (page=1) => async (dispatch) => {
  try {
    const {data} = await API.getRequest(
      `/api/v1/recommendation-filters/?page=${page}`
    );
    dispatch({ type: GET_SAVED_FILTER_RECOMMENDATIONS_SUCCESS, payload: data.results });
    return data
  } catch (error) {
    console.error(error);
    dispatch(showAlert('danger', 'Error', error.message));
  }
};

export const getSavedFilterRecommendationsInitial = (page=1) => async (dispatch) => {
  try {
    const {data} = await API.getRequest(
      `/api/v1/recommendation-filters/?page=${page}`
    );
    dispatch({ type: RESET_SAVED_FILTER_RECOMMENDATIONS_SUCCESS, payload: data.results });
    return data
  } catch (error) {
    console.error(error);
    dispatch(showAlert('danger', 'Error', error.message));
  }
};


export const deleteSavedFilterRecommendations = (id) => async (dispatch, getState) => {
  try {
   await API.deleteRequest(
      `/api/v1/recommendation-filters/${id}/`
    );

    let savedFiltersList = getState().radAdmin.followUpListSavedFilters.data;
    let requiredIndex = savedFiltersList.findIndex((x) => x.id === id);
    savedFiltersList.splice(requiredIndex, 1);
    dispatch({ type: DELETE_SAVED_FILTER_RECOMMENDATIONS_SUCCESS , payload: savedFiltersList });
    dispatch(showAlert('success', 'Success', 'Filter deleted successfully!'));
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
  }
};

export const updateSavedFilterRecommendations = ({id, title}) => async (dispatch, getState) => {
  try {
   await API.patchRequest(
      `/api/v1/recommendation-filters/${id}/`,{
        title:title
      }
    );
    
    let savedFiltersList = getState().radAdmin.followUpListSavedFilters.data;
    let updatedSavedFiltersList = savedFiltersList.map((x) => x.id === id ? {...x, title:title} :x);
    dispatch({ type:EDIT_SAVED_FILTER_RECOMMENDATIONS_SUCCESS , payload: updatedSavedFiltersList });
    dispatch(showAlert('success', 'Success', 'Filter updated successfully!'));
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
  }
};

export const getTwilioConfig = () => async (dispatch) => {
  try {
    dispatch({ type: GET_TWILIO_CONFIG_REQUEST });
    const twilioConfigRes = await API.getRequest(
      `/api/v1/twilio_response_config/`
    );
    const twilioConfig = twilioConfigRes.data.results[0];
    dispatch({ type: GET_TWILIO_CONFIG_SUCCESS, payload: twilioConfig });
  } catch (error) {
    console.error(error);
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_TWILIO_CONFIG_FAILURE, payload: error.message });
  }
};

export const getFindingsBoi = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_FINDINGS_BOI_REQUEST });
    const findingsRes = await API.getRequest(
      `/api/v1/findings-boi/?report__recommendations=${id}`
    );
    dispatch({ type: GET_FINDINGS_BOI_SUCCESS, payload: findingsRes });
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_FINDINGS_BOI_FAILURE, payload: error.message });
  }
};

export const updateTwilioConfig = (id, responseConfig) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_TWILIO_CONFIG_REQUEST });

    const { data } = await API.putRequest(
      `/api/v1/twilio_response_config/${id}/`,
      responseConfig
    );

    dispatch({ type: UPDATE_TWILIO_CONFIG_SUCCESS, payload: data });
    dispatch(
      showAlert('success', 'Success', 'Default responses successfully saved!')
    );
  } catch (error) {
    console.error(error);
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: UPDATE_TWILIO_CONFIG_FAILURE, payload: error.message });
  }
};

export const deleteTwilioConfig = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_TWILIO_CONFIG_REQUEST });
    let response = await API.deleteRequest(
      `/api/v1/sms_response_option/${id}/`
    );
    dispatch({ type: DELETE_TWILIO_CONFIG_SECCESS, payload: response });
    dispatch(showAlert('success', 'Success', 'Successfully deleted!'));
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: DELETE_TWILIO_CONFIG_FAILURE, payload: error });
  }
};

export const getSmsResponse = () => async (dispatch) => {
  try {
    dispatch({ type: GET_SMS_RESPONSE_REQUEST });

    const {
      data: { results },
    } = await API.getRequest(`/api/v1/sms_response_option/`);
    const option = {
      number: 1,
      name: '',
      value: '',
    };
    const smsData = results.length ? results : [option];
    dispatch({ type: GET_SMS_RESPONSE_SUCCESS, payload: smsData });
  } catch (error) {
    console.error(error);
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_SMS_RESPONSE_FAILURE, payload: error.message });
  }
};

export const updateSmsResponse =
  (twillioConfigId, { number, name, value, id , reco_status}) =>
  async (dispatch) => {
    try {
      dispatch({ type: UPDATE_SMS_RESPONSE_REQUEST });

      const responseData = {
        number,
        name,
        value,
        twilio_response_config: twillioConfigId,
        reco_status: reco_status.value
      };
      if (id) {
        await API.putRequest(
          `/api/v1/sms_response_option/${id}/`,
          responseData
        );
      } else {
        await API.postRequest(`/api/v1/sms_response_option/`, responseData);
      }

      dispatch(getSmsResponse());
      dispatch(
        showAlert('success', 'Success', 'SMS responses saved successfully')
      );
    } catch (error) {
      console.error(error);
      dispatch(showAlert('danger', 'Error', error.message));
      dispatch({ type: UPDATE_SMS_RESPONSE_FAILURE, payload: error.message });
    }
  };

export const getRecentIncomingMessages =
  (prevCount = 0, query = '', phoneClosed=false, page=1,currentTab ='active', filters={}) =>
  async (dispatch, getState) => {
   const searchFilter  = query? `&last_name__icontains=${query}` : '';
   const phoneCloseFilter =  `&phone_closed=${phoneClosed}`;
   const searchQueryFilter =   query? `&search=${query}` : '';
   const phoneUnsubFilter =  currentTab==='active' ? `&phone_unsubscribed=false`: currentTab === 'unsubscribed'? `&phone_unsubscribed=true` :'';
   
   let locationFilter = '';

   if(filters?.location?.length>0){
    locationFilter = '&' + filters.location.map(itm=>`report_location=${itm.value}`).join('&')
   }
  
   try {
      dispatch({ type: GET_RECENT_INCOMING_MESSAGES_REQUEST });

      await dispatch(getRecoConfig());
      const conditional = getState().radAdmin.recoConfig.data?.conditional;
      const negated = getState().radAdmin.recoConfig.data?.negated;
      let nextPage = page;
        const { data } = await API.getRequest(
          `/api/v1/sms_message/paginated_aggregate/?agg_func=max&group_by=patient_phone_number&direction=inbound&field=created&order_by=-created__max&page=${page}${searchQueryFilter}${phoneCloseFilter}${phoneUnsubFilter}${locationFilter}
          `
        );
        if (data?.next) {
          nextPage = nextPage + 1;
        } else {
          nextPage = null;
        }
       
        dispatch({
          type: SET_USERS_PHONE_NUMBERS,
          payload: data && data?.results?.length ? data?.results : [],
        });
      
      const currentPortion =  data.results;
      let allMessages = await Promise.all(
        currentPortion &&
          currentPortion.map(({ patient_phone_number }) => API.getRequest(`/api/v1/sms_message/paginated_aggregate/?field=created&order_by=-created__max&agg_func=max&group_by=message_ssid&patient_phone_number=${patient_phone_number.replace(
            '+',
            '%2B'
          )}&page=${1}${searchQueryFilter}${phoneCloseFilter}${phoneUnsubFilter}${locationFilter}`)
          )
      );
     
      const usersInfoRes = await Promise.all(
        currentPortion &&
          currentPortion.map(({ patient_phone_number }) =>
            API.getRequest(
              `/api/v1/patient/?${phoneCloseFilter}${searchQueryFilter}${phoneUnsubFilter}&phone_number=${patient_phone_number.replace(
                '+',
                '%2B'
              )}`
            )
          )
      );
      allMessages = allMessages.filter((message,idx) => {
        if (
          usersInfoRes[idx].data.results.length === 0        )
          return false;
        return true;
      });

      const usersDetailsRes = await Promise.all(
        usersInfoRes.map(
          ({ data }) =>
            data?.results[0]?.id &&
            API.getRequest(
              `/api/v1/recommendation/details/?${
                !conditional ? `&conditional=${conditional}` : ''
              }${
                !negated ? `&negated=${negated}` : ''
              }&user_rejected=${false}&report__patient=${data.results[0].id}`
            )
        )
      );

      const aggrInboundMsgs = (
        await Promise.all(
          currentPortion.map(({ patient_phone_number }) =>
            API.getRequest(
              `/api/v1/sms_message/aggregate/?patient_phone_number=${patient_phone_number.replace(
                '+',
                '%2B'
              )}&direction=inbound&agg_func=count&field=id`
            )
          )
        )
      ).map(({ data }) => data);

      const recentMsg = (
        await Promise.all(
          currentPortion.map(({ patient_phone_number }) =>
            API.getRequest(
              `/api/v1/sms_message/?patient_phone_number=${patient_phone_number.replace(
                '+',
                '%2B'
              )}&direction=inbound&ordering=-created`
            )
          )
        )
      ).map(({ data }) => data);
      
      const filteredUsersDetailsRes = usersDetailsRes.filter((item) => item);
      const usersInfo = usersInfoRes
        .reduce((sum, item) => sum.concat(item.data.results), [])
        .map((item) => {
          const details = filteredUsersDetailsRes.find(
            ({ data }) => data.results[0]?.report?.patient === item.id
          );
          return details
            ? {
                ...item,
                ...details.data.results[0],
              }
            : {
                ...item,
              };
        });
        const parsedAllMessages = await Promise.all(allMessages.map(async ({ data }, index) => {
          let msg = await Promise.all(
              data.results && data.results.map(({ message_ssid }) => API.getRequest(`/api/v1/sms_message/?ordering=-created&message_ssid=${message_ssid|| ''}`))
          );
          return {
              ...data,
              patient_phone_number: msg[0]?.data?.results[0]?.patient_phone_number,
              results: msg.map(itm => itm.data.results.find(item => item.message !=="Message not available.") || itm?.data?.results[0] ).reverse(),
              inboundMsgCount: aggrInboundMsgs && aggrInboundMsgs.length ? aggrInboundMsgs[index].id__count : 0,
              recentMsg: recentMsg[index]
          };
      }));
      
      dispatch({ type: SET_USERS_MESSAGES_INFO, payload: usersInfo });
      dispatch({
        type: GET_RECENT_INCOMING_MESSAGES_SUCCESS,
        payload: {parsedAllMessages , page},
      });
      return nextPage
    } catch (error) {
      console.error(error);
      dispatch(showAlert('danger', 'Error', error.message));
      dispatch({
        type: GET_RECENT_INCOMING_MESSAGES_FAILURE,
        payload: error.message,
      });
    }
  };
  
export const createOutgoingMessage = (messageData) => async (dispatch) => {
  try {
    dispatch({
      type: messageData.receiverName
        ? CREATE_OUTGOING_MESSAGE_REQUEST_FROM_DIALOG
        : CREATE_OUTGOING_MESSAGE_REQUEST,
    });
    let dataToSend = {
      patient_phone_number: messageData.patientPhoneNumber,
      twilio_phone_number: messageData.twilioPhoneNumber,
      send_at: messageData.sendAt,
      ...(messageData.receiverName && { sms_template: messageData.message }),
      ...(!messageData.receiverName && { message: messageData.message }),
    };
    const message = await API.postRequest(`/api/v1/sms_message/`, dataToSend);
    if (messageData.receiverName) {
      dispatch({ type: CREATE_OUTGOING_MESSAGE_SUCCESS_FROM_DIALOG });
    } else {
      dispatch({
        type: CREATE_OUTGOING_MESSAGE_SUCCESS,
        payload: {
          message: message.data,
          patientPhoneNumber: messageData.patientPhoneNumber,
        },
      });
    }
    if (messageData.receiverName) {
      let createdAt = new Date(message.data.created).getMinutes();
      let sendAt = new Date(message.data.send_at).getMinutes();
      if (createdAt === sendAt || createdAt === sendAt + 1) {
        dispatch(
          showAlert(
            'success',
            'Sent',
            `Successfully sent to ${messageData.receiverName}!`
          )
        );
      } else {
        dispatch(
          showAlert(
            'success',
            'Success',
            `Successfully scheduled to ${messageData.receiverName}!`
          )
        );
      }
    }
    return { success: true };
  } catch (error) {
    // if (messageData.receiverName) {
      dispatch(showAlert('danger', 'Error', error.message));
    // }
    console.error(error);
    dispatch({
      type: messageData.receiverName
        ? CREATE_OUTGOING_MESSAGE_FAILURE_FROM_DIALOG
        : CREATE_OUTGOING_MESSAGE_FAILURE,
      payload: error.message,
    });
  }
};

export const createOutgoingFax = (faxData) => async (dispatch) => {
  try {
    let dataToSend = {
      referring_physician: faxData.referringPhysicianId,
      cover_page_id: faxData.coverPageId,
      scheduled_at: faxData.sendAt
    };
    await API.postRequest(`/api/v1/fax-records/`, dataToSend);
    return { success: true };
  } catch (error) {
      dispatch(showAlert('danger', 'Error', error.message));   
  }
};

export const getMoreChatMessages = (moreChatMessages) => async (dispatch) => {
  const requestUrl = moreChatMessages.page.slice(
    moreChatMessages.page.indexOf('/api/v1')
  );

  try {
    dispatch({ type: GET_MORE_CHAT_MESSAGES_REQUEST });

    const { data } = await API.getRequest(requestUrl);

    let parsedAllMessages =  await Promise.all(
      data.results && data.results.map(({ message_ssid }) => API.getRequest(`/api/v1/sms_message/?ordering=-created&message_ssid=${message_ssid || ''}`))
    );
    let newData = parsedAllMessages.map(itm=>itm?.data?.results?.find(item => item.message !=="Message not available.") || itm?.data?.results[0] ).reverse();

    dispatch({
      type: GET_MORE_CHAT_MESSAGES_SUCCESS,
      payload: { data: {...data, results:newData}, patientPhoneNumber: moreChatMessages.phoneNumber },
    });
  } catch (error) {
    console.error(error);
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_MORE_CHAT_MESSAGES_FAILURE, payload: error.message });
  }
};

export const getRecoModalitiesAction = (timeframe) => async (dispatch) => {
  const displayPiesCount = 5;
  try {
    dispatch({ type: GET_RECO_MODALITIES_REQUEST });

    let timeframeStatus = '';
    if (timeframe) {
      timeframeStatus = `timeframe_status_extracted=${timeframe}`;
    }

    const { data } = await API.getRequest(
      `/api/v1/recommendation/aggregate/?user_rejected=${false}&agg_func=count&order_by=-id__count&group_by=modality&${timeframeStatus}`
    );
    const sortedData = data
      .filter(({ modality }) => modality)
      .sort((a, b) => a - b);
    const topFirst = sortedData.slice(0, displayPiesCount);
    const sumOfRest = sortedData
      .slice(displayPiesCount)
      .reduce((sum, item) => sum + item.id__count, 0);
    const displayItems = sumOfRest
      ? topFirst
          .concat({ modality: 'others', id__count: sumOfRest })
          .map(({ modality, id__count }) => ({
            modality: setModalityCase(modality),
            id__count,
          }))
      : topFirst.map(({ modality, id__count }) => ({
          modality: setModalityCase(modality),
          id__count,
        }));

    dispatch({ type: GET_RECO_MODALITIES_SUCCESS, payload: displayItems });
  } catch (error) {
    console.error(error);
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_RECO_MODALITIES_FAILURE, payload: error });
  }
};

export const getPatientHistoryAction =
  (id, isNeedNext) => async (dispatch, getState) => {
    const nextReportsUrl =
      getState().radAdmin.messages.patientHistory?.data?.next;
    const requestReportsUrl =
      !isNeedNext && !nextReportsUrl
        ? `/api/v1/report2/?patient=${id}`
        : nextReportsUrl.slice(nextReportsUrl.indexOf('/api/v1'));
    try {
      dispatch({ type: GET_PATIENT_HISTORY_REQUEST });

      const { data } = await API.getRequest(requestReportsUrl);

      const uniquePhysicianIds = [
        ...new Set(data.results.map((item) => item.referring_physician)),
      ];

      const physiciansRes = await Promise.all(
        uniquePhysicianIds.map((id) =>
          API.getRequest(`/api/v1/physician/${id}/`)
        )
      );
      const physicians = physiciansRes.map((item) => item.data);

      const patientHistoryData = data.results.map((item) => {
        const foundItem = physicians.find(
          ({ id }) => id === item.referring_physician
        );
        if (foundItem) {
          return {
            ...item,
            physicianName: `${foundItem.first_name} ${foundItem.last_name}`,
            date: moment(item.initial_exam_date).format('MM.DD.YYYY'),
            modality: setModalityCase(item.modality),
          };
        }
      });

      dispatch({
        type: GET_PATIENT_HISTORY_SUCCESS,
        payload: {
          data: patientHistoryData,
          next: data.next,
          prev: data.previous,
          id,
        },
      });
    } catch (error) {
      console.error(error);
      dispatch(showAlert('danger', 'Error', error.message));
      dispatch({ type: GET_PATIENT_HISTORY_FAILURE, payload: error });
    }
  };

export const getRecoAnatomiesAction = (timeframe) => async (dispatch) => {
  const displayPiesCount = 5;
  const setCurrectCase = (name) =>
    `${name.slice(0, 1).toUpperCase()}${name.slice(1)}`;
  try {
    dispatch({ type: GET_RECO_ANATOMIES_REQUEST });

    let timeframeStatus = '';
    if (timeframe) {
      timeframeStatus = `timeframe_status_extracted=${timeframe}`;
    }

    const { data } = await API.getRequest(
      `/api/v1/recommendation/aggregate/?user_rejected=${false}&agg_func=count&order_by=-id__count&group_by=anatomy&${timeframeStatus}`
    );
    const sortedData = data
      .filter(({ anatomy }) => anatomy)
      .sort((a, b) => a - b);
    const topFirst = sortedData.slice(0, displayPiesCount);
    const sumOfRest = sortedData  
      .slice(displayPiesCount)
      .reduce((sum, item) => sum + item.id__count, 0);
    const displayItems = sumOfRest
      ? topFirst
          .concat({ anatomy: 'others', id__count: sumOfRest })
          .map(({ anatomy, id__count }) => ({
            anatomy: setCurrectCase(anatomy),
            id__count,
          }))
      : topFirst.map(({ anatomy, id__count }) => ({
          anatomy: setCurrectCase(anatomy),
          id__count,
        }));

    dispatch({ type: GET_RECO_ANATOMIES_SUCCESS, payload: displayItems });
  } catch (error) {
    console.error(error);
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_RECO_ANATOMIES_FAILURE, payload: error });
  }
};

export const getRecoAnatomiesAndModalitiesAction = (timeframe) => async (dispatch) => {
  const displayPiesCount = 5;
  const setCasing = (name) =>
    `${name?.slice(0, 1).toUpperCase()}${name?.slice(1)}`;
  try {
    dispatch({ type: GET_RECO_ANATOMIES_AND_MODALITIES_REQUEST });

    let timeframeStatus = '';
    if (timeframe) {
      timeframeStatus = `timeframe_status_extracted=${timeframe}`;
    }
    const { data } = await API.getRequest(
      `/api/v1/recommendation/aggregate/?user_rejected=${false}&agg_func=count&order_by=-id__count&group_by=anatomy,modality&${timeframeStatus}`
    );

    const sortedData = data
      .filter(({ anatomy,modality }) => anatomy && modality)
      .sort((a, b) => a - b);
    const topFirst = sortedData.slice(0, displayPiesCount);
    const sumOfRest = sortedData
      .slice(displayPiesCount)
      .reduce((sum, item) => sum + item.id__count, 0);
    const displayItems = sumOfRest
      ? topFirst
          .map(({ modality, anatomy, id__count }) => ({
            modalityAndAnatomy: setCasing(modality) + ' ' + setCasing(anatomy),
            id__count,
          })).concat({modalityAndAnatomy:setCasing('others'), id__count: sumOfRest })

      : topFirst.map(({ modality, anatomy, id__count }) => ({
          modalityAndAnatomy: setCasing(modality) + ' ' + setCasing(anatomy),
          id__count,
        }));

    dispatch({ type: GET_RECO_ANATOMIES_AND_MODALITIES_SUCCESS, payload: displayItems });
  } catch (error) {
    console.error(error);
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_RECO_ANATOMIES_AND_MODALITIES_FAILURE, payload: error });
  }
};


export const updateNote = (id, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_NOTE_REQUEST });
    let response = await API.putRequest(`/api/v1/note/${id}/`, data);
    let notesData = getState().radAdmin.notes.data;
    let userData = getState().auth.user;
    let requiredIndex = notesData.findIndex((x) => x.id === response.data.id);
    let newObj = {
      ...response.data,
      user: userData,
    };
    notesData[requiredIndex] = newObj;
    dispatch({ type: UPDATE_NOTE_SUCCESS, payload: notesData });
    dispatch(showAlert('success', 'Success', 'Note successfully updated!'));
    return {
      updated: true,
    };
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: UPDATE_NOTE_FAILURE, payload: error });
    return {
      updated: false,
    };
  }
};

export const createNote = (data, order) => async (dispatch, getState) => {
  try {
    let response = await API.postRequest('/api/v1/note/', data);
    dispatch(getNotes(response.data.patient,1,order))
    dispatch(showAlert('success', 'Success', 'Note successfully created!'));
    return {
      created: true,
    };
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: CREATE_NOTE_FAILURE, payload: error });
  }
};

export const deleteNote = (idx) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_NOTE_REQUEST });
    let response = await API.deleteRequest(`/api/v1/note/${idx}/`);
    let notesData = getState().radAdmin.notes.data;
    let requiredIndex = notesData.findIndex((x) => x.id === idx);
    notesData.splice(requiredIndex, 1);
    dispatch({ type: UPDATE_NOTE_SUCCESS, payload: notesData });
    dispatch(showAlert('success', 'Success', 'Note successfully deleted!'));
    return {
      deleted: true,
    };
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: UPDATE_NOTE_FAILURE, payload: error });
  }
};

export const getNotes = (id, page,order="descending") => async (dispatch) => {
  if (!id) return;
  try {
    dispatch({ type: GET_NOTES_REQUEST });
    let notesOrder  = order === 'ascending'?'&ordering=created' :'&ordering=-created'
    let { data } = await API.getRequest(
      `/api/v1/note?patient=${id}&page=${page}${notesOrder}`
    );
    let notesData = data.results;
    if (notesData && notesData.length > 0) {
      let res = await Promise.allSettled(
        data.results.map(({ user }) => API.getRequest(`/api/v1/user/${user}`))
      );
      notesData = notesData.map((x, i) => {
        return {
          ...x,
          user: res[i].status === 'fulfilled' && res[i].value.data,
        };
      });
    }
    dispatch({
      type: GET_NOTES_SUCCESS,
      payload: { data: notesData, totalItems: data.count, page },
    });
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_NOTES_FAILURE, payload: error });
  }
};

export const getNotesData = (page=1) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_NOTES_REQUEST });
    let { data } = await API.getRequest(
      `/api/v1/note/?ordering=-created&page=${page}`
    );
    let notesData = data.results;
    if (notesData && notesData.length > 0) {

      const patients =  data.results.map(({ patient }) => API.getRequest(`/api/v1/patient/${patient}`));
      const users =  data.results.map(({ user }) => API.getRequest(`/api/v1/user/${user}`)) ;
      const patientInfo = Promise.allSettled(patients);
      const userInfo = Promise.allSettled(users);
    
      const [patientData, userData] = await Promise.all([
        patientInfo,
        userInfo
      ]);

      notesData = notesData.map((x, i) => {
        return {
          ...x,
          user: userData[i].status === 'fulfilled' && userData[i].value.data,
          patient:  patientData[i].status === 'fulfilled' && patientData[i].value.data,
        };
      });
    }
    dispatch({
      type: GET_ALL_NOTES_SUCCESS,
      payload: { data: notesData, totalItems: data.count, page },
    });
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_ALL_NOTES_FAILURE, payload: error });
  }
};

export const getNotesAnalytics =
  (date = '') =>
  async (dispatch) => {
    var today = new Date();
    let lastMoment = new Date(today.getTime() - 90 * 24 * 60 * 60 * 1000);
    let day = lastMoment.getDate();
    let month = lastMoment.getMonth() + 1;
    let year = lastMoment.getFullYear();
    let defaultDate = `${month}/${day}/${year}`;
    try {
      dispatch({ type: GET_NOTES_ANALYTICS_REQUEST });
      const dateFilter = date
        ? `note_created__gte=${date}&`
        : `note_created__gte=${defaultDate}&`;
      let { data: notesData } = await API.getRequest(
        `/api/v1/note/aggregate/?agg_func=count&${dateFilter}distinct=false&group_by=created`
      );
      let requiredArray = [];
      notesData.map((note) => {
        let splitedDate = note.created.split('T')[0];
        let timeStamp = new Date(note.created).getTime();
        let obj = {
          date: splitedDate,
          notes: note.id__count,
          timeStamp,
        };
        if (isEmpty(requiredArray)) {
          requiredArray = [...requiredArray, obj];
        } else {
          let index = requiredArray.findIndex((x) => x.date === obj.date);
          if (index === -1) {
            requiredArray = [...requiredArray, obj];
          } else {
            let existingObj = requiredArray[index];
            requiredArray[index] = {
              ...existingObj,
              notes: existingObj.notes + 1,
            };
          }
        }
      });
      dispatch({ type: GET_NOTES_ANALYTICS_SUCCESS, payload: requiredArray });
    } catch (error) {
      dispatch(showAlert('danger', 'Error', error.message));
      dispatch({ type: GET_NOTES_ANALYTICS_FAILURE, payload: error });
    }
  };

export const getTemplates = (pageNo) => async (dispatch) => {
  try {
    dispatch({ type: GET_TEMPLATES_REQUEST });
    let { data } = await API.getRequest(
      `/api/v1/sms_template/?page=${pageNo}&ordering=-created`
    );
    dispatch({
      type: GET_TEMPLATES_SUCCESS,
      payload: { data: data.results, totalItems: data.count, pageNo },
    });
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_TEMPLATES_FAILURE, payload: error });
  }
};

export const getFaxTemplates = (pageNo) => async (dispatch) => {
  try {
    dispatch({ type: GET_FAX_TEMPLATES_REQUEST });
    let { data } = await API.getRequest(
      `/api/v1/fax-template/?page=${pageNo}&ordering=-created`
    );
    dispatch({
      type: GET_FAX_TEMPLATES_SUCCESS,
      payload: { data: data.results, totalItems: data.count, pageNo },
    });
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_FAX_TEMPLATES_FAILURE, payload: error });
  }
};


export const getLetterTemplates = (pageNo) => async (dispatch) => {
  try {
    dispatch({ type: GET_LETTER_TEMPLATES_REQUEST });
    let { data } = await API.getRequest(
      `/api/v1/letter_template/?page=${pageNo}&ordering=-created`
    );
    dispatch({
      type: GET_LETTER_TEMPLATES_SUCCESS,
      payload: { data: data.results, totalItems: data.count, pageNo },
    });
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_LETTER_TEMPLATES_FAILURE, payload: error });
  }
};


export const getLetterContent = (id,recIds) => async (dispatch) => {
  try {
    const letterId = `letter_template_id=${id}&`;
    const recommendationId = recIds.map(itm=>`recommendation_id=${itm}`).join('&')
    let  results  = await API.getRequest(
      `/api/v1/letter_template/populate_letter_templates/?${letterId}${recommendationId}`
    );
   
    return results?.data
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
  }
};


export const createTemplate = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_TEMPLATES_REQUEST });
    let response = await API.postRequest('/api/v1/sms_template/', data);
    let templatesData = getState().radAdmin.templates.data;
    let totalItems = getState().radAdmin.templates.totalItems;
    templatesData = [...templatesData, response.data];
    dispatch(setTemplateIdToPreview(response.data.id));
    dispatch({
      type: GET_TEMPLATES_SUCCESS,
      payload: { data: templatesData, totalItems: totalItems },
    });
    dispatch(showAlert('success', 'Success', 'Template successfully created!'));
    return { response: response.data };
  } catch (error) {
    dispatch(
      showAlert(
        'danger',
        'Error',
        'Maybe something wrong with you template' + ' ' + error.message
      )
    );
    dispatch({ type: GET_TEMPLATES_FAILURE, payload: error });
  }
};

export const createFaxTemplate = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_FAX_TEMPLATES_REQUEST });
    let response = await API.postRequest('/api/v1/fax-template/', data);
    let templatesData = getState().radAdmin.faxTemplates.data;
    let totalItems = getState().radAdmin.faxTemplates.totalItems;
    templatesData = [...templatesData, response.data];
    dispatch(setTemplateIdToPreview(response.data.id));
    dispatch({
      type: GET_FAX_TEMPLATES_SUCCESS,
      payload: { data: templatesData, totalItems: totalItems },
    });
    dispatch(showAlert('success', 'Success', 'Template successfully created!'));
    return { response: response.data };
  } catch (error) {
    dispatch(
      showAlert(
        'danger',
        'Error',
        error.message
      )
    );
    dispatch({ type: GET_TEMPLATES_FAILURE, payload: error });
  }
};


export const createLetterTemplate = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_LETTER_TEMPLATES_REQUEST });
    let response = await API.postRequest('/api/v1/letter_template/', data);
    let templatesData = getState().radAdmin.letterTemplates.data;
    let totalItems = getState().radAdmin.letterTemplates.totalItems;
    templatesData = [...templatesData, response.data];
    dispatch({
      type: GET_LETTER_TEMPLATES_SUCCESS,
      payload: { data: templatesData, totalItems: totalItems },
    });
    dispatch(showAlert('success', 'Success', 'Template successfully created!'));
    return { response: response.data };
  } catch (error) {
    dispatch(
      showAlert(
        'danger',
        'Error',
        error.message
      )
    );
    dispatch({ type: GET_TEMPLATES_FAILURE, payload: error });
  }
};


export const uploadFaxImage = (data) => async (dispatch) => {
  try {
    let uploadImage = await API.postRequest('/api/v1/external_upload/', data);
    dispatch(showAlert('success', 'Success', 'Image successfully uploaded!'));
    return { response: uploadImage.data };
  } catch (error) {
    dispatch(
      showAlert(
        'danger',
        'Error',
        error.message
      )
    );
  }
};


export const updateTemplate = (data,id) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_TEMPLATES_REQUEST });
    let response = await API.patchRequest(`/api/v1/sms_template/${id}/`, data);
    let templatesData = getState().radAdmin.templates.data;
    let totalItems = getState().radAdmin.templates.totalItems;
    templatesData =templatesData.map(itm=>itm.id ===id?({...response.data}):itm );
    dispatch(setTemplateIdToPreview(response.data.id));
    dispatch({
      type: GET_TEMPLATES_SUCCESS,
      payload: { data: templatesData, totalItems: totalItems },
    });
    dispatch(showAlert('success', 'Success', 'Template successfully updated!'));
    return { created: true };
  } catch (error) {
    dispatch(
      showAlert(
        'danger',
        'Error',
        error.message
      )
    );
    dispatch({ type: GET_TEMPLATES_FAILURE, payload: error });
  }
};


export const updateFaxTemplate = (data,id) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_FAX_TEMPLATES_REQUEST });
    let response = await API.patchRequest(`/api/v1/fax-template/${id}/`, data);
    let templatesData = getState().radAdmin.faxTemplates.data;
    let totalItems = getState().radAdmin.faxTemplates.totalItems;
    templatesData =templatesData.map(itm=>itm.id ===id?({...response.data}):itm );
    dispatch(setTemplateIdToPreview(response.data.id));
    dispatch({
      type: GET_FAX_TEMPLATES_SUCCESS,
      payload: { data: templatesData, totalItems: totalItems },
    });
    dispatch(showAlert('success', 'Success', 'Template successfully updated!'));
    return { created: true };
  } catch (error) {
    dispatch(
      showAlert(
        'danger',
        'Error',
        error.message
      )
    );
    dispatch({ type: GET_TEMPLATES_FAILURE, payload: error });
  }
};

export const updateLetterTemplate = (data,id) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_LETTER_TEMPLATES_REQUEST });
    let response = await API.patchRequest(`/api/v1/letter_template/${id}/`, data);
    let templatesData = getState().radAdmin.letterTemplates.data;
    let totalItems = getState().radAdmin.letterTemplates.totalItems;
    templatesData =templatesData.map(itm=>itm.id ===id?({...response.data}):itm );
    dispatch({
      type: GET_LETTER_TEMPLATES_SUCCESS,
      payload: { data: templatesData, totalItems: totalItems },
    });
    dispatch(showAlert('success', 'Success', 'Template successfully updated!'));
    return { created: true };
  } catch (error) {
    dispatch(
      showAlert(
        'danger',
        'Error',
        error.message
      )
    );
    dispatch({ type: GET_TEMPLATES_FAILURE, payload: error });
  }
};



export const setTemplateIdToPreview = (id) => async (dispatch) => {
  dispatch({ type: SET_TEMPLATE_ID_TO_PREVIEW, payload: id });
};
export const setTemplateToPreview = (text) => async (dispatch) => {
  dispatch({ type: SET_TEMPLATE_TO_PREVIEW, payload: text });
};

export const getTemplateToPreview =
  (patientId) => async (dispatch, getState) => {
    try {
      dispatch({ type: GET_TEMPLATES_REQUEST });
      let templateId = getState().radAdmin.templates.templateIdToPreview;
      let response = await API.getRequest(
        `/api/v1/sms_template/${templateId}/populate_for_patient/?patient_id=${patientId}`
      );
      dispatch(setTemplateToPreview(response.data.text));
      return { success: true };
    } catch (error) {
      dispatch(showAlert('danger', 'Error', error.message));
    }
  };

  export const setFaxTemplateIdToPreview = (id) => async (dispatch) => {
    dispatch({ type: SET_FAX_TEMPLATE_ID_TO_PREVIEW, payload: id });
  };
  export const setFaxTemplateToPreview = (text) => async (dispatch) => {
    dispatch({ type: SET_FAX_TEMPLATE_TO_PREVIEW, payload: text });
  };
  
  export const getFaxTemplateToPreview =
    (patientId) => async (dispatch, getState) => {
      try {
        dispatch({ type: GET_FAX_TEMPLATES_REQUEST });
        let templateId = getState().radAdmin.templates.templateIdToPreview;
        let response = await API.getRequest(
          `/api/v1/fax-template/${templateId}/populate_for_patient/?patient_id=${patientId}`
        );
        dispatch(setFaxTemplateToPreview(response.data.text));
        return { success: true };
      } catch (error) {
        dispatch(showAlert('danger', 'Error', error.message));
      }
    };

export const deleteTemplate =
  (idx, templatePageNo) => async (dispatch, getState) => {
    try {
      let response = await API.deleteRequest(`/api/v1/sms_template/${idx}/`);
      let templatesData = getState().radAdmin.templates.data;
      let totalItems = getState().radAdmin.templates.totalItems - 1;
      let requiredIndex = templatesData.findIndex((x) => x.id === idx);
      templatesData.splice(requiredIndex, 1);
      dispatch({
        type: GET_TEMPLATES_SUCCESS,
        payload: { data: templatesData, totalItems: totalItems },
      });
      dispatch(
        showAlert('success', 'Success', 'Template successfully deleted!')
      );
    } catch (error) {
      dispatch(showAlert('danger', 'Error', error.message));
    }
  };

  export const deleteFaxTemplate =
  (idx) => async (dispatch, getState) => {
    try {
      await API.deleteRequest(`/api/v1/fax-template/${idx}/`);
      let templatesData = getState().radAdmin.faxTemplates.data;
      let totalItems = getState().radAdmin.faxTemplates.totalItems - 1;
      let requiredIndex = templatesData.findIndex((x) => x.id === idx);
      templatesData.splice(requiredIndex, 1);
      dispatch({
        type: GET_FAX_TEMPLATES_SUCCESS,
        payload: { data: templatesData, totalItems: totalItems },
      });
      dispatch(
        showAlert('success', 'Success', 'Template successfully deleted!')
      );
    } catch (error) {
      dispatch(showAlert('danger', 'Error', error.message));
    }
  };

  export const deleteLetterTemplate =
  (idx) => async (dispatch, getState) => {
    try {
      await API.deleteRequest(`/api/v1/letter_template/${idx}/`);
      let templatesData = getState().radAdmin.letterTemplates.data;
      let totalItems = getState().radAdmin.letterTemplates.totalItems - 1;
      let requiredIndex = templatesData.findIndex((x) => x.id === idx);
      templatesData.splice(requiredIndex, 1);
      dispatch({
        type: GET_LETTER_TEMPLATES_SUCCESS,
        payload: { data: templatesData, totalItems: totalItems },
      });
      dispatch(
        showAlert('success', 'Success', 'Template successfully deleted!')
      );
    } catch (error) {
      dispatch(showAlert('danger', 'Error', error.message));
    }
  };



  export const getOutgoingMessages =
  (page, date = '', dateRanges = {},direction="", search="", status="") =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_OUTGOING_MESSAGES_REQUEST, payload: page });
    
      const dateFilter = date ? `created__gte=${date}&` : '';
      const dateRangesFilter =
        Object.keys(dateRanges).length !== 0
          ? `created__gte=${moment(dateRanges.startDate).format(
              'MM/DD/YYYY'
            )}&created__lte=${moment(dateRanges.endDate).format('MM/DD/YYYY')}&`
          : '';
      const dateFilterForAnalytics = dateFilter
        ? dateFilter
        :''

        const searchQuery = search ? `&search=${search}` :'';

      const directionFilter = direction === 'inbound and outbound'  || direction === ''?'': `direction=${direction}`;
      const statusFilter = status === 'all'  || status === ''?'': `&status=${status}`

      let { data: analyticsCounts } = await API.getRequest(
        `/api/v1/sms_message/aggregate/?${dateFilterForAnalytics}${dateRangesFilter}group_by=status&agg_func=count&${directionFilter}${statusFilter}${searchQuery}`
      );
      let { data: analyticsDataArray } = await API.getRequest(
        `/api/v1/sms_message/aggregate/?${dateFilterForAnalytics}${dateRangesFilter}group_by=status%2Ccreated&agg_func=count&${directionFilter}${statusFilter}&order_by=created${searchQuery}`
      );
      let requiredArray = [];
      const smsStatuses = {sent: 0, bounced: 0, delivered: 0, failed:0, clicked:0, converted:0, attempted:0, drafted:0, undeliverable:0 }

      analyticsDataArray.map((msg) => {
        let keyStatus = msg.status;
        let splitedDate = msg.created.split('T')[0];
        let obj = {
          ...msg,
          date: splitedDate,
          [keyStatus]: msg.id__count,
          ...(keyStatus === 'sent' && { ...smsStatuses, [keyStatus]:msg.id__count }),
          ...(keyStatus === 'bounced' && {...smsStatuses, [keyStatus]:msg.id__count }),
          ...(keyStatus === 'delivered' && {...smsStatuses, [keyStatus]:msg.id__count}),
          ...(keyStatus === 'failed' && {...smsStatuses,[keyStatus]:msg.id__count }),
          ...(keyStatus === 'clicked' && {...smsStatuses, [keyStatus]:msg.id__count}),
          ...(keyStatus === 'converted' && { ...smsStatuses, [keyStatus]:msg.id__count}),
          ...(keyStatus === 'attempted' && {...smsStatuses, [keyStatus]:msg.id__count }),
          ...(keyStatus === 'drafted' && {...smsStatuses, [keyStatus]:msg.id__count }),
          ...(keyStatus === 'undeliverable' && { ...smsStatuses, [keyStatus]:msg.id__count}),
        };
        if (isEmpty(requiredArray)) {
          requiredArray = [...requiredArray, obj];
        } else {
          let index = requiredArray.findIndex((x) => x.date === obj.date);
          if (index === -1) {
            requiredArray = [...requiredArray, obj];
          } else {
            let existingObj = requiredArray[index];
            if (obj.status === existingObj.status) {
              requiredArray[index] = {
                ...existingObj,
                id__count: existingObj.id__count + msg.id__count ,
              };
            } else {
              requiredArray[index] = {
                ...existingObj,
                [obj.status]: existingObj[obj.status] + 1,
              };
            }
          }
          requiredArray.find(
            (x) => x.date === obj.date && x.status === obj.status
          );
        }
      });
      requiredArray = requiredArray
        .map((data) => {
          let keyStatus = data.status;
          return {
            ...data,
            [keyStatus]: data.id__count,
            timestamp: new Date(data.date).getTime(),
          };
        })
    
      let response = await API.getRequest(
        `/api/v1/sms_message/?${dateFilter}${dateRangesFilter}${directionFilter}${statusFilter}&ordering=-created&page=${page}${searchQuery}`
      );
      let outgoingMessages = response.data.results;

      const patients = await Promise.all(
        outgoingMessages.map(({ patient_phone_number }) =>
          API.getRequest(
            `/api/v1/patient/?phone_number=${patient_phone_number || ''}`
          )
        )
      );
      const recommendations = await Promise.all(
        patients.map(
          (patient) =>
            !isEmpty(patient.data.results) &&
            API.getRequest(
              `/api/v1/recommendation/details?report__patient=${
                patient.data.results[0].id
              }&conditional=${false}&negated=${false}&user_rejected=${false}`
            )
        )
      );

      let requiredData = [];

      outgoingMessages.map((x, i) => {
        let MRN = patients[i].data?.results[0]?.mrn;
        requiredData = [
          ...requiredData,
          {
            ...x,
            created: moment(x.created.split('T')[0]).format('MM-DD-YYYY'),
            message_type: 'SMS',
            mrn: MRN || '--',
            accessionNumber:
              recommendations[i] && recommendations[i].data.results[0]
                ? recommendations[i].data.results[0].report.accession_number
                : '--',
            patientId:
              recommendations[i] && recommendations[i].data.results[0]
                ? recommendations[i].data.results[0].report.patient.id
                : null,
          },
        ];
      });
      dispatch({
        type: GET_OUTGOING_MESSAGES_SUCCESS,
        payload: {
          data: requiredData,
          totalItems: response.data.count,
          page,
          analyticsCounts,
          analyticsData: requiredArray,
        },
      });
    } catch (error) {
      dispatch(showAlert('danger', 'Error', error.message));
      dispatch({ type: GET_OUTGOING_MESSAGES_FAILURE, payload: error.message });
    }
  };


  export const getOutgoingEmails =
  (page, date = '', dateRanges = {},status="", search="") =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_OUTGOING_EMAIL_REQUEST, payload: page });
    
      const dateFilter = date ? `created__gte=${date}&` : '';
      const dateRangesFilter =
        Object.keys(dateRanges).length !== 0
          ? `created__gte=${moment(dateRanges.startDateEmail).format(
              'MM/DD/YYYY'
            )}&created__lte=${moment(dateRanges.endDateEmail).format('MM/DD/YYYY')}&`
          : '';
      const dateFilterForAnalytics = dateFilter
        ? dateFilter
        :'';

      const searchQuery = search? `&search=${search}`: ''

      const statusFilter = status === 'all'  || status === ''?'': `status=${status}`;
      let { data: analyticsCounts } = await API.getRequest(
        `/api/v1/email-records/aggregate/?${dateFilterForAnalytics}${dateRangesFilter}group_by=status&agg_func=count&${statusFilter}${searchQuery}`
      );
      let { data: analyticsDataArray } = await API.getRequest(
        `/api/v1/email-records/aggregate/?${dateFilterForAnalytics}${dateRangesFilter}group_by=status%2Ccreated&agg_func=count&${statusFilter}&order_by=created${searchQuery}`
      );
      let requiredArray = [];
      const emailStatuses = {sent: 0, bounced: 0, delivered: 0, spammed: 0, failed:0, dropped:0, unsubscribed:0, clicked:0, opened:0, converted:0, attempted:0 }
      analyticsDataArray.map((msg) => {
        let keyStatus = msg.status;
        let splitedDate = msg.created.split('T')[0];
        let obj = {
          ...msg,
          date: splitedDate,
          [keyStatus]: msg.id__count,
          ...(keyStatus === 'sent' && { ...emailStatuses, [keyStatus]:msg.id__count }),
          ...(keyStatus === 'bounced' && {...emailStatuses, [keyStatus]:msg.id__count }),
          ...(keyStatus === 'delivered' && {...emailStatuses, [keyStatus]:msg.id__count}),
          ...(keyStatus === 'spammed' && { ...emailStatuses, [keyStatus]:msg.id__count }),
          ...(keyStatus === 'failed' && {...emailStatuses,[keyStatus]:msg.id__count }),
          ...(keyStatus === 'dropped' && {...emailStatuses, [keyStatus]:msg.id__count}),
          ...(keyStatus === 'unsubscribed' && { ...emailStatuses, [keyStatus]:msg.id__count}),
          ...(keyStatus === 'clicked' && {...emailStatuses, [keyStatus]:msg.id__count }),
          ...(keyStatus === 'opened' && {...emailStatuses, [keyStatus]:msg.id__count }),
          ...(keyStatus === 'converted' && { ...emailStatuses, [keyStatus]:msg.id__count}),
          ...(keyStatus === 'attempted' && { ...emailStatuses, [keyStatus]:msg.id__count}),

        };
        if (isEmpty(requiredArray)) {
          requiredArray = [...requiredArray, obj];
        } else {
          let index = requiredArray.findIndex((x) => x.date === obj.date);
          if (index === -1) {
            requiredArray = [...requiredArray, obj];
          } else {
            let existingObj = requiredArray[index];
            if (obj.status === existingObj.status) {
              requiredArray[index] = {
                ...existingObj,
                id__count: existingObj.id__count +  msg.id__count,
              };
            } else {
              requiredArray[index] = {
                ...existingObj,
                [obj.status]: existingObj[obj.status] + 1,
              };
            }
          }
          requiredArray.find(
            (x) => x.date === obj.date && x.status === obj.status
          );
        }
      });
      requiredArray = requiredArray
        .map((data) => {
          let keyStatus = data.status;
          return {
            ...data,
            [keyStatus]: data.id__count,
            timestamp: new Date(data.date).getTime(),
          };
        })
      let response = await API.getRequest(
        `/api/v1/email-records/?${dateFilter}${dateRangesFilter}${statusFilter}&ordering=-created&page=${page}${searchQuery}`
      );
      let outgoingMessages = response.data.results;

      const patients = await Promise.all(
        outgoingMessages.map(({ patient }) =>
          API.getRequest(
            `/api/v1/patient/${patient}/`
          )
        )
      );

      let requiredData = [];

      outgoingMessages.map((x, i) => {
        let MRN = patients[i].data?.mrn;
        let EMAIL = patients[i].data?.email;
        let PATIENTID = patients[i].data?.id

        requiredData = [
          ...requiredData,
          {
            ...x,
            created: moment(x.created.split('T')[0]).format('MM-DD-YYYY'),
            message_type: 'Email',
            mrn: MRN || '--',
            email :EMAIL || '',
            patientId: PATIENTID || ''
          },
        ];
      });

      dispatch({
        type: GET_OUTGOING_EMAIL_SUCCESS,
        payload: {
          data: requiredData,
          totalItems: response.data.count,
          page,
          analyticsCounts,
          analyticsData: requiredArray,
        },
      });
    } catch (error) {
      dispatch(showAlert('danger', 'Error', error.message));
      dispatch({ type: GET_OUTGOING_EMAIL_FAILURE, payload: error.message });
    }
  };

  export const getOutgoingFaxes =
  (page, date = '', dateRanges = {},status="") =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_OUTGOING_FAXES_REQUEST, payload: page });
    
      const dateFilter = date ? `created__gte=${date}&` : '';
      const dateRangesFilter =
        Object.keys(dateRanges).length !== 0
          ? `created__gte=${moment(dateRanges.startDateFaxes).format(
              'MM/DD/YYYY'
            )}&created__lte=${moment(dateRanges.endDateFaxes).format('MM/DD/YYYY')}&`
          : '';
      const dateFilterForAnalytics = dateFilter
        ? dateFilter
        :''

      const statusFilter = status === 'all'  || status === ''?'': `status=${status}`
      let { data: analyticsCounts } = await API.getRequest(
        `/api/v1/fax-records/aggregate/?${dateFilterForAnalytics}${dateRangesFilter}group_by=status&agg_func=count&${statusFilter}`
      );
      let { data: analyticsDataArray } = await API.getRequest(
        `/api/v1/fax-records/aggregate/?${dateFilterForAnalytics}${dateRangesFilter}group_by=status%2Ccreated&agg_func=count&${statusFilter}&order_by=created`
      );
      let requiredArray = [];
      const emailStatuses = {success: 0, failed:0, cancelled:0, processing:0, transmitting:0 }
      analyticsDataArray.map((msg) => {
        let keyStatus = msg.status;
        let splitedDate = msg.created.split('T')[0];
        let obj = {
          ...msg,
          date: splitedDate,
          [keyStatus]: msg.id__count,
          ...(keyStatus === 'success' && { ...emailStatuses, [keyStatus]:msg.id__count }),
          ...(keyStatus === 'failed' && {...emailStatuses,[keyStatus]:msg.id__count }),
          ...(keyStatus === 'cancelled' && {...emailStatuses, [keyStatus]:msg.id__count}),
          ...(keyStatus === 'processing' && { ...emailStatuses, [keyStatus]:msg.id__count}),
          ...(keyStatus === 'transmitting' && {...emailStatuses, [keyStatus]:msg.id__count })
        };
        if (isEmpty(requiredArray)) {
          requiredArray = [...requiredArray, obj];
        } else {
          let index = requiredArray.findIndex((x) => x.date === obj.date);
          if (index === -1) {
            requiredArray = [...requiredArray, obj];
          } else {
            let existingObj = requiredArray[index];
            if (obj.status === existingObj.status) {
              requiredArray[index] = {
                ...existingObj,
                id__count: existingObj.id__count +  msg.id__count,
              };
            } else {
              requiredArray[index] = {
                ...existingObj,
                [obj.status]: existingObj[obj.status] + 1,
              };
            }
          }
          requiredArray.find(
            (x) => x.date === obj.date && x.status === obj.status
          );
        }
      });
      requiredArray = requiredArray
        .map((data) => {
          let keyStatus = data.status;
          return {
            ...data,
            [keyStatus]: data.id__count,
            timestamp: new Date(data.date).getTime(),
          };
        })
      let response = await API.getRequest(
        `/api/v1/fax-records/?${dateFilter}${dateRangesFilter}${statusFilter}&ordering=-created&page=${page}`
      );
      let outgoingMessages = response.data.results;

      const physician = await Promise.all(
        outgoingMessages.map(({ referring_physician }) =>
          API.getRequest(
            `/api/v1/physician/${referring_physician}/`
          )
        )
      );

      let requiredData = [];

      outgoingMessages.map((x, i) => {
        let fullName = physician[i].data?.first_name + ' ' + physician[i].data?.last_name
        let uniqueId = physician[i].data?.unique_id
        let referrerFax = physician[i].data?.fax

        requiredData = [
          ...requiredData,
          {
            ...x,
            id: x.id,
            created: moment(x.created.split('T')[0]).format('MM-DD-YYYY'),
            message_type: 'Fax',
            referrer_full_name:fullName,
            referrer_unique_id: uniqueId,
            referrer_fax:referrerFax
          },
        ];
      });

      dispatch({
        type: GET_OUTGOING_FAXES_SUCCESS,
        payload: {
          data: requiredData,
          totalItems: response.data.count,
          page,
          analyticsCounts,
          analyticsData: requiredArray,
        },
      });
    } catch (error) {
      dispatch(showAlert('danger', 'Error', error.message));
      dispatch({ type: GET_OUTGOING_FAXES_FAILURE, payload: error.message });
    }
  };



  export const getSearchReportText = (page=1, titleForSearch, excludeSearch="", filters={}) => async (dispatch) => {
    try {

    let examCodeFilter = '';
    let locationFilter = '';
    let messageSentFilter = '';
    let radiologistFilter = '';
    let referrerFilter = '';
    let modalityFilter = '';
    let examDateFilter = '';
     if(filters?.exam_code?.length>0){
      if(filters?.examCodeNegation?.value ==='isNotEqual'){
        examCodeFilter ='&' + filters.exam_code.map(itm=>`exam_code_ne=${itm.value}`).join('&')
        }
      else{
      examCodeFilter = '&' + filters.exam_code.map(itm=>`exam_code=${itm.value}`).join('&')
      }
     }
    if(filters?.location?.length>0){
     locationFilter = '&' + filters.location.map(itm=>`location_address=${itm.value}`).join('&')
    }
    if(filters?.message_sent?.length>0){
     messageSentFilter = '&' + filters.message_sent.map(itm=>`message_sent=${itm.value}`).join('&')
    }
    if(filters?.radiologist?.length>0){
     radiologistFilter = '&' + filters.radiologist.map(itm=>`radiologist_id=${itm.value}`).join('&')
    }
    if(filters?.referrers?.length>0){
     referrerFilter = '&' + filters.referrers.map(itm=>`referring_physician_id=${itm.value}`).join('&')
    }
    if(filters?.report_modality?.length>0){
      if(filters?.modalityNegation?.value ==='isNotEqual'){
        modalityFilter = '&' + filters.report_modality.map(itm=>`modality_ne=${itm.value}`).join('&')
      }else{
      modalityFilter = '&' + filters.report_modality.map(itm=>`modality=${itm.value}`).join('&')
      }
    }

    if(filters?.exam_date?.length>0){
    examDateFilter = "&" + filters.exam_date.map(itm=>`initial_exam_date__gte=${moment(itm.startDate).format('YYYY-MM-DD')}&initial_exam_date__lte=${moment(itm.endDate).format('YYYY-MM-DD')}`).join('&')
    }
    

      dispatch({ type: GET_SEARCH_REPORT_TEXT_REQUEST });
      const searchQuery = titleForSearch ? `&search=${titleForSearch}` : '';
      const excludeQuery = excludeSearch? `&neg_search=${excludeSearch}` : '';
      const { data } = await API.getRequest(
        `/api/v1/report2_open_search/?${page ? `page=${page}` : ''}${searchQuery}${excludeQuery}${examCodeFilter}${locationFilter}${messageSentFilter}${radiologistFilter}${referrerFilter}${modalityFilter}${examDateFilter}`
      );
     
    let responseData;
    let totalItems;

  
    responseData = data.results.map(itm=>({...itm, initial_exam_date: moment(itm.initial_exam_date).format('MM-DD-YYYY')}));
    totalItems = data.count;

      dispatch({
        type: GET_SEARCH_REPORT_TEXT_SUCCESS,
        payload: { items: responseData,
          totalItems: totalItems,
          next: responseData.next,
          pageNumber: page, },
      });
    } catch (error) {
      dispatch(showAlert('danger', 'Error', error.message));
      dispatch({ type: GET_SEARCH_REPORT_TEXT_FAILURE, payload: error });
    }
  };
  

  export const saveReportSearchText = (data, excludeSearch,filterSetName, filters) => async (dispatch) => {
    try {
      const payload = { search_text: data, name: filterSetName }
      if(excludeSearch){
        payload.neg_search_text = excludeSearch
      }
      if(filters.length>0){
        payload.filters = filters
      }
      
      await API.postRequest("/api/v1/report2_searches/", payload );
      dispatch(
        showAlert('success', 'Success', 'Report text saved successfully!')
      );
    } catch (error) {
      dispatch(showAlert("danger", "Error", error.message));
    }
  };
  
  export const getSavedSearchesData = (page) => async (dispatch) => {
    try {
      const { data } = await API.getRequest(
        `/api/v1/report2_searches/?page=${page}`
      );
      return data;
    } catch (error) {
      dispatch(showAlert("danger", "Error", error.message));
    }
  };
  
  export const getRefName = (id) => async (dispatch) => {
    try {
      const { data } = await API.getRequest(
        `/api/v1/physician/${id}/`
      );
      return data;
    } catch (error) {
      dispatch(showAlert("danger", "Error", error.message));
    }
  };

  export const getRadiologistName = (id) => async (dispatch) => {
    try {
      const { data } = await API.getRequest(
        `/api/v1/radiologist/${id}/`
      );
      return data;
    } catch (error) {
      dispatch(showAlert("danger", "Error", error.message));
    }
  };


  export const deleteSavedSearchesData = (id) => async (dispatch) => {
    try {
      const { data } = await API.deleteRequest(
        `/api/v1/report2_searches/${id}`
      );
      dispatch(
        showAlert('success', 'Success', 'Saved search deleted successfully!')
      );
    } catch (error) {
      dispatch(showAlert("danger", "Error", error.message));
    }
  };

  export const updateSavedSearchesData = ({id, name}) => async (dispatch) => {
    try {
      const payload = { name: name}
      const { data } = await API.patchRequest(
        `/api/v1/report2_searches/${id}`, payload
      );
      dispatch(
        showAlert('success', 'Success', 'Saved search updated successfully!')
      );
    } catch (error) {
      dispatch(showAlert("danger", "Error", error.message));
    }
  };
  


export const getAdherenceOfToday = (filters) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ADHERENCE_OF_TODAY_REQUEST
    });
    const followUpDoc = filters['radiologist'].length>0
      ? filters['radiologist'].map(itm=> `&report__radiologist__id=${itm.value}`).join('')
      : '';
    const followUpRefDoc = filters['referrers'].length>0
      ? filters['referrers'].map(itm => `&report__referring_physician=${itm.value}`).join("")
      : '';
    
      const followUpModality = filters.modality.length>0? filters.modality.map(itm=>modalityFilter(
        itm,
        filters?.modalityOperator
      )).join('') :''
    const followUpAnatomy = filters.anatomy.length>0? filters.anatomy.map(itm => anatomyFilter(
      itm,
      filters?.anatomyOperator
    )).join(''):''
  
    const followUpTimeframeStatus = filters['timeframe'].length>0
      ? filters['timeframe'].map(itm=> `&timeframe_status_extracted=${itm.value}`).join('')
      : '';
     const ageFilter = filters['age'].length>0
      ? filters.age.map(itm=>`&patient_age_group=${itm.value}`).join('')
      : '';
    // const followUpAgeOptions = filters['age'].length>0
    //   ? `&report__patient__date_of_birth__gte=${startDate}&report__patient__date_of_birth__lte=${endDate}`
    //   : '';
      const notesFilter = filters['has_note'].length>0
      ? `&has_note=${filters['has_note'][0].value}`
      : '';

    const messageSentFilter = filters['message_sent'].length>0
      ? `&message_sent=${filters['message_sent'][0].value}`
      : '';
      const locationFilter = filters['location'].length>0
      ? filters.location.map(itm=>`&location_address=${itm.value}`).join('')
      : '';

      let reportModalityQuery = ''
      if(filters?.reportModalityOperator?.value ==='isNotEqual'){
           reportModalityQuery = '&report_modality_ne'
      }else{
          reportModalityQuery = '&report__modality'
      }

      const reportModality = filters['report_modality'].length>0 ? filters['report_modality'].map(itm=>`${reportModalityQuery}=${itm.value}`).join('') 
      : '';
    let response = await API.getRequest(
      `/api/v1/recommendation/aggregate/?agg_func=count&distinct=false&group_by=status_category${followUpDoc}${followUpRefDoc}${followUpModality}${followUpAnatomy}${followUpTimeframeStatus}${notesFilter}${messageSentFilter}${locationFilter}${reportModality}${ageFilter}`
    );
    dispatch({
      type: GET_ADHERENCE_OF_TODAY_SUCCESS,
      payload: { data: response.data },
    });
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_ADHERENCE_OF_TODAY_FAILURE, payload: error.message });
  }
};

export const getFollowUpListFromMessages =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: GET_FOLLOWUP_LIST_FROM_MESSAGES_REQUEST });

      await dispatch(getRecoConfig());
      const conditional = getState().radAdmin.recoConfig.data?.conditional;
      const negated = getState().radAdmin.recoConfig.data?.negated;
      const { data } = await API.getRequest(
        `/api/v1/recommendation/details/?${
          !conditional ? `&conditional=${conditional}` : ''
        }${
          !negated ? `&negated=${negated}` : ''
        }&user_rejected=${false}&report__patient=${id}`
      );
      dispatch({
        type: GET_FOLLOWUP_LIST_FROM_MESSAGES_SUCCESS,
        payload: data.results,
      });
    } catch (error) {
      dispatch(showAlert('danger', 'Error', error.message));
    }
  };

export const toggleConversation = (id, data, query, phoneClosed,currentTab='active') => async (dispatch, getState) => {
  try {
    await API.putRequest(`/api/v1/patient/${id}/`, data);
    let recentMessages = getState().radAdmin.messages.recentMessages.data;
    let usersInfo = getState().radAdmin.messages.usersInfo.data;
    let requiredIndex = recentMessages.findIndex(
      (x) => x?.patient_phone_number === data.phone_number
    );
    let requiredIndexUserInfo = usersInfo.findIndex(
      (x) => x?.phone_number === data.phone_number
    );
    recentMessages.splice(requiredIndex, 1);
    usersInfo.splice(requiredIndexUserInfo, 1);
    dispatch(getRecentIncomingMessages(0, query, phoneClosed,1,currentTab));
    dispatch({
      type: GET_RECENT_INCOMING_MESSAGES_UPDATE_SUCCESS,
      payload: recentMessages,
    });
    dispatch({ type: SET_USERS_MESSAGES_INFO, payload: usersInfo });
    dispatch(showAlert('success', 'Success', phoneClosed ? 'Successfully opened!': 'Successfully closed!'));
    return {
      updated: true,
    };
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: UPDATE_NOTE_FAILURE, payload: error });
  }
};

export const getCohorts = (page, titleForSearch) => async (dispatch) => {
  try {
    dispatch({ type: GET_COHORTS_REQUEST });
    const Filter = titleForSearch ? `&cohort_title=${titleForSearch}` : '';
    const { data } = await API.getRequest(
      `/api/v1/cohort/?${page ? `page=${page}` : ''}${Filter}&ordering=-id`
    );
  
    let requiredData = data.results.map((item, i) => {
      let description = item.raw_filters.filters?.map(itm=>{
        let filter = itm;
        let dimension = (itm?.model ==='Report2' ? 'Report' : itm?.model) || '';
        let attribute = itm?.attribute || '';
        let operator =
          filter?.operator === '__gt'
            ? 'Is a timestamp after'
            : filter?.operator === '__icontains'&& itm.exclude ===1?
            'Does not contain'
            : filter?.operator === '__icontains'&& itm.exclude === 0 
            ? 'Contains'
            : filter?.operator === '__lt'
            ? 'Is a timestamp before'
            : filter?.operator === '__lte'
            ? 'Is a timestamp less than'
            : filter?.operator === '__gte'
            ? 'Is a timestamp greater than'
            : itm.exclude===1? 'Is not equal to' : 'Is equal to';
        let value = filter?.value || '';
       return dimension + ' ' + attribute + ' ' + operator + ' ' + value
      }).join(" " +item.raw_filters.condition.toUpperCase() + " ")
    
      return {
        ...item,
        description:description,
      };
    });
    dispatch({
      type: GET_COHORTS_SUCCESS,
      payload: { data: requiredData, totalItems: data.count, page },
    });
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_COHORTS_FAILURE, payload: error });
  }
};

export const getCohortWithId = (idx) => async (dispatch) => {
  try {
    dispatch({ type: GET_COHORT_WITH_ID_REQUEST });
    const { data } = await API.getRequest(`/api/v1/cohort/${idx}`);

    let filter = data?.raw_filters[0]?.filters[0];
    let dimension = data?.raw_filters[0]?.filters[0]?.model || '';
    let attribute = data?.raw_filters[0]?.filters[0]?.attribute || '';
    let operator =
      filter?.operator === '__gt'
        ? 'Is a timestamp after'
        : filter?.operator === '__icontains'
        ? 'Contains'
        : filter?.operator === '__lt'
        ? 'Is a timestamp before'
        : 'Is equal to';
    let value = filter?.value || '';

    const cohortFilters = data?.raw_filters?.filters

    let requiredData = {
      ...data,
      cohort_filters: cohortFilters,
      description: dimension + ' ' + attribute + ' ' + operator + ' ' + value,
    };
    dispatch({ type: GET_COHORT_WITH_ID_SUCCESS, payload: requiredData });
    return { payload : requiredData}
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_COHORT_WITH_ID_FAILURE, payload: error });
  }
};

export const getDimention = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_DIMENTION_REQUEST });
    const { data } = await API.getRequest(`/api/v1/dimension/${id}/`);
    dispatch({ type: GET_DIMENTION_SUCCESS, payload: { data: data } });
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_DIMENTION_FAILURE, payload: error });
  }
};

export const getAttributesForDimension = (model) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_DIMENTIONS_REQUEST });
    const { data } = await API.getRequest(`/api/v1/dimension/?model=${model}&page_size=100`);
    dispatch({
      type: GET_ALL_DIMENTIONS_SUCCESS,
      payload: { data: data.results },
    });
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_ALL_DIMENTIONS_FAILURE, payload: error });
  }
};

export const getPatientsInCohort = (id, page) => async (dispatch) => {
  try {
    dispatch({ type: GET_PATIENT_IN_COHORT_REQUEST, payload: page });
    let response = await API.getRequest(
      `/api/v1/patient/?patients_in_cohort__id=${id}&page=${page}`
    );
    dispatch({
      type: GET_PATIENT_IN_COHORT_SUCCESS,
      payload: {
        data: response.data.results,
        totalItems: response.data.count,
        page,
      },
    });
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_PATIENT_IN_COHORT_FAILURE, payload: error });
  }
};

export const updateCohortPatients = (id) => async (dispatch) => {
  try {
    let response = await API.postRequest(
      `/api/v1/cohort/${id}/refresh/`
    );
    return response
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
  }
};

export const getPatientById = (idx) => async (dispatch) => {
  try {
    dispatch({ type: GET_PATIENT_BY_ID_REQUEST });
    let response = await API.getRequest(`/api/v1/patient/${idx}`);
    dispatch({ type: GET_PATIENT_BY_ID_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_PATIENT_BY_ID_FAILURE, payload: error });
  }
};

export const updatePatientDetails =
  (
    id,
    {
      selectedLanguage,
      selectedGender,
      selectedPhoneClosed,
      selectedPhoneUnsubcribed,
      selectedPatientEmail,
      selectedPatientPhoneNumber,
    }
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_RECO_BY_PATIENT_ID_REQUEST });

      await API.patchRequest(`/api/v1/patient/${id}/`, {
        language_preference: selectedLanguage?.value,
        gender: selectedGender?.value,
        phone_closed: selectedPhoneClosed?.value,
        phone_unsubscribed: selectedPhoneUnsubcribed?.value,
        email: selectedPatientEmail,
        phone_number: selectedPatientPhoneNumber,
      });
      const patientRecosRow = getState().radAdmin.patientDetails.recommendations?.data
  
      dispatch(
        showAlert(
          "success",
          "Success",
          "Patient Details Updated Successfully!"
        )
      );
      dispatch({
        type: UPDATE_RECO_BY_PATIENT_ID_SUCCESS,
        payload: patientRecosRow.map(itm=>({...itm,patient:{...itm.patient,   language_preference: selectedLanguage?.value,
          gender: selectedGender?.value,
          phone_closed: selectedPhoneClosed?.value,
          phone_unsubscribed: selectedPhoneUnsubcribed?.value,
          email: selectedPatientEmail,
          phone_number: selectedPatientPhoneNumber} })),
      });
  
      
    } catch (error) {
      dispatch(showAlert("danger", "Error", error.message));
      dispatch({ type: UPDATE_RECO_BY_PATIENT_ID_FAILURE, payload: error.message });

    }
  };


export const getPatientByMrn = (mrn) => async (dispatch) => {
  try {
    let {data} = await API.getRequest(`/api/v1/patient/?mrn=${mrn}`);
    return data.results[0]
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
    throw error;
  }
};


export const getAppointmentByPatientId = (patientId, page=1) => async (dispatch) => {
  try {
    dispatch({ type: GET_APPOINTMENT_BY_PATIENT_ID_REQUEST });
    let response = await API.getRequest(
      `/api/v1/appointment/?patient=${patientId}&page=${page}`
    );
    let data = response.data.results.map((result) => {
      return {
        ...result,
        initial_exam_date: moment(result.initial_exam_date).format(
          'YYYY-MM-DD'
        ),
      };
    });
    dispatch({ type: GET_APPOINTMENT_BY_PATIENT_ID_SUCCESS, payload: data,  page, totalItems: response.data.count });
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_APPOINTMENT_BY_PATIENT_ID_FAILURE, payload: error });
  }
};

export const getReportByPatientId = (patientId, page=1) => async (dispatch) => {
  try {
    dispatch({ type: GET_REPORT_BY_PATIENT_ID_REQUEST });
    let response = await API.getRequest(
      `/api/v1/report2/details/?ordering=-initial_exam_date&patient=${parseInt(patientId)}&page=${page}`
    );
    let data = response.data.results.map((result) => {
      return {
        ...result,
        initialExamDate: moment(result.initial_exam_date).format('YYYY-MM-DD'),
        reportText: result.report_text,
        accessionNumber: result.accession_number,
      };
    });
    dispatch({ type: GET_REPORT_BY_PATIENT_ID_SUCCESS, payload: data, page, totalItems: response.data.count });
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_REPORT_BY_PATIENT_ID_FAILURE, payload: error });
  }
};


export const getPrescriptionByPatientId = (patientId,page=1) => async (dispatch) => {
  try {
    dispatch({ type: GET_PRESCRIPTIONS_BY_PATIENT_ID_REQUEST });
  
    let {data} = await API.getRequest(
      `/api/v1/prescription/?patient=${parseInt(patientId)}&page=${page}`
    );
    let referringPhysiciansData = await Promise.all(
      data.results.filter(item => item.referring_physician !== null).map((item) =>
        API.getRequest(`/api/v1/physician/${item.referring_physician}/`)
      )
    );
    
    const prescriptionData = data.results.map(prescription => {
      const referringPhysician = referringPhysiciansData.find(physician => physician.data.id === prescription.referring_physician);
      const referring_physician_name = referringPhysician ? referringPhysician.data.first_name + ' ' + referringPhysician.data.last_name : "";

      return { ...prescription, referring_physician: referring_physician_name, ordered_time: moment(prescription.ordered_time).format('YYYY-MM-DD') };
    });
    dispatch({ type: GET_PRESCRIPTIONS_BY_PATIENT_ID_SUCCESS, payload:  { data: prescriptionData, totalItems: data.count, page }});

  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_PRESCRIPTIONS_BY_PATIENT_ID_FAILURE, payload: error });
  }
};


export const getEmailsByPatientId = (patientId,page=1) => async (dispatch) => {
  try {
    dispatch({ type: GET_EMAILS_BY_PATIENT_ID_REQUEST });
  
    let {data} = await API.getRequest(
      `/api/v1/email-records/?patient=${parseInt(patientId)}&page=${page}`
    );

    let resData = data.results.map((sms) => {
      return {
        ...sms,
        created: `${moment(sms.created).format('MM-DD-YYYY')} ${moment(
          sms.created
        ).format('LTS')}`,
      };
    });
    dispatch({
      type: GET_EMAILS_BY_PATIENT_ID_SUCCESS,
      payload: { data:resData, totalItems: data.count, page },
    });
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_EMAILS_BY_PATIENT_ID_FAILURE, payload: error });
  }
};

export const getCohortsByPatientId = (patientId,page=1) => async (dispatch) => {
  try {
    dispatch({ type: GET_COHORTS_BY_PATIENT_ID_REQUEST });
    let response = await API.getRequest(
      `/api/v1/cohort/?patients=${patientId}&ordering=-id`
    );

      
    let requiredData = response.data.results.map((item, i) => {
      let description = item.raw_filters.filters?.map(itm=>{
        let filter = itm;
        let dimension = (itm?.model ==='Report2' ? 'Report' : itm?.model) || '';
        let attribute = itm?.attribute || '';
        let operator =
          filter?.operator === '__gt'
            ? 'Is a timestamp after'
            : filter?.operator === '__icontains' && itm.exclude ===1
            ? 'Does not contain'
            :filter?.operator === '__icontains' && itm.exclude ===0
            ? 'Contains'
            : filter?.operator === '__lt'
            ? 'Is a timestamp before'
            : filter?.operator === '__lte'
            ? 'Is a timestamp less than'
            : filter?.operator === '__gte'
            ? 'Is a timestamp greater than'
            : itm.exclude ===1?'Is not equal to'  :'Is equal to';
        let value = filter?.value || '';
       return dimension + ' ' + attribute + ' ' + operator + ' ' + value
      }).join(" " +item.raw_filters.condition.toUpperCase() + " ")
    
      return {
        ...item,
        description:description,
      };
    });
   
    dispatch({ type: GET_COHORTS_BY_PATIENT_ID_SUCCESS, payload: { data:requiredData, totalItems: response.data.count, page }    });
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_COHORTS_BY_PATIENT_ID_FAILURE, payload: error });
  }
};

export const getRecommendationByPatientId =
  (patientId, page=1) => async (dispatch, getState) => {
    try {
      dispatch({ type: GET_RECO_BY_PATIENT_ID_REQUEST });

      await dispatch(getRecoConfig());
      const conditional = getState().radAdmin.recoConfig.data?.conditional;
      const negated = getState().radAdmin.recoConfig.data?.negated;

      let response = await API.getRequest(
        `/api/v1/recommendation/details/?report__patient=${parseInt(
          patientId
        )}${!conditional ? `&conditional=${conditional}` : ''}${
          !negated ? `&negated=${negated}` : ''
        }&user_rejected=${false}&page=${page}`
      );

      
      let aggregateCount = await API.getRequest(`/api/v1/recommendation/aggregate/?agg_func=count&report__patient=${parseInt(
        patientId
      )}${!conditional ? `&conditional=${conditional}` : ''}${
        !negated ? `&negated=${negated}` : ''
      }&user_rejected=${false}`)


      const data = response.data.results.map(
        ({
          id,
          status,
          range_start,
          range_end,
          report,
          modality,
          anatomy,
          user_rejected,
          relevant_blob,
          report__modality,
          report_initial_exam_date,
          conditional,
          timeframe_status_extracted,
          nlp_modality,
          implied_modality,
          guideline_type,
          exam_type,
          recommendation_type,
          contrast
        },idx) => ({
          id,
          status,
          rangeStart: moment(range_start).format('YYYY-MM-DD'),
          rangeEnd: moment(range_end).format('YYYY-MM-DD'),
          user_rejected,
          accessionNumber: report.accession_number,
          modality : modality || nlp_modality || implied_modality,
          initialExamDate: moment(report.initial_exam_date).format(
            'YYYY-MM-DD'
          ),
          anatomy,
          patientId: report.patient.id,
          patient: report.patient,
          radiologist: report.radiologist,
          referring_physician: report.referring_physician,
          patientMRN: report.patient_mrn || report.patient.mrn,
          reportText: report.report_text,
          report__modality: report.modality,
          relevantBlob: relevant_blob,
          conditional: conditional,
          timeframe_status_extracted: timeframe_status_extracted,
          examCode:  report.exam_code ,
          guideline_type: guideline_type,
          examType: exam_type,
          recommendationType: recommendation_type,
          contrast:contrast
        })
      );
      dispatch({ type: GET_RECO_BY_PATIENT_ID_SUCCESS, payload: data, page,  totalItems: aggregateCount.data.id__count });
    } catch (error) {
      dispatch(showAlert('danger', 'Error', error.message));
      dispatch({ type: GET_RECO_BY_PATIENT_ID_FAILURE, payload: error });
    }
  };

export const getMessagesByPatientPhoneNumber =
  (phoneNumber, page) => async (dispatch) => {
    try {
      dispatch({ type: GET_MESSAGES_BY_PATIENT_PHONE_REQUEST });
      let response = await API.getRequest(
        `/api/v1/sms_message/?patient_phone_number=${phoneNumber}&page=${page}`
      );
      let data = response.data.results.map((sms) => {
        return {
          ...sms,
          created: `${moment(sms.created).format('MM-DD-YYYY')} ${moment(
            sms.created
          ).format('LTS')}`,
        };
      });
      dispatch({
        type: GET_MESSAGES_BY_PATIENT_PHONE_SUCCESS,
        payload: { data, totalItems: response.data.count, page },
      });
    } catch (error) {
      dispatch(showAlert('danger', 'Error', error.message));
      dispatch({ type: GET_MESSAGES_BY_PATIENT_PHONE_FAILURE, payload: error });
    }
  };

export const createCohort = (data) => async (dispatch) => {
  try {
    dispatch({ type: GET_COHORTS_REQUEST });
    let response = await API.postRequest(`/api/v1/cohort/`, data);
    dispatch(getCohorts(1));
    dispatch({ type: CREATE_COHORT_SUCCESS, payload: { data: response.data } });
    dispatch(showAlert('success', 'Success', 'Successfully Created!'));
    return { success: true };
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_COHORTS_FAILURE, payload: error });
  }
};

export const updateCohort = (idx, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_COHORTS_REQUEST });
    await API.putRequest(`/api/v1/cohort/${idx}/`, data);
    let cohorts = getState().radAdmin.cohorts.data;
    let requiredIndex = cohorts.findIndex((x) => x.id === idx);
    let description =  data.raw_filters.filters.map(itm => {
      let dimension = (itm?.model ==='Report2' ? 'Report' : itm?.model) || '';
      let attribute = itm?.attribute || '';
      let operator = null;
      if (itm?.operator) {
        if (itm?.operator === '__gt') {
          operator = 'Is a timestamp after';
        } else if (itm?.operator === '__icontains' && itm?.exclude ===1) {
          operator = 'Does not contain';
        } else if (itm?.operator === '__icontains' && itm?.exclude ===0) {
          operator = 'Contains';
        }  
        else {
          operator = 'Is a timestamp before';
        }
      } else if (itm?.exclude) {
        operator = 'Is not equal to';
      } else {
        operator = 'Is equal to';
      }
      let value = itm?.value || '';
    return dimension + ' ' + attribute + ' ' + operator + ' ' + value}).join(" "+data?.raw_filters.condition.toUpperCase() + " ")
  
    cohorts[requiredIndex] = {
      ...data,
      id: idx,
      description: description
    };
    dispatch({ type: CREATE_COHORT_SUCCESS, payload: { data: cohorts } });
    dispatch(showAlert('success', 'Success', 'Successfully updated!'));
    return { success: true };
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_COHORTS_FAILURE, payload: error });
  }
};

export const deleteCohort = (idx) => async (dispatch, getState) => {
  try {
    await API.deleteRequest(`/api/v1/cohort/${idx}/`);
    let cohorts = getState().radAdmin.cohorts.data;
    let totalItems = getState().radAdmin.cohorts.totalItems - 1;
    let requiredIndex = cohorts.findIndex((x) => x.id === idx);
    cohorts.splice(requiredIndex, 1);
    dispatch({
      type: GET_COHORTS_SUCCESS,
      payload: { data: cohorts, totalItems },
    });
    dispatch(showAlert('success', 'Success', 'Successfully deleted!'));
    return { success: true };
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_COHORTS_FAILURE, payload: error });
  }
};

export const getDimensionsToEdit = (filters) => async (dispatch) => {
  try {
    dispatch({ type: GET_DIMENSIONS_TO_EDIT_REQUEST });
    const response = await Promise.all(
      filters.map(({ dimension }) =>
        API.getRequest(`/api/v1/dimension/${dimension}`)
      )
    );
    let dimensions = response.map((item) => item.data);
    dispatch({ type: GET_DIMENSIONS_TO_EDIT_SUCCESS, payload: dimensions });
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_DIMENSIONS_TO_EDIT_FAILURE, payload: error });
  }
};

export const setDimensionsToInitial = () => async (dispatch) => {
  dispatch({ type: SET_DIMENSIONS_TO_INITIAL });
};

export const getCampaigns = (page) => async (dispatch) => {
  try {
    dispatch({ type: GET_CAMPAIGNS_REQUEST });
    let response = await API.getRequest(`/api/v1/campaigns/?page=${page}`);
    dispatch({
      type: GET_CAMPAIGNS_REQUEST_SUCCESS,
      payload: {
        data: response.data.results,
        totalItems: response.data.count,
        page,
      },
    });
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_CAMPAIGNS_REQUEST_FAILURE, payload: error });
  }
};

export const deleteCampaignStep = (selectedStep) => {
  return async (dispatch, getState) => {
    try {
      const enableMoreStepsDemo = getState().constants.featureFlags.find(
        (flag) => flag.name === 'enable-more-steps-demo'
      ).value;
      const createdCampaign = getState().radAdmin.campaigns.createdCampaign;
      const { steps } = createdCampaign;
      const { id } = steps[selectedStep];

      if (!enableMoreStepsDemo) {
        await API.deleteRequest(`/api/v1/steps/${id}/`);
      }

      async function decreaseAllStepCounts() {
        const stepsToUpdate = steps
          .slice(selectedStep + 2)
          .filter((step) => step.isStep);

        for (let i = 0; i < stepsToUpdate.length; i++) {
          await API.patchRequest(`/api/v1/steps/${stepsToUpdate[i].id}/`, {
            step_number: stepsToUpdate[i].stepNumber - 1,
          });
        }
      }

      if (!enableMoreStepsDemo) {
        decreaseAllStepCounts();
      }

      dispatch({
        type: DELETE_CAMPAIGN_STEP,
        payload: [
          ...steps.slice(0, selectedStep),
          ...steps.slice(selectedStep + 2).map((step) => {
            if (step.isStep) {
              return {
                ...step,
                stepNumber: step.stepNumber - 1,
              };
            }

            return step;
          }),
        ],
      });
    } catch (err) {
      dispatch({
        type: 'DELETE_CAMPAIGN_STEP_FAILURE',
      });
    }
  };
};

export const fetchCampaignSteps = () => {
  return async (dispatch, getState) => {
    try {
      const enableMoreStepsDemo = getState().constants.featureFlags.find(
        (flag) => flag.name === 'enable-more-steps-demo'
      ).value;

      if (enableMoreStepsDemo) {
        return dispatch({
          type: FETCH_CAMPAIGN_STEPS,
          payload: {
            steps: demoSequences,
            stepCount: demoSequences.length,
          },
        });
      }

      const createdCampaign = getState().radAdmin.campaigns.createdCampaign;

      if (!createdCampaign) throw new Error();

      const { id: campaignId } = createdCampaign;
      const queryParams = queryString.stringify({
        campaign: campaignId,
      });
      const {
        data: { results },
      } = await API.getRequest(`/api/v1/steps/?${queryParams}`);

      const steps = results.sort((a, b) => a.step_number - b.step_number);
      const newSequences = steps.reduce((acc, result) => {
        const firstSequence = omitBy(
          {
            id: result.id,
            type: camelCase(result.step_type),
            stepNumber: result.step_number,
            selectedNumber: result.delay_time_interval,
            selectedDelay: result.delay_time_unit,
            content: result.content,
            title: result.title,
          },
          isUndefined
        );

        acc.push(
          generateNewSequence(firstSequence),
          generateNewSequence({
            type: 'transition',
          })
        );

        return acc;
      }, []);

      dispatch({
        type: FETCH_CAMPAIGN_STEPS,
        payload: {
          steps: [
            ...defaultSequences.slice(0, 1),
            ...newSequences,
            ...defaultSequences.slice(1),
          ],
          stepCount: steps.length,
        },
      });
    } catch (e) {
      dispatch({
        type: 'FETCH_CAMPAIGN_STEPS_FAILURE',
      });
    }
  };
};

export const createABSequenceStep = (index) => async (dispatch, getState) => {
  const createdCampaign = getState().radAdmin.campaigns.createdCampaign;
  const { steps } = createdCampaign;

  try {
    dispatch({
      type: CREATE_CAMPAIGN_STEP,
      payload: {
        steps: steps.map((step, idx) => {
          if (idx === index) {
            const { btn, type } = step;

            return generateNewSequence({
              ...step,
              type,
              btn,
              textBtnA: 'Email 2 - A',
              textBtnB: 'Email 2 - B',
              isAbTest: true,
            });
          }

          return step;
        }),
      },
    });
  } catch (error) {
    dispatch({
      type: 'CREATE_CAMPAIGN_STEP_FAILURE',
    });
  }
};

export const editCampaignStep = ({
  id,
  type,
  selectedNumber = null,
  selectedDelay = null,
  title = null,
  sendFrom = null,
  content = null,
}) => {
  return async (dispatch, getState) => {
    const enableMoreStepsDemo = getState().constants.featureFlags.find(
      (flag) => flag.name === 'enable-more-steps-demo'
    ).value;
    const { steps } = getState().radAdmin.campaigns.createdCampaign;

    try {
      if (!enableMoreStepsDemo) {
        const requestPayload = buildCampaignStepPayload({
          type,
          selectedNumber,
          selectedDelay,
          title,
          sendFrom,
          content,
        });

        await API.patchRequest(`/api/v1/steps/${id}/`, requestPayload);
      }

      const newStep = omitBy(
        {
          selectedNumber,
          selectedDelay,
          title,
          sendFrom,
          content,
        },
        isUndefined
      );

      dispatch({
        type: EDIT_CAMPAIGN_STEP,
        payload: {
          steps: steps.map((step) => {
            if (step.id === id) {
              return {
                ...step,
                ...newStep,
              };
            }

            return step;
          }),
        },
      });
    } catch (err) {
      dispatch({
        type: 'EDIT_CAMPAIGN_STEP_FAILURE',
      });
    }
  };
};

export const createCampaignStep =
  ({
    type,
    stepNumber,
    selectedNumber = null,
    selectedDelay = null,
    text = null,
    btn = null,
    title = null,
    sendFrom = null,
    content = null,
  }) =>
  async (dispatch, getState) => {
    const enableMoreStepsDemo = getState().constants.featureFlags.find(
      (flag) => flag.name === 'enable-more-steps-demo'
    ).value;
    const createdCampaign = getState().radAdmin.campaigns.createdCampaign;
    const { id: campaignId, steps } = createdCampaign;

    try {
      async function increaseAllStepCounts() {
        const stepsToUpdate = steps
          .slice(stepNumber)
          .filter((step) => step.isStep);

        for (let i = stepsToUpdate.length - 1; i >= 0; i--) {
          await API.patchRequest(`/api/v1/steps/${stepsToUpdate[i].id}/`, {
            step_number: stepsToUpdate[i].stepNumber + 1,
          });
        }
      }

      if (!enableMoreStepsDemo) {
        await increaseAllStepCounts();
      }

      const newStepNumber =
        stepNumber === 1 ? 1 : steps[stepNumber - 2].stepNumber + 1;
      const requestPayload = buildCampaignStepPayload({
        campaignId,
        stepNumber: newStepNumber,
        type,
        selectedNumber,
        selectedDelay,
        title,
        sendFrom,
        content,
      });
      const { data: response } = !enableMoreStepsDemo
        ? await API.postRequest('/api/v1/steps/', requestPayload)
        : mockResponse;
      const firstSequence = omitBy(
        {
          id: response.id,
          type,
          selectedNumber,
          selectedDelay,
          text,
          btn,
          stepNumber: newStepNumber,
          title,
          sendFrom,
          content,
        },
        isNil
      );
      const newSequences = [
        generateNewSequence(firstSequence),
        generateNewSequence({
          type: 'transition',
        }),
      ];
      const newSteps = [
        ...steps.slice(0, stepNumber),
        ...newSequences,
        ...steps.slice(stepNumber).map((step) => {
          if (step.isStep) {
            return {
              ...step,
              stepNumber: step.stepNumber + 1,
            };
          }

          return step;
        }),
      ];

      dispatch({
        type: CREATE_CAMPAIGN_STEP,
        payload: {
          steps: newSteps,
        },
      });

      if (newSteps.filter((step) => step.isStep).length >= 10) {
        dispatch(
          showAlert('warning', 'Warning', "You've reached the maximum steps!")
        );
      }
    } catch (error) {
      dispatch({
        type: 'CREATE_CAMPAIGN_STEP_FAILURE',
      });
    }
  };

export const resetCampaign = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CAMPAIGN });
  };
};

export const createCampaigns = (data, history) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_CAMPAIGNS_REQUEST });
    let response = await API.postRequest(`/api/v1/campaigns/`, data);
    dispatch(showAlert('success', 'Success', 'Campaign successfully created!'));
    dispatch({
      type: CREATE_CAMPAIGNS_REQUEST_SUCCESS,
      payload: response.data,
    });
    history.push(
      `/auth/radAdmin/Campaigns/${response.data.id}/create/triggers`
    );
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: CREATE_CAMPAIGNS_REQUEST_FAILURE, payload: error });
  }
};

export const fetchCampaign = (id) => {
  return async (dispatch) => {
    const response = await API.getRequest(`/api/v1/campaigns/${id}/`);

    dispatch({
      type: CREATE_CAMPAIGNS_REQUEST_SUCCESS,
      payload: response.data,
    });
  };
};

export const updateCampaigns =
  (data, step = '') =>
  async (dispatch, getState) => {
    try {
      let recentCreatedCampaign = getState().radAdmin.campaigns.createdCampaign;
      if (step === 'setup') {
        await API.putRequest(
          `/api/v1/campaigns/${recentCreatedCampaign.id}/`,
          data
        );
        dispatch(
          showAlert('success', 'Success', 'Setup successfully updated!')
        );
      } else if (step === 'triggers') {
        let dataToSend = {};
        dataToSend = {
          title: data.title,
          trigger_type: data.trigger_type,
          offset_days: parseInt(data.offset_days),
          offset: data.offset,
          date_attribute: data.date_attribute.replace('report', 'report2'),
          frequency: data.frequency,
        };
        await API.putRequest(
          `/api/v1/campaigns/${recentCreatedCampaign.id}/`,
          dataToSend
        );
      }
    } catch (error) {
      dispatch(showAlert('danger', 'Error', error.message));
      dispatch({ type: CREATE_CAMPAIGNS_REQUEST_FAILURE, payload: error });
    }
  };

export const deleteCampaigns = (idx) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_CAMPAIGNS_REQUEST });
    let response = await API.deleteRequest(`/api/v1/campaigns/${idx}/`);
    let existingCampaigns = getState().radAdmin.campaigns.data;
    let filteredData = existingCampaigns.filter((cam) => cam.id !== idx);
    dispatch(showAlert('success', 'Success', 'Successfully deleted!'));
    dispatch({ type: DELETE_CAMPAIGNS_REQUEST_SUCCESS, payload: filteredData });
    return { success: true };
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: DELETE_CAMPAIGNS_REQUEST_FAILURE, payload: error });
  }
};

const conditions = {
  false: 'Is',
  true: 'Is Not'
}

export const fetchSegment = (campaignId) => {
  return async (dispatch) => {
    const { data } = await API.getRequest(
      `/api/v1/segments/?campaign=${campaignId}`
    );

    let cohorts = await Promise.all(
      data.results.map((segment) =>
        API.getRequest(`/api/v1/cohort/${segment.cohort}/`)
      )
    );

    cohorts = cohorts.map((cohort) => cohort.data);

    dispatch({
      type: SET_ADDITIONAL_SEGMENT_CONDITIONS,
      payload: data.results.map((segment) => {
        const cohort = cohorts.find((cohort) => cohort.id === segment.cohort);

        return {
          condition: conditions[segment.exclude],
          cohortTitle: cohort.cohort_title,
        };
      }),
    });
  };
};

export const createSegment = (segmentsArray) => async (dispatch) => {
  try {
    await Promise.all(
      segmentsArray.map((segment) =>
        API.postRequest(`/api/v1/segments/`, segment)
      )
    );
    dispatch(
      showAlert(
        'success',
        'Success',
        'Segment and Trigger conditions added successfully!'
      )
    );
    return { success: true };
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
  }
};

export const getCampaignAttributesChoices = () => async (dispatch) => {
  try {
    dispatch({ type: GET_CAMPAIGNS_ATTRIBUTE_CHOICES_REQUEST });
    let {
      data: { results: offset },
    } = await API.getRequest(
      '/api/v1/campaigns/campaign_attributes_choices/?attribute=offset'
    );
    let {
      data: { results: frequency },
    } = await API.getRequest(
      '/api/v1/campaigns/campaign_attributes_choices/?attribute=frequency'
    );
    let {
      data: { results: dateAttributes },
    } = await API.getRequest(
      '/api/v1/campaigns/campaign_attributes_choices/?attribute=date_attribute'
    );
    let data = {
      offset: offset
        .reduce((acc, obj) => [...acc, Object.values(obj).map((y) => y)], [])
        .map((x) => x[0]),
      dateAttributes: dateAttributes
        .reduce((acc, obj) => [...acc, Object.values(obj).map((y) => y)], [])
        .map((x) => x[0])
        .map((x) => x.replace('report2', 'report')),
      frequency: frequency
        .reduce((acc, obj) => [...acc, Object.values(obj).map((y) => y)], [])
        .map((x) => x[0]),
    };
    dispatch({ type: GET_CAMPAIGNS_ATTRIBUTE_CHOICES_SUCCESS, payload: data });
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_CAMPAIGNS_ATTRIBUTE_CHOICES_FAILURE, payload: error });
  }
};

export const getDropdownValuesForCohort =
  (model, attribute) => async (dispatch) => {
    try {
      dispatch({ type: GET_COHORT_FIXED_VALUES_REQUEST });
      let endpoint = `/api/v1/dimension/fixed_values/?model=${model}&attribute=${attribute}`
      if(attribute ==="modality" && model ==="Report2"){
       endpoint = `/api/v1/report2/aggregate/?distinct=true&field=modality&agg_func=count&group_by=modality&user_rejected=${false}`
      }
      if(attribute ==="modality" && model ==="Recommendation"){
        endpoint = `/api/v1/recommendation/aggregate/?distinct=true&field=nlp_modality&agg_func=count&group_by=nlp_modality&user_rejected=${false}`
       }
       if(attribute ==="anatomy" && model ==="Recommendation"){
        endpoint = `/api/v1/recommendation/aggregate/?distinct=true&field=anatomy&agg_func=count&group_by=anatomy&user_rejected=${false}`
       }
      const { data } = await API.getRequest(
       endpoint
      );
    
      let obj = {
        model,
        attribute,
        values: attribute ==="modality" && model ==="Report2"?['empty'].concat(data?.map(itm=> itm.modality
        ).filter(itm=>itm)) : attribute ==="modality" && model ==="Recommendation"? ['empty'].concat(data?.map(itm=> itm.nlp_modality
        ).filter(itm=>itm)) : attribute ==="anatomy" && model ==="Recommendation"? ['empty'].concat(data?.map(itm=> itm.anatomy
        ).filter(itm=>itm)) :  data.results
          .reduce((acc, obj) => [...acc, Object.values(obj).map((y) => y)], [])
          .map((x) => x[0]),
      };
      dispatch({ type: GET_COHORT_FIXED_VALUES_SUCCESS, payload: obj });
    } catch (error) {
      dispatch(showAlert('danger', 'Error', error.message));
      dispatch({ type: GET_COHORT_FIXED_VALUES_FAILURE, payload: error });
    }
  };

export const getExportMessages =
  ({ endDate, startDate , directionValue, statusValue}) =>
  async (dispatch) => {
    try {
      dispatch({ type: EXPORT_MESSAGES_REQUEST });
      const reqObj = {
        created_less_than_filter: endDate,
        created_greater_than_filter: startDate,
      }
      if(!endDate){
      delete  reqObj.created_less_than_filter
      }
      if(directionValue !== 'inbound and outbound' && directionValue){
        reqObj.direction_filter = directionValue
      }
      if(statusValue !== 'all' && statusValue){
        reqObj.status_filter = statusValue
      }
      const payload = await API.postRequest('/api/v1/message_export/', reqObj);
      dispatch({ type: EXPORT_MESSAGES_SUCCESS, payload });
      dispatch(showAlert('success', 'Success', 'Your export was successful!'));
    } catch (error) {
      dispatch({ type: EXPORT_MESSAGES_FAILURE, payload: error.message });
      dispatch(showAlert('danger', 'Error', error.message));
    }
  };

  export const getExportEmails =
  ({ endDate, startDate , statusValue, includeEmailBody}) =>
  async (dispatch) => {
    try {
      const reqObj = {
        created_less_than_filter: endDate,
        created_greater_than_filter: startDate,
        include_email_body:includeEmailBody
      }
      if(!endDate){
      delete  reqObj.created_less_than_filter
      }
      if(statusValue !== 'all' && statusValue){
        reqObj.status_filter = statusValue
      }
      await API.postRequest('/api/v1/email-export/', reqObj);
      dispatch(showAlert('success', 'Success', 'Emails successfully exported'));
    } catch (error) {
      dispatch(showAlert('danger', 'Error', error.message));
    }
  };

  
  export const getExportFaxes =
  ({ endDate, startDate , statusValue, includeFaxBody}) =>
  async (dispatch) => {
    try {
      const reqObj = {
        created_less_than_filter: endDate,
        created_greater_than_filter: startDate,
      }
      if(!endDate){
      delete  reqObj.created_less_than_filter
      }
      if(statusValue !== 'all' && statusValue){
        reqObj.status_filter = statusValue
      }
      await API.postRequest('/api/v1/fax-export/', reqObj);
      dispatch(showAlert('success', 'Success', 'Faxes successfully exported'));
    } catch (error) {
      dispatch(showAlert('danger', 'Error', error.message));
    }
  };





  export const exportNotes =
  (data) =>
  async (dispatch) => {
    try {
      // dispatch({ type: EXPORT_MESSAGES_REQUEST });
      const payload = await API.postRequest('/api/v1/notes_export/', data);
      // dispatch({ type: EXPORT_MESSAGES_SUCCESS, payload });
      dispatch(showAlert('success', 'Success', 'Notes successfully exported'));
    } catch (error) {
      // dispatch({ type: EXPORT_MESSAGES_FAILURE, payload: error.message });
      dispatch(showAlert('danger', 'Error', error.message));
    }
  };



export const getRadiologistForDropdown = () => async (dispatch) => {
  try {
    dispatch({ type: GET_DROPDOWN_RADIOLOGISTS_REQUEST });
    const {
      data: { id__count: totalRecords },
    } = await API.getRequest(
      `/api/v1/radiologist/aggregate/?agg_func=count&distinct=true&field=id`
    );
    let pages = 1;
    if (totalRecords) pages = Math.ceil(totalRecords / 10);
    const pagesArray = Array(pages)
      .fill()
      .map((x, i) => i + 1);
    const radArray = await Promise.all(
      pagesArray.map((page) =>
        API.getRequest(`/api/v1/radiologist/?page=${page}&sorted=true`)
      )
    );
    let results = [];
    results = results.concat(...radArray.map((item) => item?.data?.results));
    dispatch({ type: GET_DROPDOWN_RADIOLOGISTS_SUCCESS, payload: results });
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_DROPDOWN_RADIOLOGISTS_FAILURE, payload: error });
  }
};


export const getReferrersForDropdown = (inputQuery) => async (dispatch) => {
  try {
    dispatch({ type: GET_DROPDOWN_REFERRERS_REQUEST });
    let results = await  API.getRequest(`/api/v1/physician/${inputQuery}`)
    dispatch({ type: GET_DROPDOWN_REFERRERS_SUCCESS, payload: results.data.results });
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_DROPDOWN_REFERRERS_FAILURE, payload: error });
  }
};


export const getCohort = (id) => async (dispatch) => {
  try {
    let results = await  API.getRequest(`/api/v1/cohort/${id}/`)
    return results.data
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
    return error
  }
};



export const getReferrersForDropdownOptions = (inputQuery, page=1) => async (dispatch) => {
  try {
    let results = await  API.getRequest(`/api/v1/physician/${inputQuery}&page=${page}&sorted=true`)
    return results.data
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
  }
};

export const setAdditionalSegmentConditionsField = (payload) => {
  return dispatch => {
    dispatch({
      type: SET_ADDITIONAL_SEGMENT_CONDITIONS,
      payload
    })
  }
}

export const setSegmentField = (payload) => {
  return dispatch => {
    dispatch({
      type: SET_SEGMENT_CONDITION,
      payload
    })
  }
}

export const setTimeTriggerField = (payload) => {
  return dispatch => {
    dispatch({
      type: SET_TIME_TRIGGER,
      payload
    })
  }
}

export const resetTimeTriggerForm = () => {
  return dispatch => {
    dispatch({
      type: RESET_TIME_TRIGGER,
    })
  }
}

const getAgeFilter = (filters) => {
  let startDate = '';
  let endDate = '';
  let { value: ageSlab } = filters.age;
  if (ageSlab === '18-34') {
    startDate = moment().subtract(34, 'years').format('YYYY-MM-DD');
    endDate = moment().subtract(18, 'years').format('YYYY-MM-DD');
  } else if (ageSlab === '35-64') {
    startDate = moment().subtract(64, 'years').format('YYYY-MM-DD');
    endDate = moment().subtract(35, 'years').format('YYYY-MM-DD');
  } else if (ageSlab === '65+') {
    startDate = moment().subtract(65, 'years').format('YYYY-MM-DD');
    endDate = moment().format('YYYY-MM-DD');
  }
  return { startDate, endDate };
};

const modalityFilter = (modality, modalityOperator) => {
  let followUpModality;
  if (modality && modality?.value === 'modality_none') {
    followUpModality = `&modality_isnull=${true}`;
  } else if (
    modality &&
    modality?.value !== 'modality_none' &&
    modalityOperator?.value !== 'isNotEqual'
  ) {
    followUpModality = `&modality=${modality.value}`;
  } else if (
    modality &&
    modality?.value !== 'modality_none' &&
    modalityOperator?.value === 'isNotEqual'
  ) {
    followUpModality = `&modality_ne=${modality.value}`;
  } else {
    followUpModality = '';
  }
  return followUpModality;
};

const anatomyFilter = (anatomy, anatomyOperator) => {
  let followUpAnatomy;
  if (anatomy && anatomy?.value === 'anatomy_none') {
    followUpAnatomy = `&anatomy_isnull=${true}`;
  } else if (
    anatomy &&
    anatomy?.value !== 'anatomy_none' &&
    anatomyOperator?.value !== 'isNotEqual'
  ) {
    followUpAnatomy = `&anatomy=${anatomy.value}`;
  } else if (
    anatomy &&
    anatomy?.value !== 'anatomy_none' &&
    anatomyOperator?.value === 'isNotEqual'
  ) {
    followUpAnatomy = `&anatomy_ne=${anatomy.value}`;
  } else {
    followUpAnatomy = '';
  }
  return followUpAnatomy;
};


export const getLocations = (page=1) => async (dispatch) => {
  try {
    let results = await  API.getRequest(`/api/v1/report2/locations/?page=${page}`)
    return results.data
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
  }
};


export const getCohortsData = (pageSize=500) => async (dispatch) => {
  try {
    dispatch({ type: GET_COHORTS_DROPDOWN_REQUEST });
    let results = await  API.getRequest(`/api/v1/cohort/?page_size=${pageSize}`)
    dispatch({ type: GET_COHORTS_DROPDOWN_SUCCESS, payload: results.data.results  });
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_COHORTS_DROPDOWN_FAILURE, payload: error });

  }
};

export const getReportsModality = () => async (dispatch) => {
  try {
    let results = await  API.getRequest(`/api/v1/recommendation/aggregate/?group_by=report__modality&agg_func=count&sorted=true`)
    return results.data
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
  }
};

export const getNoteUsers = () => async (dispatch) => {
  try {
    let {data} = await  API.getRequest( `/api/v1/note/aggregate/?agg_func=count&distinct=true&group_by=user__id`)
    let res = await Promise.allSettled(
      data.map(({ user__id }) => API.getRequest(`/api/v1/user/${user__id}`))
    );
  let usersList = data.map((x, i) => {
      return res[i].status === 'fulfilled' && res[i].value.data;
    });
    return usersList
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
  }
};


export const getNotePatients = (query) => async (dispatch) => {
  try {
    let {data} = await  API.getRequest( `/api/v1/note/aggregate/?agg_func=count&distinct=false&group_by=patient__id&search=${query}`)
    let res = await Promise.allSettled(
      data.map(({ patient__id }) =>API.getRequest(`/api/v1/patient/${patient__id}`))
    );
  let patientList = data.map((x, i) => {
      return res[i].status === 'fulfilled' && res[i].value.data;
    });
    return patientList
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
  }
};


export const getNoteTitle = (query) => async (dispatch) => {
  try {
    let {data} = await  API.getRequest( `/api/v1/note/aggregate/?agg_func=count&distinct=false&group_by=title&search=${query}`)
    return data
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
  }
};



export const getKeyMetrics  = (days) => async (dispatch) => {
  try {
    dispatch({ type: GET_KEY_METRICS_REQUEST });
    let results = await  API.getRequest(`/api/v1/recommendation/analytics-trends/?metric_days=${days}`)
    dispatch({ type: GET_KEY_METRICS_SUCCESS, payload: results.data });
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
    dispatch({ type: GET_KEY_METRICS_FAILURE, payload: error });
  }
};

export const removeBulkReco =
(data) =>
async (dispatch) => {
  try {
    await API.postRequest('/api/v1/recommendation/reco_bulk_action/', data);
    dispatch(showAlert('success', 'Success', 'Reco removed success'));
    return true;
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
  }
};


export const changeStatusBulkReco =
(data) =>
async (dispatch) => {
  try {
    await API.postRequest('/api/v1/recommendation/reco_bulk_action/', data);
    dispatch(showAlert('success', 'Success', 'Reco status updated success'));
    return true;
  } catch (error) {
    dispatch(showAlert('danger', 'Error', error.message));
  }
};
