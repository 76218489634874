import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import { Arrow } from './Icons';
import columnIcon from '../../../assets/columnIcon.svg';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;
  position: relative;
`;

const RecoNoteIconContainer = styled.span`
  cursor: pointer;
`;

const Dropdown = styled.div`
  position: absolute;
  background: white;
  border: 1px solid #dcdcdc;
  padding: 20px;
  top: 30px;
  border-radius: 4px;
  width: 225px;
  z-index: 3;
`;

const DropdownItem = styled.div`
  display: flex;
  align-items:center;
  gap:12px;
  margin-bottom:14px;
  opacity: ${(props) => (props.isDragging ? 0.5 : 1)};  
  transform: ${(props) => (props.isDragging ? 'translateY(-5px)' : 'none')};
  transition: transform 0.2s ease;

  &:last-child {
    margin-bottom: 0;
  }

`;

export default function ColumnFilterDropdown({
  columnCheckbox,
  columnData,
  isOpen,
  handleColumnChange,
  handleColumnFilterDropdown,
  setColsOrder=()=>{}
}) {

  let storedCols = JSON.parse(localStorage.getItem('col-order')) || []
  const [columns, setColumns] = useState( storedCols.length>0? storedCols: Object.keys(columnCheckbox));

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index.toString());
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, index) => {
    const draggedIndex = parseInt(e.dataTransfer.getData('text/plain'), 10);
    const updatedColumns = [...columns];
    const [draggedColumn] = updatedColumns.splice(draggedIndex, 1);
    updatedColumns.splice(index, 0, draggedColumn);
    setColumns(updatedColumns);
    setColsOrder(updatedColumns)
    localStorage.setItem('col-order',JSON.stringify(updatedColumns))
  };
  
  

  return (
    <Container>
      <span>Columns</span>
      <RecoNoteIconContainer
        onClick={() => {
          handleColumnFilterDropdown((prev) => !prev);
        }}
      >
        <Arrow />
      </RecoNoteIconContainer>

      {isOpen && (
        <Dropdown>
          {columns.map((column, index) => (
            <DropdownItem
            onDragStart={(e) => handleDragStart(e, index)}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, index)}
            draggable
              key={column}
            >
              <img src={columnIcon} />
              <input
                onChange={handleColumnChange}
                type="checkbox"
                value={column}
                checked={columnCheckbox[column]}
              />
              <label style={{ marginBottom: '0px' }}>
                {columnData.find((col) => col.key === column).name}
              </label>
            </DropdownItem>
          ))}
        </Dropdown>
      )}
    </Container>
  );
}