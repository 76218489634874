import React, { useEffect, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { withRouter, Link, NavLink } from 'react-router-dom';
import { Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { 
  DashboardIcon, 
  DashboardBlueIcon, 
  ClipboardIcon, 
  ClipboardBlueIcon, 
  PinIcon, 
  ClockIcon, 
  FileTextIcon, 
  CheckListIcon, 
  CheckListBlueIcon, 
  MessageSquareIcon, 
  MessageSquareBlueIcon, 
  SettingIcon, 
  SettingBlueIcon, 
  UserIcon, 
  ExportIcon, 
  RecoConfigIcon 
} from '../../pages/RadiologySearchPage/Icons';
import EnableFeature from '../EnableFeature/EnableFeature';
import styled from 'styled-components';
import * as authActions from '../../actions/auth/actions';
import { getConstants } from '../../actions/constants';
import {Deliveries, Incoming, CohortsIcon, CampaignsIcon} from '../../pages/RadAdmin/Messages/Icons'
import Logo from '../UI/Logo/Logo';
import { getRecoConfig } from '../../actions/radminUploads';

import { Header, Brand, HeaderContainer } from './styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import SendIcon from '@material-ui/icons/Send';
import TipsAndUpdatesIcon from '@material-ui/icons/EmojiObjects';
import {ReactComponent as SearchReportIcon} from '../../assets/searchReportIcon.svg'
import {ReactComponent as ActivityIcon } from '../../assets/activityIcon.svg'
import {ReactComponent as BellIcon } from '../../assets/bell-icon.svg'
import { Popover } from '@material-ui/core';
import ChangeLog from '../../pages/RadAdmin/Changelog';


const NavText = styled.span`
  color: ${prop => prop.isClicked ? '#00AEEF' : 'white'}
  margin-left: 5px;
`
const ProviderName = styled.div`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 0px;
  color: #FFFFFF;
  margin-right:0.5rem;
  white-space: nowrap;
  text-align:end;
`
const CustomStyledNavDropdown = styled(NavDropdown)`
  .dropdown-toggle::after {
    color: #fff; /* Custom color for the dropdown arrow */
  }
`;

function fullName(user) {
  let name;

  if (!user.first_name && !user.last_name) {
    name= user.email;
  }


  if (user.first_name) {
    name = user.first_name;
  }

  if (user.last_name) {
    name += ' ';
    name += user.last_name;
  }

  return name;
}
const NavDropdownMessage = ({isClicked}) => isClicked? (<> <MessageSquareBlueIcon/> Messages </>): (<> <MessageSquareIcon /> Messages</>)
const NavDropdownSetting = ({isClicked}) => isClicked? (<> <SettingBlueIcon /> Settings</>) : (<> <SettingIcon /> Settings</>)
const NavDropdownFollowUp = ({isClicked}) => isClicked? (<><CheckListBlueIcon /> Follow-Ups</>) : (<><CheckListIcon /> Follow-Ups</>)
const NavDropdownOverview = ({isClicked}) => isClicked? (<><DashboardBlueIcon /> Overview</> ) : (<><DashboardBlueIcon /> Overview</>)

function HorizontalNavbar({ logout, user }) {
  const dispatch = useDispatch();
  const featureFlags = useSelector(state => state.constants.featureFlags);
  const recoConfigData = useSelector((state) => state.radAdmin.recoConfig.data);
  const isDoctor = user?.role === 'doctor';
  const isRadmin = user?.role === 'radiology_admin';
  const isHaveUploads = user?.isHaveUploads;
  // const navDropdownMessage = (<> <MessageSquareIcon /> Messages</>);
  // const navDropdownSetting = (<> <SettingIcon /> Settings</>);

  const [isOverViewClicked, setIsOverViewClicked] = useState(false);
  const [isQualityMetricsClicked, setIsQualityMetricsClicked] = useState(false);
  const [isfollowUplistClicked, setIsfollowUplistClicked] = useState(false);
  const [isMessagesClicked, setIsMessagesClicked] = useState(false);
  const [isSettingsClicked, setIsSettingsClicked] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hasNewNotifications, setHasNewNotifications] = useState(user?.new_changelogs_count>0)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setHasNewNotifications(false)
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  const overviewClickAnalytic = () => {
    window.analytics.track('Overview-Navigation-Click');
  }

  const viewActivityClickAnalytic = () => {
    window.analytics.track('View-Activity-Logs-Click');
  }
  
  const viewCustomReportsClickAnalytic = () => {
    window.analytics.track('View-Custom-Charts-Navigation-Click');
  }

  const dashboardClickAnalytic = () => {
    window.analytics.track('Dashboard-Navigation-Click');
  }
  const reportsClickAnalytic = () => {
    window.analytics.track('Reports-Navigation-Click');
  }
  const appointmentsClickAnalytic = () => {
    window.analytics.track('Appointments-Navigation-Click');
  }
  const searchClickAnalytic = () => {
    window.analytics.track('Search-Navigation-Click');
  }
  const followUpListClickAnalytic = () => {
    window.analytics.track('FollowUpList-Navigation-Click');
  }
  const patientViewListClick = () => {
    window.analytics.track('Patient-List-Navigation-Click');
  }
  const searchReportTextViewClick = () => {
    window.analytics.track('Search-Report-Text-Navigation-Click');
  }
  const integrationsClickAnalytic = () => {
    window.analytics.track('Integrations-Navigation-Click');
  }

  const refDoctorsClickAnalytic = () => {
    window.analytics.track('Ref-Doctors-Navigation-Click');
  }

  const messagesClickAnalytics = () => {
    window.analytics.track('Messages-Navigation-Click');
  }
  const campaignsClickAnalytics = () => {
    window.analytics.track('Campaigns-Navigation-Click');
  }

  const previousUploadsClickAnalytic = () => {
    window.analytics.track('Previous-Uploads-Navigation-Click');
  }

  const notesClickAnalytic = () => {
    window.analytics.track('Nav: clicked Notes navbar');
  }
  
  const supportClickAnalytic = () => {
    window.analytics.track('Support-Navigation-Click');
  }

  const settingsClickAnalytic = () => {
    window.analytics.track('Settings-Navigation-Click');
  }

  const exportsClickAnalytic = () => {
    window.analytics.track('Exports-Navigation-Click');
  }

  const recoConfigClickAnalytic = () => {
    window.analytics.track('Reco-Config-Navigation-Click');
  }

  const cohortsClickAnalytic = () => {
    window.analytics.track('Cohorts-Navigation-Click');
  }

  const incomingClickAnalytic = () => {
    window.analytics.track('Incoming-Navigation-Click');
  }
  
  const deliveriesClickAnalytic = () => {
    window.analytics.track('Deliveries-Navigation-Click');
  }
  

  useEffect(() => {
    if(!featureFlags.length) {
      dispatch(getConstants());
    }
  }, []);

  useEffect(() => {
    dispatch(getRecoConfig());
  }, []);

  if(!featureFlags.length) {
    return null;
  }

  return (
    <HeaderContainer>
      <Header>
        <Brand>
          <Logo />
        </Brand>
        <Navbar expand='lg'>
        { isDoctor ? (
          <Nav defaultActiveKey='/home' className='justify-content-center'>
            <Nav.Link as={Link} eventKey='link-1' to='/auth/appointments' onClick={appointmentsClickAnalytic} >
              <DashboardIcon /> Appointments
            </Nav.Link>
          </Nav>
      ) : isRadmin ? (
        
          <Nav defaultActiveKey='/home' className='justify-content-center'>
              <NavDropdown title={<NavDropdownOverview />}>
              <NavDropdown.Item
              as={NavLink}
              eventKey='link-1'
              to={isHaveUploads ? '/auth/radAdmin/Overview' : '/auth/radAdmin/Dashboard'}
              onClick={overviewClickAnalytic}
              isActive={(match) => {
                if(match){
                  setIsOverViewClicked(true)
                  setIsMessagesClicked(false);
                  setIsSettingsClicked(false);
                }else{
                  setIsOverViewClicked(false)
                }
                return match
              }}
            >
             {isOverViewClicked ? <DashboardBlueIcon /> : <DashboardIcon />} Overview
            </NavDropdown.Item>
             <EnableFeature featureName={'enable-custom-charts'}>
               <NavDropdown.Item
               as={NavLink}
               eventKey='link-16'
               to={'/auth/radAdmin/custom/charts/view'}
               onClick={viewCustomReportsClickAnalytic}
               isActive={(match) => {
                if(match){
                  setIsOverViewClicked(true)
                  setIsMessagesClicked(false);
                  setIsSettingsClicked(false);
                }
                return match
               }}
               >
               {isOverViewClicked ? <CampaignsIcon /> : <CampaignsIcon />} Custom Charts *beta
              </NavDropdown.Item>
             </EnableFeature>
                     
            <NavDropdown.Item
               as={NavLink}
               eventKey='link-17'
               to={'/auth/radAdmin/activity'}
               onClick={viewActivityClickAnalytic}
               >
               {<ActivityIcon/>} Activity Logs
              </NavDropdown.Item>
            </NavDropdown>
            <EnableFeature featureName={'ref-docs-list-view'}>
              <Nav.Link
                as={NavLink}
                eventKey='link-2'
                to='/auth/radAdmin/referringDocs/1'
                onClick={refDoctorsClickAnalytic}
                isActive={(match) => {
                  if(match){
                    setIsQualityMetricsClicked(true)
                    setIsMessagesClicked(false);
                    setIsSettingsClicked(false);
                  }else{
                    setIsQualityMetricsClicked(false)
                  }
                  return match
                }}
                >
              {isQualityMetricsClicked ? <ClipboardBlueIcon /> : <ClipboardIcon />} <NavText isClicked={isQualityMetricsClicked}>Quality Metrics</NavText>
              </Nav.Link>
            </EnableFeature>
            <EnableFeature featureName={'enable-follow-up-list-view'} onClick={followUpListClickAnalytic}>
              <NavDropdown title={<NavDropdownFollowUp isClicked={isfollowUplistClicked} id='basic-nav-dropdown'/>}>
               { recoConfigData &&  <NavDropdown.Item
                 as={NavLink}
                 eventKey='link-10'
                 to='/auth/radAdmin/followUpList'
                 isActive={(match) => {
                   if(match){
                    setIsfollowUplistClicked(true)
                    setIsMessagesClicked(false);
                   }else{
                    setIsfollowUplistClicked(false)
                   }
                   return match
                 }}
                > <CheckListIcon />  Follow-Ups
                </NavDropdown.Item>
                }
                <EnableFeature featureName={'enable-patient-view-list'} onClick={patientViewListClick}>
                <NavDropdown.Item
                  as={NavLink}
                  eventKey='link-11'
                  to='/auth/radAdmin/followUp/patients'
                  isActive={(match) => {
                   if(match){
                     setIsfollowUplistClicked(true)
                     setIsMessagesClicked(false);
                    }
                   return match
                 }}
                > <UserIcon />  Patients
                </NavDropdown.Item>
                </EnableFeature>
                <NavDropdown.Item
                  as={NavLink}
                  eventKey='link-12'
                  to='/auth/radAdmin/followUp/notes'
                  isActive={(match) => {
                    if(match){
                      setIsfollowUplistClicked(true)
                      setIsMessagesClicked(false);
                     }
                    return match
                  }}
                  onClick={notesClickAnalytic}
                > <FileTextIcon />  Notes
                </NavDropdown.Item>
                <EnableFeature featureName={'enable-search'} onClick={searchReportTextViewClick}>
                <NavDropdown.Item
                  as={NavLink}
                  eventKey='link-13'
                  to='/auth/radAdmin/followUp/search-report-text'
                  isActive={(match) => {
                   if(match){
                     setIsfollowUplistClicked(true)
                     setIsMessagesClicked(false);
                    }
                   return match
                 }}
                > <SearchReportIcon /> Search Report Text
                </NavDropdown.Item>
                </EnableFeature>
              </NavDropdown>
              
            </EnableFeature>
            {/* <EnableFeature featureName={'view-messages'}>
              <Nav.Link as={Link} eventKey='link-7' to='/auth/radAdmin/messages' onClick={messagesClickAnalytics}>
                <MessageSquareIcon /> Messages
              </Nav.Link>
            </EnableFeature> */}
             <EnableFeature featureName={'view-messages'} onClick={messagesClickAnalytics}>
             <NavDropdown title={<NavDropdownMessage isClicked={isMessagesClicked}/>} id='basic-nav-dropdown'>
                <NavDropdown.Item
                  as={NavLink}
                  eventKey='link-9'
                  to='/auth/radAdmin/messages/cohorts'
                  isActive={(match) => {
                    if(match){
                      setIsMessagesClicked(true);
                      setIsSettingsClicked(false);
                    }
                    return match
                  }}
                  onClick={cohortsClickAnalytic}
                  >
                  <CohortsIcon /> Cohorts
                </NavDropdown.Item>
                <EnableFeature featureName={'campaign-view'} onClick={campaignsClickAnalytics}>
                  <NavDropdown.Item
                    as={NavLink}
                    eventKey='link-10'
                    to='/auth/radAdmin/Campaigns'
                    isActive={(match) => {
                      if(match){
                        setIsMessagesClicked(true);
                        setIsSettingsClicked(false);
                      }
                      return match
                    }}
                    >
                    <CampaignsIcon /> Campaigns
                  </NavDropdown.Item>
                </EnableFeature>
              <NavDropdown.Item
                as={NavLink}
                eventKey='link-7'
                exact
                to='/auth/radAdmin/messages'
                isActive={(match) => {
                  if(match){
                    setIsMessagesClicked(true);
                    setIsSettingsClicked(false);
                  }
                  return match
                }}
                onClick={incomingClickAnalytic}
              >
                <Incoming /> Incoming
              </NavDropdown.Item>
              <NavDropdown.Item
                as={NavLink}
                eventKey='link-8'
                exact
                to='/auth/radAdmin/messages/deliveries'
                isActive={(match) => {
                  if(match){
                    setIsMessagesClicked(true);
                    setIsSettingsClicked(false);
                  }
                  return match
                }}
                onClick={deliveriesClickAnalytic}
                >
                <Deliveries /> Deliveries
              </NavDropdown.Item>
            </NavDropdown>
            </EnableFeature>
            <NavDropdown title={<NavDropdownSetting isClicked={isSettingsClicked}/>} id='basic-nav-dropdown'>
              <NavDropdown.Item
                as={NavLink}
                eventKey='link-4'
                to={isHaveUploads ? '/auth/radAdmin/Import' : '/auth/radAdmin/Dashboard'}
                onClick={integrationsClickAnalytic}
                isActive={(match) => {
                  if(match){
                    setIsSettingsClicked(true);
                    setIsMessagesClicked(false)
                  }
                  return match
                }}
                >
                <FileTextIcon /> Import
              </NavDropdown.Item>
              <NavDropdown.Item
                as={NavLink}
                eventKey='link-5'
                to='/auth/radAdmin/previousUploads'
                onClick={previousUploadsClickAnalytic}
                isActive={(match) => {
                  if(match){
                    setIsSettingsClicked(true);
                    setIsMessagesClicked(false)
                  }
                  return match
                }}
                >
                <ClockIcon /> Previous Uploads
              </NavDropdown.Item>
              <EnableFeature featureName={'enable-export-page-view'}>
                <NavDropdown.Item as={Link} eventKey='link-6' to='/auth/radAdmin/exports' onClick={exportsClickAnalytic} >
                  <ExportIcon /> Exports
                </NavDropdown.Item>
              </EnableFeature>
            </NavDropdown>
         
          </Nav>
      ): (
          <Nav defaultActiveKey='/home' className='justify-content-center'>
            <Nav.Link as={Link} eventKey='link-1' to='/auth/dashboard' onClick={dashboardClickAnalytic}>
              <DashboardIcon /> Dashboard
            </Nav.Link>
            <Nav.Link as={Link} eventKey='disabled' to='/auth/reports' onClick={reportsClickAnalytic}>
              <ClipboardIcon /> Reports
            </Nav.Link>
            <Nav.Link as={Link} eventKey='link-1' to='/auth/appointments' onClick={appointmentsClickAnalytic}>
              <ClockIcon /> Appointments
            </Nav.Link>
            <Nav.Link as={Link} eventKey='disabled' to='/auth/search' onClick={searchClickAnalytic}>
              <PinIcon /> Find Radiology Center
            </Nav.Link>
          </Nav>
      )}
        </Navbar>
     
        <Navbar expand="lg">
       
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav style={{display:'flex', flexDirection:'column',alignItems:'flex-end'}} className='mr-auto'>
              <div style={{ display:'flex', alignItems:'center', gap:'15px'}}>
              {isRadmin && <div onClick={handleClick} style={{position:'relative'}}>
              <BellIcon style={{cursor:'pointer'}} />
                   {
                    hasNewNotifications && user?.new_changelogs_count>0 && <div style={{background:'#00AEEF', borderRadius:'50%', width:'16px', height:'16px', position:'absolute',top: '-50%', transform: 'translate(-50%, 50%)',right:'-85%', color:'#0C2945', fontWeight:600, fontSize:'8px', display:'flex', justifyContent:'center', alignItems:'center'}} >
                    {user?.new_changelogs_count}
                    </div>
                   } 
                   </div>
                } 
              <div>
              <ProviderName alignRight>{recoConfigData?.name}</ProviderName>
                <div style={{marginBottom: '-20px', textAlign:'end'}}>
                  <NavDropdown title={fullName(user)} id='basic-nav-dropdown' alignRight>
                    {isRadmin && <NavDropdown.Item as={Link} to='/auth/radAdmin/settings' onClick={settingsClickAnalytic}>Settings</NavDropdown.Item>}
                    <NavDropdown.Item onClick={() => logout()}>Log out</NavDropdown.Item>
                  </NavDropdown>
                </div>
              </div>
              </div>
        
            </Nav>
          </Navbar.Collapse>
          {
            isRadmin  &&  <Nav.Link as={Link} style={{color:'white'}}  to='/auth/radAdmin/support' onClick={supportClickAnalytic}>
             <HelpOutlineIcon style={{width:"20px" , height:'20px', color:'#fff'}} /> Support 
          </Nav.Link>     
            }
        </Navbar>
    
       <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: -10,
          horizontal: 'center',
        }}
        >
         <ChangeLog />
        </Popover>
      
      </Header>
    </HeaderContainer>
  );
}

function mapStateToProps(state) {
  const { isLoggedIn, user } = state.auth;

  return {
    isLoggedIn,
    user
  };
}

 export default withRouter(connect(mapStateToProps, authActions)(HorizontalNavbar));
